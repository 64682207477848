import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";

import { env } from '../env';

import { ImgButton } from './ImgButtonComp';
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import Chart from "react-google-charts";

import Collapse from "@kunukn/react-collapse";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import * as Utilities from "./Utilities"

import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import { Kennzahl } from './KennzahlComp';
import { KennzahlJR } from './KennzahlJRComp';

import { GoogleChartWrapper, GoogleChartControl, GoogleViz, ReactGoogleChartProps, ReactGoogleChartEvent, GoogleDataTable, GoogleVizEventName } from "react-google-charts/dist/types";



type expandSet = {
  name: string;
  expanded: boolean;
}

type State = {
    ready: boolean;        
    isVisible: boolean;
    hiddenRows: (string|undefined)[];
    expandedCollaps: expandSet[];
    expanded: string[];    
    alleJahre: boolean;
    //gzebenen: KSLPlusAPI.GZEbenenModel[];
    grundzahlen: KSLPlusAPI.DatensatzModel[];
    gzopen: boolean;
    kennzahlen: KSLPlusAPI.Kennzahlen[];
    kennzahlopen: boolean;
    //gzebene: string;
}

type Props = {    
    hh: KSLPlusAPI.HaushaltSummiert,   
    kunde: number,
    gemeinde: number,    
    jahr: number,
    planstufe: number,
    plantyp: number,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    renderTyp: number,
    settings: KSLPlusAPI.SettingsModel,
    isVisible: boolean,    
    summenTyp: number,
    callerID: string,
    suchText: string,
    produkt: string
}



export class HHSummiertDoppisch extends Component<Props, State> {
            
    constructor(props: any) {
        super(props);
        this.handleOnExpand = this.handleOnExpand.bind(this);
        this.handleOnExpand1 = this.handleOnExpand1.bind(this);
        this.collapsRow = this.collapsRow.bind(this);
        this.hideRows = this.hideRows.bind(this);
        this.hideRows1 = this.hideRows1.bind(this);
        this.hideRows2 = this.hideRows2.bind(this);
        

    }

    node: any;
    getFilters: { (filter: string | undefined): void }[] = [];      
    
    state: State = {
        ready: true,
        isVisible: false,
        hiddenRows: [],
        expanded: [],
        expandedCollaps: [],
        alleJahre: false,
        grundzahlen: [],
        gzopen: false,
        kennzahlen: [],
        kennzahlopen: false

    }

    static defaultProps = {
        summenTyp: 1,
        renderTyp: 0,
        suchText: "",
        produkt: "",
        kunde: 0,
        gemeinde: 0,
        planstufe: 0
    }

    readKennzahlen = () => {
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;

        if (this.props.produkt === "")
            return;

        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;
        request.modul = (this.props.plantyp == 3 ? 1 : (this.props.plantyp == 4 ? 2 : 0));


        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdAdmin_Client(api);


        client.getProduktKennzahlen(this.props.produkt, this.props.summenTyp == 4 || this.props.summenTyp == 10 || this.props.summenTyp == 20 ? 1 : 0,"", request).then((kennzahlen) => {
            this.state.kennzahlen = kennzahlen;
            
            this.setState({ ready: true });            
        }).catch(Reason => {
           
        });

    }

    readGrundzahlen = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
        if (this.props.produkt === "")
            return;

        client.grundzahlen(this.props.kunde, this.props.gemeinde, this.props.jahr, this.props.plantyp === 3 ? 1 : (this.props.plantyp === 4 ? 2 : 0), this.props.produkt, "").then((gz) => {            
            this.getFilters = [];
            this.setState({ grundzahlen: gz });
        }).catch(Reason => {
            
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.hh != this.props.hh && this.props.hh.zeilen) {            
            this.setState({ ready: true, hiddenRows: this.props.hh.zeilen.filter(d => d.verbergen).map(d => d.id) });            
        }

            
        if (prevProps.isVisible !== this.props.isVisible && this.props.hh && this.props.hh.zeilen) {            
            this.setState({ expanded: [], expandedCollaps: [], hiddenRows: this.props.hh.zeilen.filter(d => d.verbergen).map(d => d.id) });
            
        }

        if (prevProps.produkt !== this.props.produkt) {
            this.readGrundzahlen();
            this.readKennzahlen();
        }
    }

    componentDidMount() {
        if (this.props.hh.zeilen) {
            this.hideRows(undefined);
            this.state.expanded = [];
            if (this.props.suchText !== "")
                this.props.hh.zeilen.forEach(z => this.expandBySuchtext(z));
            
            this.setState({ expanded: this.state.expanded, ready: true, hiddenRows: this.props.hh.zeilen.filter(d => d.verbergen).map(d => d.id) });                        
        }   
        this.readGrundzahlen();
        this.readKennzahlen();
    }


    rowEvents = {
        onClick: (e: any, row: KSLPlusAPI.HaushaltsZeile, rowIndex: number) => {
            if (this.props.hh.zeilen && row.unterzeilen && row.unterzeilen.length > 0) {
                if (this.props.hh.zeilen.findIndex(d => row.unterzeilen && row.unterzeilen.findIndex(z => z === d.zeile) >= 0 && d.verbergen) >= 0) {
                    this.showRows(row.unterzeilen);
                    row.changeFlag = !row.changeFlag;
                }
                else {                    
                    this.hideRows(row.unterzeilen);                    
                    this.state.expanded = this.state.expanded.filter(x => x !== row.id);
                    row.changeFlag = !row.changeFlag;                    
                    this.setState({ expanded: this.state.expanded });
                }
            }
        }
    };

    collapsRow = (row: KSLPlusAPI.HaushaltsZeilenDetails) => {
        this.state.expanded = this.state.expanded.filter(x => x !== row.id);
        if (row.unterEbenen)
            row.unterEbenen.forEach(r => this.collapsRow(r));
        
                
    }

    expandBySuchtext = (row: KSLPlusAPI.HaushaltsZeile) => {
        var any = false;
                
        if (row.unterzeilen && this.props.hh.zeilen) {
            const rows = this.props.hh.zeilen.filter(d => (!row.unterzeilen && !d.summenZeile) || (row.unterzeilen && row.unterzeilen.findIndex(z => d.zeile === z) >= 0));            
            
            rows.forEach(r => {
                if (this.expandBySuchtext(r)) {                        
                    any = true;
                }
            });
        }

        if (row.details && row.details.unterEbenen) {
            row.details.unterEbenen.forEach(r => {

                if (this.expandBySuchtext1(r)) {
                    any = true;                    
                }
            });
        }

        if (any) {                  
            this.handleOnExpand(row, true, 0, 0);
            if (row.unterzeilen) 
                this.showRows(row.unterzeilen);
                
        }

        return any;
    }

    expandBySuchtext1 = (row: KSLPlusAPI.HaushaltsZeilenDetails) => {
        var any = false;
        
        if (row.unterEbenen)
            row.unterEbenen.forEach(r => {
                if (this.expandBySuchtext1(r)) {                    
                    any = true;
                }
                
            });

        if (row.suchText && this.props.suchText.length > 0 && row.suchText.toLowerCase().indexOf(this.props.suchText) != -1) {            
            this.scrollIntoView("kslplus-row-id-" + row.id);            
            any = true;
        }

        if (any) {
            this.handleOnExpand1(row, true, 0, 0);
            
            //this.state.expanded.push(row.id ? row.id : "");
        }

        return any;
    }

    handleOnExpand = (row: KSLPlusAPI.HaushaltsZeile, isExpand: boolean, rowIndex: number, e: any) => {
        //var test = this.node.props;
        var expand = document.getElementById('kslplus-tabexpand-' + row.id);
        if (isExpand) {
            if (expand)
                expand.textContent = this.props.summenTyp > 10 ? "(-)" : "(--)";
            this.state.expanded.push(row.id ? row.id : "");

        } else {
            if (expand)
                expand.textContent = this.props.summenTyp > 10 ? "(+)" : "(++)";            
            this.state.expanded = this.state.expanded.filter(x => x !== row.id);
            
            if (row.details && row.details.unterEbenen)
                row.details.unterEbenen.forEach(r => this.collapsRow(r));
            
            this.setState({ expanded: this.state.expanded })
        }
    }

    setStyle = (name: string, style: string) => {
        var trElem = document.getElementsByClassName(name);
        

        if (trElem && trElem.length > 0)             
            trElem[0].setAttribute("style", style);
            
    }

    scrollIntoView = (name: string) => {

        var trElem = document.getElementsByClassName(name);
        
        
        
        if (trElem && trElem.length > 0) {            
            trElem[0].scrollIntoView({ block: 'center' });                                 
            trElem[0].setAttribute("style", "background-color: #FDFF47");
            setTimeout(() => this.setStyle(name, ""), 10000);
            
        }
        else
            setTimeout(() => this.scrollIntoView(name), 1000);


    }

    handleOnExpand1 = (row: KSLPlusAPI.HaushaltsZeilenDetails, isExpand: boolean, rowIndex: number, e: any) => {
        var expand = document.getElementById('kslplus-tabexpand-' + row.id);
        if (isExpand) {
            if (expand)
                expand.textContent = this.props.summenTyp > 10 ? "(-)" : "(--)";
            this.state.expanded.push(row.id ? row.id: "");

        } else {
            if (expand)
                expand.textContent = this.props.summenTyp > 10 ? "(+)" : "(++)";
            
            this.state.expanded = this.state.expanded.filter(x => x !== row.id);
            if (row.unterEbenen)
                row.unterEbenen.forEach(r => this.collapsRow(r));
            
            this.setState({ expanded: this.state.expanded })
        }
    }
    /*
    const expandRow = {
        renderer: (row: KSLPlusAPI.HaushaltsZeile) => (

            row.details && row.details.unterEbenen && row.details.unterEbenen.length  ?
                <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.details.unterEbenen} columns={this.createDetailColumns(this.props.hh.zeilen ? this.props.hh.zeilen : [])} expandRow={row.details.unterEbenen && row.details.unterEbenen.find(e => e.unterEbenen) ? this.expandRow1 : undefined} rowClasses="kslplus-row-sum-0" />
                :
                null

        ),
        className: 'kslplus-tabellen-expanded',
        //showExpandColumn: true,
        //expandColumnPosition: 'left',
        expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
            return null;
        },
        expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
            if (expanded) {
                return (
                    <div className="kslplus-expand-column">(-)</div>
                );
            }
            return (
                <div className="kslplus-expand-column">(+)</div>
            );
        },
        nonExpandable: this.props.hh.zeilen ? this.props.hh.zeilen.filter(d => !d.details || !d.details.unterEbenen || !d.details.unterEbenen.length).map(d => d.id) : [],
        onExpand: this.handleOnExpand,
        expanded: this.state.expanded  
    };
    
    const expandRow1 = {
        renderer: (row: KSLPlusAPI.HaushaltsZeilenDetails) => (
            row.unterEbenen ?
                <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createDetailColumns(this.props.hh.zeilen ? this.props.hh.zeilen : [])} expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen) ? this.expandRow1 : undefined} rowClasses="kslplus-row-sum-0" />
                :
                null

        ),
        className: 'kslplus-tabellen-expanded',
        //showExpandColumn: true,
        //expandColumnPosition: 'left',
        expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
            return null;
        },
        expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
            if (expanded) {
                return (
                    <div className="kslplus-expand-column">(-)</div>
                );
            }
            return (
                <div className="kslplus-expand-column">(+)</div>
            );
        },
        expanded: this.state.expanded,
        onExpand: this.handleOnExpand1

    };
    */
    createDetailColumns = (data: KSLPlusAPI.HaushaltsZeile[]) => {
        var columns = [];

        columns.push({
            dataField: 'changeFlag',
            text: "(+)",

            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'left' };
            },
            style: (colum: any, colIndex: any) => {
                return { width: '50px' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsZeilenDetails) => {
                if (row.unterEbenen && row.unterEbenen.length) {
                    // immer ++ rendern -> Änderung zw. ++ und -- erfolgt in handleOnExpand1
                    /*
                    return (
                        <div className="kslplus-tabellen-data" id={"kslplus-tabexpand-" + row.id} >{this.props.summenTyp > 10 ? "(+)" : "(++)"}</div>
                    );
                    */
                    return (
                        <div className="kslplus-tabellen-data" id={"kslplus-tabexpand-" + row.id} >{this.state.expanded.findIndex(r => r == row.id) != -1 ? (this.props.summenTyp > 10 ? "(-)" : "(--)") : (this.props.summenTyp > 10 ? "(+)" : "(++)")}</div>
                    );
                }
                else
                    return null;
            }

        });

        if (this.props.summenTyp < 11 || (this.props.summenTyp > 16 && this.props.summenTyp < 21)) {
            columns.push({
                dataField: 'zeile',
                text: this.props.hh.bezZeilen ? this.props.hh.bezZeilen : "Zeile",
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '65px', textAlign: 'left' };
                },

                style: (colum: any, colIndex: any) => {
                    return { width: '65px' };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsZeilenDetails) => {
                    return null;
                }

            });
        }

        columns.push({
            dataField: 'bezeichnung',
            text: "bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '530px', textAlign: 'left' };
            },

            style: (colum: any, colIndex: any) => {
                return { width: '530px' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsZeilenDetails) => {
                var label: JSX.Element[] = [];
                var toolTips: JSX.Element[] = [];
    
                if (row.produktStellen) {
                    label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-prod" + row.id} key={"kslplus-label-prod" + row.id}>{(row.produktStellen ? (Utilities.Num2Str(row.produkt, row.produktStellen) + ".") : "")}</span>);
                    if (row.investNr)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id}>{Utilities.Num2Str(row.konto, row.kontoStellen) + "-" + row.investNr.toString() + " - " + row.bezeichnung}</span>);
                    else
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id}>{Utilities.Num2Str(row.konto, row.kontoStellen) + " - " + row.bezeichnung}</span>);
                    if (row.produktText)
                        toolTips.push(<UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-prod" + row.id : ""} key={row.id ? "kslplus-label-prod" + row.id : ""}>{row.produktText}</UncontrolledTooltip>);
                }
                else {
                    label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-prod" + row.id} key={"kslplus-label-" + row.id}>{Utilities.Num2Str(row.konto, row.kontoStellen) + " - " + row.bezeichnung}</span>);
                }
                //<div className="kslplus-tabellen-bezeichnung" >{label} {row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                //    <div className="kslplus-tabellen-data">{(row.produktStellen ? (Utilities.Num2Str(row.produkt, row.produktStellen) + ".") : "") + Utilities.Num2Str(row.konto, row.kontoStellen) + " - " + row.bezeichnung}</div>

                return (
                    <div className="kslplus-tabellen-bezeichnung" >{label}{row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}><div className="kslplus-tooltip">{row.beschreibung}</div></UncontrolledTooltip></div> : null}{toolTips}</div>

                );

            }
        });



        if (data == null || !this.props.hh.jahre)
            return columns;

        var id = 2;


        if (this.props.plantyp == 4 && (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) != -1 || this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) != -1)) {
            columns.push({
                dataField: '',
                isDummyField: true,
                headerAlign: 'middle',
                headerFormatter: (column: any, columnIndex: any) => {
                    var ttId = "kslplus-tt-pjcol-" + this.props.callerID;
                    return (<div>
                        <div id={ttId}>{this.state.alleJahre ? "-" : "+"}</div >
                        <UncontrolledPopover
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-header-popover">
                            <PopoverBody className="kslplus-header-popover-body">
                                <Container>
                                    <div>
                                        {"Zusätzliche Jahre " + (this.state.alleJahre ? "verbergen" : "anzeigen")}
                                    </div>
                                </Container>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div >);

                },
                text: this.state.alleJahre ? "-" : "+",
                headerStyle: (colum: any, colIndex: any) => {
                    return { fontWeight: 900, width: '30px', textAlign: 'left' };
                },

                style: (colum: any, colIndex: any) => {
                    return { width: '30px' };
                },
                headerEvents: {
                    onClick: (e: any, column: any, columnIndex: any) => { this.setState({ alleJahre: !this.state.alleJahre }) }
                }
            });
        }


        var fieldPrev: string | undefined;

        if (this.props.plantyp === 3) {
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM4", this.props.jahr - 4, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM4";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM3", this.props.jahr - 3, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM3";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM2", this.props.jahr - 2, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM2";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM1", this.props.jahr - 1, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM1";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.summenTyp < 11) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("ansatzPJfort", this.props.jahr, id, "Fortgeschriebener Ansatz", false, true));
                    id++;
                    fieldPrev = "ansatzPJfort";

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJ", this.props.jahr, id, "Ergebnis", true));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ", id, "fortgeschriebenen Ansatz", false, true));
                    id++;
                }
                fieldPrev = "ergebnisPJ";

            }
            else {
                fieldPrev = undefined;
            }

        }
        else if (this.props.plantyp == 4) {
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM4", this.props.jahr - 4, id, "Ergebnis", false, false, !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM3", this.props.jahr - 3, id, "Ergebnis", false, false, !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM2", this.props.jahr - 2, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM2";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM1", this.props.jahr - 1, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("ansatzPJfort", this.props.jahr, id, "Gesamtsoll", false, false, false, "", "", false, true));
                    id++;
                    fieldPrev = "ansatzPJfort";

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("verfuegt", this.props.jahr, id, "Ausgeführt", false, false, false, "", "", false, false, true));
                    id++;
                    fieldPrev = "verfuegt";

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataPercentColumn("verfuegt", "ansatzPJfort", this.props.jahr, id, "Ausgeführt", this.state.alleJahre));
                    id++;
                    fieldPrev = undefined;

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("verfuegbar", this.props.jahr, id, "Einzunehmen / Verfügbar", false, false));
                    id++;
                    fieldPrev = "verfuegbar";

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataPercentColumn("verfuegbar", "ansatzPJfort", this.props.jahr, id, "Enzunehmen / Verfügbar", this.state.alleJahre));
                    id++;
                    fieldPrev = undefined;

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("progAuto", this.props.jahr, id, "Prognose errechnet", false, false, false, "", "", true));
                    id++;
                    fieldPrev = "progAuto";

                    columns.push(this.createChangeColumn("ansatzPJfort", "progAuto", id, "Gesamtsoll"));
                    id++;

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("progMan", this.props.jahr, id, "Prognose manuell", false, false, false, "hasProgMan", "progKomm"));
                    id++;
                    fieldPrev = "progMan";

                    columns.push(this.createChangeColumn("ansatzPJfort", "progMan", id, "Gesamtsoll"));
                    id++;

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.settings.importFormat !== 0 && this.props.settings.importFormat !== 1 && (this.props.summenTyp < 11 || this.props.summenTyp > 16)) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("istAo", this.props.jahr, id, "Ist AO", false, false));
                    id++;
                    fieldPrev = "istAo";


                }
                else {
                    fieldPrev = undefined;
                }
            }

        }
        else {
            //if (data.findIndex(d => d.ergebnisVvvJ !== 0) >= 0) {
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                columns.push(this.createDataColumn("ergebnisVvvJ", this.props.jahr - 3, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvvJ", id));
                    id++;
                }
                fieldPrev = "ergebnisVvvJ";
            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                columns.push(this.createDataColumn("ergebnisVvJ", this.props.jahr - 2, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ", id));
                    id++;
                }
                fieldPrev = "ergebnisVvJ";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                columns.push(this.createDataColumn("ansatzVJ", this.props.jahr - 1, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ", id));
                    id++;
                }
                fieldPrev = "ansatzVJ";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                columns.push(this.createDataColumn("ansatzPj", this.props.jahr, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj", id, "Vorjahr", true));
                    id++;
                }
                fieldPrev = "ansatzPj";
            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 1) >= 0) {
                columns.push(this.createDataColumn("fp1", this.props.jahr + 1, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1", id, "Vorjahr", this.props.settings.doppelhaushalt ? true : false));
                    id++;
                }
                fieldPrev = "fp1";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 2) >= 0) {
                columns.push(this.createDataColumn("fp2", this.props.jahr + 2, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2", id));
                    id++;
                }
                fieldPrev = "fp2";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 3) >= 0) {
                columns.push(this.createDataColumn("fp3", this.props.jahr + 3, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3", id));
                    id++;
                }
                fieldPrev = "fp3";
            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 4) >= 0) {
                columns.push(this.createDataColumn("fp4", this.props.jahr + 4, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp4", id));
                    id++;
                }
                fieldPrev = "fp4";
            }
            else {
                fieldPrev = undefined;
            }
        }


        return columns;
    }

    createHighDifItems = (rows: KSLPlusAPI.HaushaltsZeile[], field1: string, field2: string) => {
        var items = [];

        for (let i = 0; i < Math.min(3, rows.length); i++) {
            const rowAsArray = rows[i] as unknown as { [key: string]: number };
            if (rowAsArray[field2] - rowAsArray[field1] != 0) {
                items.push(<div key={i.toString()}><Row><Col lg="8"><div className="kslplus-change-popover-bezeichnung">{rows[i].bezeichnung}</div></Col><Col><div className="kslplus-change-popover-wert">{Utilities.FormatCurrency(rowAsArray[field2] - rowAsArray[field1], 2, 0, '.', ',') + " EUR"}</div></Col></Row></div>);
            }
        }
        return items;
    }

    createHighDifItems1 = (rows: KSLPlusAPI.HaushaltsZeilenDetails[], field1: string, field2: string) => {
        var items = [];

        for (let i = 0; i < Math.min(3, rows.length); i++) {
            const rowAsArray = rows[i] as unknown as { [key: string]: number };
            if (rowAsArray[field2] - rowAsArray[field1] != 0) {
                {
                    if (rows[i].produktStellen)
                        items.push(<div key={i.toString()}><Row><Col lg="8"><div className="kslplus-change-popover-bezeichnung">{Utilities.Num2Str(rows[i].produkt, rows[i].produktStellen) + "." + Utilities.Num2Str(rows[i].konto, rows[i].kontoStellen) + " " + rows[i].bezeichnung}</div></Col><Col><div className="kslplus-change-popover-wert">{Utilities.FormatCurrency(rowAsArray[field2] - rowAsArray[field1], 2, 0, '.', ',') + " EUR"}</div></Col></Row></div>);
                    else
                        items.push(<div key={i.toString()}><Row><Col lg="8"><div className="kslplus-change-popover-bezeichnung">{Utilities.Num2Str(rows[i].konto, rows[i].kontoStellen) + " " + rows[i].bezeichnung}</div></Col><Col><div className="kslplus-change-popover-wert">{Utilities.FormatCurrency(rowAsArray[field2] - rowAsArray[field1], 2, 0, '.', ',') + " EUR"}</div></Col></Row></div>);
                }

            }
        }
        return items;
    }

    createChangeColumn = (field1: string, field2: string, id: number, text: string = "Vorjahr", pjFarben: boolean = false, jrFarben: boolean = false) => {
        return {
            dataField: "dummy" + id.toString(),
            text: "+/-",
            headerAlign: 'right',
            align: 'right',
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                var items: JSX.Element[] = [];
                if (row['unterzeilen'] !== undefined && this.props.hh.zeilen) {
                    const zeilen = row['unterzeilen'] as unknown as string[];
                    var unterEbenen = this.props.hh.zeilen.filter(d => zeilen.findIndex(z => z === d.zeile) >= 0);
                    let sorted = unterEbenen.slice();;

                    sorted = sorted.sort((r1, r2) => {
                        const row1 = r1 as unknown as { [key: string]: number };
                        const row2 = r2 as unknown as { [key: string]: number };

                        return (Math.abs(row2[field1] - row2[field2]) - Math.abs(row1[field1] - row1[field2]))

                    });
                    items = this.createHighDifItems(sorted, field1, field2);

                }
                else {

                    let sorted: KSLPlusAPI.HaushaltsZeilenDetails[] = [];
                    var unterEbenen: KSLPlusAPI.HaushaltsZeile[] = [];


                    if (row['unterEbenen'] !== undefined)
                        unterEbenen = row['unterEbenen'] as unknown as KSLPlusAPI.HaushaltsZeilenDetails[];
                    else if (row['details'] !== undefined) {
                        const details = row['details'] as unknown as KSLPlusAPI.HaushaltsZeilenDetails;
                        if (details.unterEbenen !== undefined)
                            unterEbenen = details.unterEbenen
                    }


                    //if (details.unterEbenen && details.kontoStellen && details.kontoStellen < 8) {
                    sorted = unterEbenen.slice();
                    //}
                    sorted = sorted.sort((r1, r2) => {
                        const row1 = r1 as unknown as { [key: string]: number };
                        const row2 = r2 as unknown as { [key: string]: number };

                        return (Math.abs(row2[field1] - row2[field2]) - Math.abs(row1[field1] - row1[field2]))

                    });

                    items = this.createHighDifItems1(sorted, field1, field2);
                }

                var res = Utilities.calcArrowRotAndColor(row[field1], row[field2], row["ausgaben"] > 0, this.props.settings);


                var ttId = row.id ? "kslplus-label-" + id + "_" + row.id : "";
                ttId = ttId + ((row["ausgaben"] > 0) ? "-a" : "-e");

                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%" >
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className={items.length > 0 ? "kslplus-change-popover-doppisch-detailiert-body" : "kslplus-change-popover-body"}>
                                <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP - 1) * 100, 2, 0, '.', ',') + "  %)"}</div>
                                {items.length > 0 && <br />}
                                {items.length > 0 && <div className="kslplus-change-popover-headline">Davon wesentlich:</div>}
                                {items.length > 0 &&
                                    <div className="kslplus-change-popover-container-werte">
                                        {items}
                                    </div>
                                }
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>

                );

            },
            headerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                if (jrFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbeJR, color: this.props.settings.vFarbeJR };


                return { width: "40px" };
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if (pjFarben) {
                    var bk = this.props.settings.hFarbePJ;
                    if (row["summenTiefe"] > 1)
                        bk = this.props.settings.hFarbeSumPJ;


                    return { width: "40px", backgroundColor: bk, color: this.props.settings.vFarbePJ };
                }

                if (jrFarben) {
                    var bk = this.props.settings.hFarbeJR;
                    if (row["summenTiefe"] > 1)
                        bk = this.props.settings.hFarbeSumJR;


                    return { width: "40px", backgroundColor: bk, color: this.props.settings.vFarbeJR };
                }
                

                return { width: "40px" };
            }
        };
    }

    createDataColumn = (field: string, jahr: number, id: number, text: string = "", ttE: boolean = false, ttF: boolean = false, hidden: boolean = false, testfield: string = "", komfield: string = "", pkTip: boolean = false, gsTip: boolean = false, ausgefuehrtTip: boolean = false) => {
        if (text == "") {
            if (jahr - this.props.jahr == -2 && this.props.plantyp < 3 && this.props.settings.vvjVorl )
                text = "vorl. Ergebnis ";
            else if (jahr - this.props.jahr < -1)
                text = "Ergebnis";
            else if (jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0))
                text = "Finanzplan";
            else
                text = "Ansatz";
        }
        return {
            dataField: field,
            text: text + " " + jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerStyle: (colum: any, colIndex: any) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                else if (this.props.plantyp === 3 && jahr - this.props.jahr === 0) {
                    return { backgroundColor: this.props.settings.hFarbeJR, color: this.props.settings.vFarbeJR };
                }
                else
                    return {};
            },
            headerFormatter: (column: any, columnIndex: any) => {
                if (ausgefuehrtTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Ausgeführt errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.verfuegtSpaltenDic &&
                                            Utilities.createAusgefuehrtTooltipItems(this.props.uhconfig.verfuegtSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (gsTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Gesamtsoll errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.fortAnsatzSpaltenDic &&
                                            Utilities.createGSTooltipItems(this.props.uhconfig.fortAnsatzSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (ttE) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Ergebnis (Rechnungsergebnis) errechnet sich wie folgt:</div></div>
                                        <br />
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ausgeführtes Soll auf lfd. Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang auf Kassenreste aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Neue Haushaltsreste</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang Haushaltsreste aus Vj.</div></div>


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (ttF) {

                    var ttId = "header-tt-gl-fort-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>
                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Der fortgeschrieben Ansatz errechnet sich wie folgt:</div></div>
                                        <br />
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Nachtrag</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Sollveränderungen</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Übertragungen aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">über-/außerplanmäßige Bewilligungen</div></div>
                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else {
                    return (
                        <div className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>
                    );
                }
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if (testfield != "" && row[testfield])
                    return { backgroundColor: this.props.uhconfig && this.props.uhconfig.hfarbeProgMan ? this.props.uhconfig.hfarbeProgMan : 'lightcoral', color: this.props.uhconfig && this.props.uhconfig.vfarbeProgMan ? this.props.uhconfig.vfarbeProgMan : 'black' };
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {

                    var bk = this.props.settings.hFarbePJ;

                    if (row["summenTiefe"] > 1)
                        bk = this.props.settings.hFarbeSumPJ;

                
                    return { backgroundColor: bk, color: this.props.settings.vFarbePJ };
                }
                if (this.props.plantyp === 3 && (jahr - this.props.jahr) === 0 ) {

                    var bk = this.props.settings.hFarbeJR;

                    if (row["summenTiefe"] > 1)
                        bk = this.props.settings.hFarbeSumJR;


                    return { backgroundColor: bk, color: this.props.settings.vFarbeJR };
                }
                return {};
            },
            formatter: (cellContent: string, row: { [key: string]: any }) => {
                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }

                if (pkTip && row["pkTip"]) {
                    return (<div className="kslplus-tabellen-data" >{row["pkTip"] ? <div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-progtip-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-progtip-" + row.id : ""}><div className="kslplus-tooltip">{row["pkTip"]}</div></UncontrolledTooltip></div> : null}{Utilities.FormatCurrency(row[field] as number, nk, 3, '.', ',')}</div>);
                }
                if (komfield !== "") {
                    return (<div className="kslplus-tabellen-data" >{row[komfield] ? <div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-progkomm-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-progkomm-" + row.id : ""}><div className="kslplus-tooltip">{row[komfield]}</div></UncontrolledTooltip></div> : null}{Utilities.FormatCurrency(row[field] as number, nk, 3, '.', ',')}</div>);
                }

                if (row["jahrTips"] && row["jahrTips"][jahr]) { 
                    return (<div className="kslplus-tabellen-data" >{<div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-jahr-" + jahr + "-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-jahr-" + jahr + "-" + row.id : ""}><div className="kslplus-tooltip">{row["jahrTips"][jahr]}</div></UncontrolledTooltip></div>}{Utilities.FormatCurrency(row[field] as number, nk, 3, '.', ',')}</div>);
                }
                
                    
                
                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field] as number, nk, 3, '.', ',')} </div>
                );

            }
        };
    }



    createDataPercentColumn = (field: string, field1: string, jahr: number, id: number, text: string = "", hidden: boolean = false, ttE: boolean = false, ttF: boolean = false) => {
        if (text == "") {
            if (jahr - this.props.jahr < -1)
                text = "Ergebnis";
            else if (jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0))
                text = "Finanzplan";
            else
                text = "Ansatz";
        }
        return {
            dataField: field,
            text: text + " " + jahr + " (%)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(%)</div></div>
                );
            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                if (Math.round(row[field] * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-data">0,00</div>
                    );
                else if (row[field1] === 0 && row[field] < 0) {
                    return (<div className="kslplus-tabellen-data">-Ꝏ</div>);
                }
                else if (row[field1] === 0) {

                    return (<div className="kslplus-tabellen-data">Ꝏ</div>);
                }
                else
                    return (
                        <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field] / row[field1] * 100.0, 2, 3, '.', ',')}</div>
                    );

            }
        };
    }

    hideRows2 = (zeilen: KSLPlusAPI.HaushaltsZeilenDetails[]) => {
        var t = this;
        
        zeilen.forEach(r => {
            r.changeFlag = !r.changeFlag;
            //t.state.expanded = t.state.expanded.filter(x => x !== r.id);            
            t.collapsRow(r);            
            t.setState({ expanded: t.state.expanded });
            //t.setState({ expanded: t.state.expanded.filter(x => x !== r.id) });
            if (r.unterEbenen)
                t.hideRows2(r.unterEbenen)

        });
        
    }

    hideRows1 = (zeilen: string[] | undefined) => {
        if (this.props.hh.zeilen) {
            const rows = this.props.hh.zeilen.filter(d => (!zeilen && !d.summenZeile) || (zeilen && zeilen.findIndex(z => d.zeile === z) >= 0));
            var t = this;
            
            rows.forEach(r => {
                r.changeFlag = !r.changeFlag;
                //t.setState({ expanded: t.state.expanded.filter(x => x !== r.id) });

                
                t.state.expanded = t.state.expanded.filter(x => x !== r.id);  
                if (r.details && r.details.unterEbenen)
                    r.details.unterEbenen.forEach(r => this.collapsRow(r));
                if (r.unterzeilen)
                    t.hideRows1(r.unterzeilen);
                if (r.details && r.details.unterEbenen)
                    t.hideRows2(r.details.unterEbenen);
                                
            });

            

        }
    }

    getRows2Hide = (zeilen: string[] | undefined) => {
        if (!this.props.hh.zeilen) {
            return [];
        }
        if (zeilen)
            return this.props.hh.zeilen.filter(d => zeilen.findIndex(z => d.zeile === z) >= 0);
        else
            return this.props.hh.zeilen.filter(d => !d.summenZeile && this.props.hh.zeilen && this.props.hh.zeilen.findIndex(z => z.unterzeilen && z.unterzeilen.findIndex(z1 => z1 === d.zeile) !== -1) !== -1);

    }

    hideRows = (zeilen: string[] | undefined) => {
        if (this.props.hh.zeilen) {
            /*            
            const rows = this.props.hh.zeilen.filter(d => !zeilen && !d.summenZeile || (zeilen && zeilen.findIndex(z => d.zeile === z) >= 0));

            var t = this;                
                rows1.forEach(r => {
                    r.verbergen = true;
                    t.state.expanded = t.state.expanded.filter(x => x !== r.id);
                    if (r.details && r.details.unterEbenen)
                        r.details.unterEbenen.forEach(r => this.collapsRow(r));
                    if (r.unterzeilen) {
                        t.hideRows(r.unterzeilen);
                        t.hideRows1(r.unterzeilen);
                    }
                });
            */

            const rows = this.getRows2Hide(zeilen);
            var t = this;

            rows.forEach(r => {
                r.verbergen = true;
                t.state.expanded = t.state.expanded.filter(x => x !== r.id);
                if (r.details && r.details.unterEbenen)
                    r.details.unterEbenen.forEach(r => this.collapsRow(r));
                if (r.unterzeilen) {
                    t.hideRows(r.unterzeilen);
                    t.hideRows1(r.unterzeilen);
                }
            });
            

            this.setState({ expanded: this.state.expanded, hiddenRows: this.props.hh.zeilen.filter(d => d.verbergen).map(d => d.id) });
        }
    }

    showRows = (zeilen: string[] | undefined) => {
        if (this.props.hh.zeilen) {
            const rows = this.props.hh.zeilen.filter(d => (!zeilen && !d.summenZeile) || (zeilen && zeilen.findIndex(z => d.zeile === z) >= 0));
            rows.forEach(r => r.verbergen = false);            
            this.setState({ hiddenRows: this.props.hh.zeilen.filter(d => d.verbergen).map(d => d.id) });
        }
    }

    createColumns = (data: KSLPlusAPI.HaushaltsZeile[]) => {
        var columns = [];

        columns.push({
            dataField: 'changeFlag',
            text: "",
            headerFormatter: (colum: any, colIndex: any) => {
                if (this.props.summenTyp == 1 || this.props.summenTyp == 2) {
                    //if (this.props.hh.zeilen && this.state.hiddenRows.length === this.props.hh.zeilen.filter(d => !d.summenZeile).length) {
                    if (this.props.hh.zeilen && this.state.hiddenRows.length === this.getRows2Hide(undefined).length) {
                        return (
                            <div className="kslplus-tabellen-data" onClick={() => { this.showRows(undefined) }}>(+)</div>
                        );
                    }
                    else {
                        return (
                            <div className="kslplus-tabellen-data" onClick={() => { this.state.expanded = []; this.hideRows(undefined) }}>(-)</div>
                        );
                    }
                }
            },
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'left' };
            },
            style: (colum: any, colIndex: any) => {
                return { width: '50px' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsZeile) => {
                if (this.props.hh.zeilen && row.unterzeilen && row.unterzeilen.length > 0) {
                    if (this.props.hh.zeilen.findIndex(d => row.unterzeilen && row.unterzeilen.findIndex(z => z === d.zeile) >= 0 && d.verbergen) >= 0) {
                        return (
                            <div className="kslplus-tabellen-data" >(+)</div>
                        );
                    }
                    else
                        return (
                            <div className="kslplus-tabellen-data" >(-)</div>
                        );
                }
                else if (row.details && row.details.unterEbenen && row.details.unterEbenen.length) {
                    // immer ++ rendern -> Änderung zw. ++ und -- erfolgt in handleOnExpand
                    return (
                        <div className="kslplus-tabellen-data" id={"kslplus-tabexpand-" + row.id} >{this.props.summenTyp > 10 ? "(+)" : "(++)"}</div>
                    );

                }
                else
                    return null;
            }

        });

        if (this.props.summenTyp < 11 || (this.props.summenTyp > 16 && this.props.summenTyp < 21)) {
            columns.push({
                dataField: 'zeile',
                text: this.props.hh.bezZeilen ? this.props.hh.bezZeilen : "Zeile",
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '65px', textAlign: 'left' };
                },

                style: (colum: any, colIndex: any) => {
                    return { width: '65px' };
                }

            });
        }

        var bez = ""
        if (this.props.summenTyp > 10) {
            if (this.props.summenTyp == 11 || this.props.summenTyp == 13)
                bez = "AKTIVA/Konten";
            else if (this.props.summenTyp == 12 || this.props.summenTyp == 14)
                bez = "PASSIVA/Konten";
            else if (this.props.summenTyp == 15)
                bez = "Forderungen/Konten";
            else if (this.props.summenTyp == 16)
                bez = "Verbindlichkeiten/Konten";
        }
        else if (this.props.summenTyp == 1 || this.props.summenTyp == 3)
            bez = "Ertrags- und Aufwandsarten (Konten/Zeilen)";
        else
            bez = "Einzahlungen- und Auszahlungen (Konten/Zeilen)";


        columns.push({
            dataField: 'bezeichnung',
            text: bez,
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '530px', textAlign: 'left' };
            },

            style: (colum: any, colIndex: any) => {
                return { width: '530px' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsZeile) => {
                if (row.opZeichen && row.opZeichen.length > 0) {
                    return (
                        <div className="kslplus-tabellen-data">{row.opZeichen + " " + row.bezeichnung}</div>
                    );
                }
                else
                    return (
                        <div className="kslplus-tabellen-data">{row.bezeichnung}</div>
                    );

            }
        });



        if (data == null || !this.props.hh.jahre)
            return columns;



        if (this.props.plantyp == 4 && (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) != -1 || this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) != -1)) {
            columns.push({
                dataField: '',
                isDummyField: true,
                headerAlign: 'middle',
                headerFormatter: (column: any, columnIndex: any) => {
                    var ttId = "kslplus-tt-pjcol-" + this.props.callerID;
                    return (<div>
                        <div id={ttId}>{this.state.alleJahre ? "-" : "+"}</div >
                        <UncontrolledPopover
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-header-popover">
                            <PopoverBody className="kslplus-header-popover-body">
                                <Container>
                                    <div>
                                        {"Zusätzliche Jahre " + (this.state.alleJahre ? "verbergen" : "anzeigen")}
                                    </div>
                                </Container>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div >);

                },
                text: this.state.alleJahre ? "-" : "+",
                headerStyle: (colum: any, colIndex: any) => {
                    return { fontWeight: 900, width: '30px', textAlign: 'left' };
                },

                style: (colum: any, colIndex: any) => {
                    return { width: '30px' };
                },
                headerEvents: {
                    onClick: (e: any, column: any, columnIndex: any) => { this.setState({ alleJahre: !this.state.alleJahre }) }
                }
            });
        }

        var id = 2;


        var fieldPrev: string | undefined;

        if (this.props.plantyp === 3) {
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM4", this.props.jahr - 4, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM4";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM3", this.props.jahr - 3, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM3";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM2", this.props.jahr - 2, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM2";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM1", this.props.jahr - 1, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM1";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.summenTyp < 11) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("ansatzPJfort", this.props.jahr, id, "Fortgeschriebener Ansatz", false, true));
                    id++;
                    fieldPrev = "ansatzPJfort";

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJ", this.props.jahr, id, "Ergebnis", this.props.summenTyp < 11));
                id++;
                if (this.props.summenTyp < 11) {
                    if (fieldPrev && id > 3) {
                        columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ", id, "fortgeschriebenen Ansatz", false, true));
                        id++;
                    }
                }
                else {
                    if (fieldPrev && id > 3) {
                        columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ", id, undefined, false, true));
                        id++;
                    }
                }
                fieldPrev = "ergebnisPJ";

            }
            else {
                fieldPrev = undefined;
            }

        }
        else if (this.props.plantyp === 4) {
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM4", this.props.jahr - 4, id, "Ergebnis", false, false, !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM3", this.props.jahr - 3, id, "Ergebnis", false, false, !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM2", this.props.jahr - 2, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM2";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM1", this.props.jahr - 1, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("ansatzPJfort", this.props.jahr, id, "Gesamtsoll", false, false, false, "", "", false, true));
                    id++;
                    fieldPrev = "ansatzPJfort";

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("verfuegt", this.props.jahr, id, "Ausgeführt", false, false, false, "", "", false, false, true));
                    id++;
                    fieldPrev = "verfuegt";

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataPercentColumn("verfuegt", "ansatzPJfort", this.props.jahr, id, "Ausgeführt", this.state.alleJahre));
                    id++;
                    fieldPrev = undefined;

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("verfuegbar", this.props.jahr, id, "Einzunehmen / Verfügbar", false, false));
                    id++;
                    fieldPrev = "verfuegbar";

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataPercentColumn("verfuegbar", "ansatzPJfort", this.props.jahr, id, "Einzunehmen / Verfügbar", this.state.alleJahre));
                    id++;
                    fieldPrev = undefined;

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("progAuto", this.props.jahr, id, "Prognose errechnet", false, false));
                    id++;
                    fieldPrev = "progAuto";

                    columns.push(this.createChangeColumn("ansatzPJfort", "progAuto", id, "Gesamtsoll"));
                    id++;

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("progMan", this.props.jahr, id, "Prognose manuell", false, false, false, "hasProgMan"));
                    id++;
                    fieldPrev = "progMan";

                    columns.push(this.createChangeColumn("ansatzPJfort", "progMan", id, "Gesamtsoll"));
                    id++;

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.settings.importFormat !== 0 && this.props.settings.importFormat !== 1 && (this.props.summenTyp < 11 || this.props.summenTyp > 16)) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("istAo", this.props.jahr, id, "Ist AO", false, false));
                    id++;
                    fieldPrev = "istAo";


                }
                else {
                    fieldPrev = undefined;
                }
            }
        }
        else {
            //if ( data.findIndex(d => d.ergebnisVvvJ !== 0) >= 0 )
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                columns.push(this.createDataColumn("ergebnisVvvJ", this.props.jahr - 3, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvvJ", id));
                    id++;
                }
                fieldPrev = "ergebnisVvvJ";
            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                columns.push(this.createDataColumn("ergebnisVvJ", this.props.jahr - 2, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ", id));
                    id++;
                }
                fieldPrev = "ergebnisVvJ";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                columns.push(this.createDataColumn("ansatzVJ", this.props.jahr - 1, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ", id));
                    id++;
                }
                fieldPrev = "ansatzVJ";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                columns.push(this.createDataColumn("ansatzPj", this.props.jahr, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj", id, "Vorjahr", true));
                    id++;
                }
                fieldPrev = "ansatzPj";
            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 1) >= 0) {
                columns.push(this.createDataColumn("fp1", this.props.jahr + 1, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1", id, "Vorjahr", this.props.settings.doppelhaushalt ? true : false));
                    id++;
                }
                fieldPrev = "fp1";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 2) >= 0) {
                columns.push(this.createDataColumn("fp2", this.props.jahr + 2, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2", id));
                    id++;
                }
                fieldPrev = "fp2";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 3) >= 0) {
                columns.push(this.createDataColumn("fp3", this.props.jahr + 3, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3", id));
                    id++;
                }
                fieldPrev = "fp3";
            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 4) >= 0) {
                columns.push(this.createDataColumn("fp4", this.props.jahr + 4, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp4", id));
                    id++;
                }
                fieldPrev = "fp4";
            }
            else {
                fieldPrev = undefined;
            }
        }





        return columns;
    }



    rowStyles = (row: KSLPlusAPI.HaushaltsZeile, rowIndex: number) => {
        const style: any = {};
        if (row.summenZeile) {
            style.fontWeight = 'bold';
        }
        return style;
    }

    rowClasses1 = (row: KSLPlusAPI.HaushaltsZeilenDetails, rowIdx: number) => {
        
        return "kslplus-row-sum-0 " + "kslplus-row-id-" + row.id;
    }

    rowClasses = (row: KSLPlusAPI.HaushaltsZeile, rowIdx: number) => {

        if (row.summenTiefe)
            return "kslplus-row-id-" + row.id + " kslplus-row-sum-" + row.summenTiefe.toString();

        return "kslplus-row-id-" + row.id + " kslplus-row-sum-0";
    }

    createBarChartDataRow = (r: KSLPlusAPI.Reihe, z: KSLPlusAPI.HaushaltsZeile, field: string, jahr: number, min: any[], max: any[], basis: string | null = null) => {
        var data: any[] = [];

        
        const row = z as unknown as { [key: string]: number };        

        data.push(row[field]);
        if (row[field] < min[0])
            min[0] = row[field];
        if (row[field] > max[0])
            max[0] = row[field];        
        var style = "color: " + r.rahmenFarbe;
        if (r.farbeNeg && r.farbePos) {
            if ( row[field] < 0 )
                style += ";fill-color: " + r.farbeNeg;
            else if ( row[field] > 0) 
                style += ";fill-color: " + r.farbePos;

            //style += ";stroke-width: 3; fill-opacity: 0.5";
            style += ";stroke-width: 3";
        }
        data.push(style);
        if (basis !== null) {
            if (row[basis] == row[field])
                data.push(0);
            else if (row[basis] == 0 && row[field] > 0 )
                data.push(1000);
            else if (row[basis] == 0 && row[field] < 0)
                data.push(-1000);
            else
                data.push((row[field] / row[basis] - 1) * 100);

        }
        else
            data.push(100);
        if (data[data.length - 1] < min[1])
            min[1] = data[data.length - 1];
        if (data[data.length - 1] > max[1])
            max[1] = data[data.length - 1];
        data.push(style);

        
        return data;
        
    }

    pushToTreeMapData = (parent: string, parentZeile: KSLPlusAPI.HaushaltsZeilenDetails, zeilen: KSLPlusAPI.HaushaltsZeilenDetails[], data: any[][], jahr: number | undefined) => {
        
        var col = 0;
        zeilen.forEach(z => {            
            var bez = "";
            if (z.produktStellen) {
                bez = (z.produktStellen ? (Utilities.Num2Str(z.produkt, z.produktStellen) + ".") : "");              
                if (z.investNr)
                    bez += Utilities.Num2Str(z.konto, z.kontoStellen) + "-" + z.investNr.toString() + " - " + z.bezeichnung;
                else
                    bez += Utilities.Num2Str(z.konto, z.kontoStellen) + " - " + z.bezeichnung;                
            }
            else {
                bez = Utilities.Num2Str(z.konto, z.kontoStellen) + " - " + z.bezeichnung;
            }
            
            if (z && data.findIndex(d => d[0] == bez) == -1) {
                var row: any[] = [bez, parent];

                if (this.props.plantyp === 3 || this.props.plantyp === 4) {
                    switch (jahr) {
                        case -4:
                            row.push(z.ergebnisPJM4);
                            row.push(col);
                            row.push((z.ergebnisPJM4 ? z.ergebnisPJM4 : 0) / (parentZeile.ergebnisPJM4 ? parentZeile.ergebnisPJM4 : 0) * 100);
                            break;

                        case -3:
                            row.push(z.ergebnisPJM3);
                            row.push(col);
                            row.push((z.ergebnisPJM3 ? z.ergebnisPJM3 : 0) / (parentZeile.ergebnisPJM3 ? parentZeile.ergebnisPJM3 : 0) * 100);
                            break;

                        case -2:
                            row.push(z.ergebnisPJM2);
                            row.push(col);
                            row.push((z.ergebnisPJM2 ? z.ergebnisPJM2 : 0) / (parentZeile.ergebnisPJM2 ? parentZeile.ergebnisPJM2 : 0) * 100);
                            break;

                        case -1:
                            row.push(z.ergebnisPJM1);
                            row.push(col);
                            row.push((z.ergebnisPJM1 ? z.ergebnisPJM1 : 0) / (parentZeile.ergebnisPJM1 ? parentZeile.ergebnisPJM1 : 0) * 100);
                            break;

                        case 0:
                            var v = this.props.plantyp === 4 ? z.progMan : z.ergebnisPJ;
                            var pV = this.props.plantyp === 4 ? parentZeile.progMan : parentZeile.ergebnisPJ;
                            row.push(v);
                            row.push(col);
                            row.push((v ? v : 0) / (pV ? pV : 0) * 100);
                            break;

                    }
                }
                else {
                    switch (jahr) {
                        case -3:
                            row.push(z.ergebnisVvvJ);
                            row.push(col);
                            row.push((z.ergebnisVvvJ ? z.ergebnisVvvJ : 0) / (parentZeile.ergebnisVvvJ ? parentZeile.ergebnisVvvJ : 0) * 100);
                            break;

                        case -2:
                            row.push(z.ergebnisVvJ);
                            row.push(col);
                            row.push((z.ergebnisVvJ ? z.ergebnisVvJ : 0) / (parentZeile.ergebnisVvJ ? parentZeile.ergebnisVvJ : 0) * 100);
                            break;

                        case undefined:
                        case -1:
                            row.push(z.ansatzVJ);
                            row.push(col);
                            row.push((z.ansatzVJ ? z.ansatzVJ : 0) / (parentZeile.ansatzVJ ? parentZeile.ansatzVJ : 0) * 100);
                            break;

                        case 0:
                            row.push(z.ansatzPj);
                            row.push(col);
                            row.push((z.ansatzPj ? z.ansatzPj : 0) / (parentZeile.ansatzPj ? parentZeile.ansatzPj : 0) * 100);
                            break;

                        case 1:
                            row.push(z.fp1);
                            row.push(col);
                            row.push((z.fp1 ? z.fp1 : 0) / (parentZeile.fp1 ? parentZeile.fp1 : 0) * 100);
                            break;

                        case 2:
                            row.push(z.fp2);
                            row.push(col);
                            row.push((z.fp2 ? z.fp2 : 0) / (parentZeile.fp2 ? parentZeile.fp2 : 0) * 100);
                            break;

                        case 3:
                            row.push(z.fp3);
                            row.push(col);
                            row.push((z.fp3 ? z.fp3 : 0) / (parentZeile.fp3 ? parentZeile.fp3 : 0) * 100);
                            break;

                        case 4:
                            row.push(z.fp4);
                            row.push(col);
                            row.push((z.fp4 ? z.fp4 : 0) / (parentZeile.fp4 ? parentZeile.fp4 : 0) * 100);
                            break;

                    }
                }
                col++;
                //row.forEach((val: number, idx, data) => { if (idx > 0) data[idx] = val / 1000 });
                data.push(row);

                if (z.unterEbenen && z.unterEbenen.length > 0) {
                    this.pushToTreeMapData(bez, z, z.unterEbenen, data, jahr);
                }
                else if (z.unterEbenen && z.unterEbenen.length == 1 && z.unterEbenen[0].unterEbenen && z.unterEbenen[0].unterEbenen.length > 1 && z.unterEbenen[0].unterEbenen[0].unterEbenen) {
                    this.pushToTreeMapData(bez, z.unterEbenen[0], z.unterEbenen[0].unterEbenen, data, jahr);
                }
                
            }
        });
    };

    createTreeMapData = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[], jahr: number | undefined) => {

        var data: any[][] = [];
        data.push(['Gruppe', 'Parent', 'Wert', 'Farbe', 'Änderung']);
        data.push([" ", null, 0, 0, 0]);

        var col = 0;
        if (g.reihen) {            
            var sum = 0;
            g.reihen.forEach(r => {

                const z = zeilen.find(z => z.zeile == r.zeile);


                if (z) {

                    var row: any[] = [z.bezeichnung, " "];

                    if (this.props.plantyp === 3 || this.props.plantyp === 4) {
                        switch (jahr) {
                            case -4:
                                if ( z.ergebnisPJM4 )
                                    sum += z.ergebnisPJM4;                                
                                break;

                            case -3:
                                if (z.ergebnisPJM3)
                                    sum += z.ergebnisPJM3;                                
                                break;

                            case -2:
                                if (z.ergebnisPJM2)
                                    sum += z.ergebnisPJM2;                                
                                break;

                            case -1:
                                if (z.ergebnisPJM1)
                                    sum += z.ergebnisPJM1;                                
                                break;

                            case 0:
                                var v = this.props.plantyp === 4 ? z.progMan : z.ergebnisPJ;
                                if (v)
                                    sum += v;                                
                                
                                break;

                        }
                    }
                    else {
                        switch (jahr) {
                            case -3:
                                if (z.ergebnisVvvJ)
                                    sum += z.ergebnisVvvJ;                                                                
                                break;

                            case -2:
                                if (z.ergebnisVvJ)
                                    sum += z.ergebnisVvJ;                                                                                                
                                break;

                            case undefined:
                            case -1:
                                if (z.ansatzVJ)
                                    sum += z.ansatzVJ;     
                                
                                break;

                            case 0:
                                if (z.ansatzPj)
                                    sum += z.ansatzPj;     
                                break;

                            case 1:
                                if (z.fp1)
                                    sum += z.fp1;                                     
                                break;

                            case 2:
                                if (z.fp2)
                                    sum += z.fp2;                                     
                                break;

                            case 3:
                                if (z.fp3)
                                    sum += z.fp3;                                     
                                break;

                            case 4:
                                if (z.fp4)
                                    sum += z.fp4;                                     
                                break;

                        }
                    }
                    
                }
            });
            g.reihen.forEach(r => {
                
                const z = zeilen.find(z => z.zeile == r.zeile);
                
                
                if (z) {
                    
                    var row: any[] = [z.bezeichnung, " "];

                    if (this.props.plantyp === 3 || this.props.plantyp === 4) {
                        switch (jahr) {
                            case -4:
                                row.push(z.ergebnisPJM4);                                
                                row.push(col);
                                row.push((z.ergebnisPJM4 ? z.ergebnisPJM4 : 0)/sum*100);                                
                                break;

                            case -3:
                                row.push(z.ergebnisPJM3);
                                row.push(col);
                                row.push((z.ergebnisPJM3 ? z.ergebnisPJM3 : 0) / sum * 100);                                
                                break;

                            case -2:
                                row.push(z.ergebnisPJM2);
                                row.push(col);
                                row.push((z.ergebnisPJM2 ? z.ergebnisPJM2 : 0) / sum * 100);                                
                                break;

                            case -1:
                                row.push(z.ergebnisPJM1);
                                row.push(col);
                                row.push((z.ergebnisPJM1 ? z.ergebnisPJM1 : 0) / sum * 100);                                
                                break;

                            case 0:
                                var v = this.props.plantyp === 4 ? z.progMan : z.ergebnisPJ;
                                row.push(v);
                                row.push(col);
                                row.push((v ? v: 0) / sum * 100);                                
                                break;

                        }
                    }
                    else {
                        switch (jahr) {
                            case -3:
                                row.push(z.ergebnisVvvJ);
                                row.push(col);
                                row.push((z.ergebnisVvvJ ? z.ergebnisVvvJ : 0) / sum * 100);                                
                                break;

                            case -2:
                                row.push(z.ergebnisVvJ);
                                row.push(col);
                                row.push((z.ergebnisVvJ ? z.ergebnisVvJ : 0) / sum * 100);                                
                                break;

                            case undefined:
                            case -1:
                                row.push(z.ansatzVJ);
                                row.push(col);
                                row.push((z.ansatzVJ ? z.ansatzVJ : 0) / sum * 100);                                
                                break;

                            case 0:
                                row.push(z.ansatzPj);
                                row.push(col);
                                row.push((z.ansatzPj ? z.ansatzPj : 0) / sum * 100);                                
                                break;

                            case 1:
                                row.push(z.fp1);
                                row.push(col);
                                row.push((z.fp1 ? z.fp1 : 0) / sum * 100);                                
                                break;

                            case 2:
                                row.push(z.fp2);
                                row.push(col);
                                row.push((z.fp2 ? z.fp2 : 0) / sum * 100);                                
                                break;

                            case 3:
                                row.push(z.fp3);
                                row.push(col);
                                row.push((z.fp3 ? z.fp3 : 0) / sum * 100);                                
                                break;

                            case 4:
                                row.push(z.fp4);
                                row.push(col);
                                row.push((z.fp4 ? z.fp4 : 0) / sum * 100);                                
                                break;

                        }                        
                    }
                    col++;
                    //row.forEach((val: number, idx, data) => { if (idx > 0) data[idx] = val / 1000 });
                    data.push(row);
                    if (z.details && z.details.unterEbenen && z.details.unterEbenen.length > 1) {
                        this.pushToTreeMapData(z.bezeichnung ? z.bezeichnung : "", z.details, z.details.unterEbenen, data, jahr);                        
                    }
                    else if (z.details && z.details.unterEbenen && z.details.unterEbenen.length == 1 && z.details.unterEbenen[0].unterEbenen && z.details.unterEbenen[0].unterEbenen.length > 1 && z.details.unterEbenen[0].unterEbenen[0].unterEbenen) {                        
                        this.pushToTreeMapData(z.bezeichnung ? z.bezeichnung : "", z.details.unterEbenen[0], z.details.unterEbenen[0].unterEbenen, data, jahr);
                    }
                }
            });
        }

        
        return data;
        /*
        var bHasData = false;
        
        for (var i = 1; i < data.length; i++) {
            if (data[i][2] as unknown as number < 0) {
                return null;
            }
            else if (data[i][2] as unknown as number > 0) {
                bHasData = true;
            }

        }

        if (bHasData)
            return data;
        */
        return null;
    }

    createPieChartData = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[], jahr: number|undefined) => {        

        var data = [];
        data.push(['Gruppe', 'Wert']);
        
        if ( g.reihen )
        {
            g.reihen.forEach(r => {
                const z = zeilen.find(z => z.zeile == r.zeile);
                if (z) {
                    var row: any[] = [z.bezeichnung];

                    if (this.props.plantyp === 3 || this.props.plantyp === 4) {
                        switch (jahr) {
                            case -4:
                                row.push(z.ergebnisPJM4);
                                break;

                            case -3:
                                row.push(z.ergebnisPJM3);
                                break;

                            case -2:
                                row.push(z.ergebnisPJM2);
                                break;

                            case -1:
                                row.push(z.ergebnisPJM1);
                                break;

                            case 0:
                                row.push(this.props.plantyp === 4 ? z.progMan : z.ergebnisPJ);
                                break;

                        }
                    }
                    else {
                        switch (jahr) {
                            case -3:
                                row.push(z.ergebnisVvvJ);
                                break;

                            case -2:
                                row.push(z.ergebnisVvJ);
                                break;

                            case undefined:
                            case -1:
                                row.push(z.ansatzVJ);
                                break;

                            case 0:
                                row.push(z.ansatzPj);
                                break;

                            case 1:
                                row.push(z.fp1);
                                break;

                            case 2:
                                row.push(z.fp2);
                                break;

                            case 3:
                                row.push(z.fp3);
                                break;

                            case 4:
                                row.push(z.fp4);
                                break;

                        }
                    }
                    row.forEach((val: number, idx, data) => { if ( idx > 0 ) data[idx] = val / 1000});
                    data.push(row);
                }
            });
        }

        
        var bHasData = false;
        for (var i = 1; i < data.length; i++) {
            if (data[i][1] as unknown as number < 0) {
                return null;
            }
            else if (data[i][1] as unknown as number > 0) {
                bHasData = true;
            }
                
        }

        if ( bHasData )
            return data;

        return null;      
    }

    createBarData = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[], jahr: number|undefined) => {        
        const colors = ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411'];
        //var colors = ["blue", "pink", "magenta", "purple", "lightblue", "orange"];
        var grdata = [];
        grdata.push(['Kontengruppe', 'Betrag [EUR]', { role: "style" }, {
          sourceColumn: 0,      
          role: "annotation",
          type: "string",
          calc: "stringify",
        }]);
        
        if ( g.reihen )
        {
            var i = 0;
            g.reihen.forEach(r => {                
                const z = zeilen.find(z => z.zeile == r.zeile);
                if (z) {
                    //var row : any[] = [z.bezeichnung];

                    var val: number | undefined;   
                    
                    if (this.props.plantyp === 3 || this.props.plantyp === 4 )
                    {
                        switch (jahr) {
                            case -4:
                                val = z.ergebnisPJM4;
                                break;

                            case -3:
                                val = z.ergebnisPJM3;
                                break;

                            case -2:
                                val = z.ergebnisPJM2;
                                break;
                            
                            case -1:
                                val = z.ergebnisPJM1;
                                break;

                            case 0:
                                val = this.props.plantyp === 4 ? z.progMan : z.ergebnisPJ;                                
                                break;

                        }      
                    }
                    else
                    {
                        switch (jahr) {
                            case -3:
                                val = z.ergebnisVvvJ;
                                break;

                            case -2:
                                val = z.ergebnisVvJ;
                                break;

                            case undefined:
                            case -1:
                                val = z.ansatzVJ;                        
                                break;

                            case 0:
                                val = z.ansatzPj;                                                
                                break;

                            case 1:
                                val = z.fp1;                                                                        
                                break;

                            case 2:
                                val = z.fp2;                                                
                                break;

                            case 3:
                                val = z.fp3;                                                
                                break;

                            case 4:
                                val = z.fp4;
                                break;

                        }         
                    }           
                    if (val !== undefined)
                        grdata.push([z.bezeichnung, val, colors[i++], null]);
                }
            });
        }

        
    
        var bHasData = false;
        for (var i = 1; i < grdata.length; i++) {
            if (grdata[i][1] as unknown as number != 0) {
                return grdata;
            }
                
        }

        return null;      

    }
    
    createBarChartData = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[], withDataOnly: boolean = false) => {        

        var data: any[] = [];
        var colors: string[] = [];
        var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];

        if (g.reihen) {
            var header:any[] = ["Jahr"];
            
            var bData = false;
            g.reihen.forEach(r => {
                const z = zeilen.find(z => z.zeile == r.zeile);
                if (z) {
                    header.push(z.bezeichnung);
                    header.push({ role: "style" });
                    header.push("Änderung " + z.bezeichnung + " (%)");
                    header.push({ role: "style" });
                    colors.push(r.rahmenFarbe ? r.rahmenFarbe : "");
                    colors.push(r.rahmenFarbe ? r.rahmenFarbe : "");
                    if ( z.containsData )
                        bData = true;
                }
            });

            if ( !bData && withDataOnly )
                return null;

            data.push(header);

            if (!this.props.hh.jahre )
                return { data: data, colors: colors };   

            var basis:string|null = null;

            if ( this.props.plantyp === 3 ) 
            {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 4).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {                            
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJM4", this.props.jahr - 3, min, max, basis));                            
                        }                                            
                    });
                    basis = "ergebnisPJM4";
                    data.push(reihe)
                }
                
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 3).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJM3", this.props.jahr - 3, min, max, basis));                            
                        }                        
                    
                    });
                    basis = "ergebnisPJM3";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 2).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJM2", this.props.jahr - 3, min, max, basis));                            
                        }                        
                    
                    });
                    basis = "ergebnisPJM2";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 1).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJM1", this.props.jahr - 3, min, max, basis));                            
                        }                                            
                    });
                    basis = "ergebnisPJM1";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    var reihe: any[] = [(this.props.jahr).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJ", this.props.jahr - 3, min, max, basis));                            
                        }                                            
                    });
                    basis = "ergebnisPJ";
                    data.push(reihe)
                }
            }
            else if (this.props.plantyp === 4) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 4).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJM4", this.props.jahr - 3, min, max, basis));
                        }
                    });
                    basis = "ergebnisPJM4";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 3).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJM3", this.props.jahr - 3, min, max, basis));
                        }

                    });
                    basis = "ergebnisPJM3";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 2).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJM2", this.props.jahr - 3, min, max, basis));
                        }

                    });
                    basis = "ergebnisPJM2";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 1).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisPJM1", this.props.jahr - 3, min, max, basis));
                        }
                    });
                    basis = "ergebnisPJM1";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    var reihe: any[] = [(this.props.jahr).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "progMan", this.props.jahr - 3, min, max, basis));
                        }
                    });
                    basis = "progMan";
                    data.push(reihe)
                }
            }
            else
            {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 3).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisVvvJ", this.props.jahr - 3, min, max, basis));                            
                        }
                    
                    });
                    basis = "ergebnisVvvJ";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 2).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisVvJ", this.props.jahr - 2, min, max, basis));                            
                        }

                    });
                    basis = "ergebnisVvJ";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                    var reihe: any[] = [(this.props.jahr - 1).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ansatzVJ", this.props.jahr - 1, min, max, basis));                            
                        }

                    });
                    basis = "ansatzVJ";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    var reihe: any[] = [this.props.jahr.toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "ansatzPj", this.props.jahr, min, max, basis));                            
                        }

                    });
                    basis = "ansatzPj";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 1) >= 0) {
                    var reihe: any[] = [(this.props.jahr + 1).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "fp1", this.props.jahr + 1, min, max, basis));                            
                        }

                    });
                    basis = "fp1";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 2) >= 0) {
                    var reihe: any[] = [(this.props.jahr + 2).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "fp2", this.props.jahr + 2, min, max, basis));                            
                        }

                    });
                    basis = "fp2";
                    data.push(reihe)
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 3) >= 0) {
                    var reihe: any[] = [(this.props.jahr + 3).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "fp3", this.props.jahr + 3, min, max, basis));                            
                        }

                    });
                    basis = "fp3";
                    data.push(reihe);
                }

                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 4) >= 0) {
                    var reihe: any[] = [(this.props.jahr + 4).toString()];

                    g.reihen.forEach(r => {
                        const z = zeilen.find(z => z.zeile == r.zeile);
                        if (z) {
                            reihe = reihe.concat(this.createBarChartDataRow(r, z, "fp4", this.props.jahr + 3, min, max, basis));
                        }

                    });
                    basis = "fp4";
                    data.push(reihe);
                }
            }

            
            
        }

        return { data: data, colors: colors, min: min, max: max };        
    }

    createBarChart = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[]) => {
        const data = this.createBarChartData(g, zeilen, this.props.summenTyp > 10);
        var graphOpt = Utilities.calcGrapOptions(data && data.min ? data.min : [], data && data.max ? data.max : [], this.props.settings.nullAchseAnzeigen);

        if (data && data.data && data.data.length > 1) {
            return (<Chart
                width="100%"
                height={'300px'}
                chartType="ComboChart"
                chartLanguage='de'
                loader={<div>Loading Chart</div>}
                data={data.data}
                options={{                    
                    is3D: true,
                    colors: data.colors,
                    vAxes: {
                        0: { title: 'EUR', minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: '%', minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max1, min: graphOpt.min1 } } },
                    hAxis: { title: 'Jahr' },
                    seriesType: 'bars',
                    series: { 0: { targetAxisIndex: 0 }, 1: { type: 'line', targetAxisIndex: 1 }, 2: { targetAxisIndex: 0 }, 3: { type: 'line', targetAxisIndex: 1 } },

                }}
                formatters={Utilities.createFormatters(g.reihen ? g.reihen.length + 1 : 2, 2)}
                rootProps={{ 'data-testid': '1' }}
                
            />);
        }
        else
            return null;

    }

    createBarChartHor = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[]) => {
        const barData = this.createBarData(g, zeilen, g.jahr);

        if (barData) {
            return (<Chart
                width="100%"
                height={'300px'}
                chartType="BarChart"
                chartLanguage='de'
                loader={<div>Loading Chart</div>}
                data={barData}
                options={{
                    bar: { groupWidth: "50%" },
                    legend: { position: "none" },
                    chartArea: { width: '50%' }

                }}
                //formatters={Utilities.createFormatters(this.state.ebene.filter.unterEbenen ? this.state.ebene.filter.unterEbenen.length : 10, 2)}
                rootProps={{ 'data-testid': '2' }}
            />);
        }
        else
            return null
    }

    createPieChart = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[] ) => {
        const pieData = this.createPieChartData(g, zeilen, g.jahr);

                
        if ( pieData )
        {            
            var options = {
                backgroundColor: 'none',
                is3D: this.props.settings.tD3d,
                pieSliceText: this.props.settings.tdText,
                legend: { position: this.props.settings.tdLegend, labeledValueText: this.props.settings.tdText == "value" ? "percentage" : "value" },
                pieSliceTextStyle: { fontSize: this.props.settings.tdFontSize != -1 ? this.props.settings.tdFontSize : undefined },
                sliceVisibilityThreshold: 0.00001                
            };

         
            return (<Chart
                    width="100%"
                    height={'300px'}
                    chartType="PieChart"
                    chartLanguage='de'                                                            
                    loader={<div>Loading Chart</div>}
                    data={pieData}
                    options={options}
                        
                    formatters={[
                                {
                                    type: 'NumberFormat',
                                    column: 1,
                                    options: {
                                        fractionDigits: 0,
                                        suffix: ' TEUR'
                                    }
                                }]}
                    rootProps={{ 'data-testid': '2' }}
            />);                                 
        }
        else 
        {
            return this.createBarChartHor(g, zeilen);
        }

    }
    
    moveTreeChartLabels = (container: HTMLElement | null) => {
        var newLabelCoords = { x: 8, y: 16 };
        if (!container)
            return;

        Array.prototype.forEach.call(container.getElementsByTagName('text'), function (text) {
            var bounds = text.getBBox();
            var rect = text.parentNode.getElementsByTagName('rect')[0];
            var rect = text.parentNode.getElementsByTagName('rect')[0];
            if (rect != null && (rect.getAttribute('fill') !== '#cccccc') && (text.getAttribute('text-anchor') === 'middle')) {                
                text.setAttribute('font-weight', 'bold');
                text.setAttribute('x', parseFloat(rect.getAttribute('x')) + newLabelCoords.x + (bounds.width / 2));
                text.setAttribute('y', parseFloat(rect.getAttribute('y')) + newLabelCoords.y);
            }
        });
    }

    modifyTreeChart = (container: HTMLElement|null, chartWrapper: GoogleChartWrapper, data: GoogleDataTable|null, hoverrow: number = -1, mouseover: boolean = false) => {
        const colors = ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411'];

        
        if (!data || !container || chartWrapper.getChartType() != "TreeMap")
            return;

        
        
        // find existing / build new labels
        Array.prototype.forEach.call(container.getElementsByTagName('text'), (text) => {

            // nur elemente ohne added attribute sind orignal elemente
            if (text.getAttribute('text-anchor') === 'middle' && !text.getAttribute('added')) {

                var rect = text.parentNode.getElementsByTagName('rect')[0];

                var added = false;
                if (text.parentNode) {

                    text.parentNode.childNodes.forEach((c: any) => {
                        if (c.getAttribute("added")) {
                            added = true;
                        }
                    });
                }
                // exclude top node
                if (rect.getAttribute('fill') !== '#cccccc') {
                    // angezeigten Text ohne ... ermitteln
                    var val = text.textContent as string;
                    val = val.replace(/…+$/, "");

                    var color = colors[0];
                    var cVal = 0;
                    var found = false;
                    var rFound = -1;
                    for (var r = 0; r < data.getNumberOfRows(); r++) {
                        if ((data.getValue(r, 0) as string).startsWith(val)) {
                            rFound = r;
                            if (found) {    // wenn mehr als ein Eintrag gefunden wurden, wird nichts unternommen
                                found = false;
                                break;
                            }

                            found = true;
                        }

                    }
                    if (found) {
                        color = colors[data.getValue(rFound, 3) as number % 31];
                        cVal = data.getValue(rFound, 4) as number;
                    }
                    if (!added) {
                        rect.setAttribute('fill', color);
                        if (found) {
                            var textLabel = text.cloneNode()
                            textLabel.setAttribute('y', (parseFloat(text.getAttribute('y')) + parseFloat(text.getAttribute('font-size')) + 4).toString());
                            textLabel.textContent = Utilities.FormatCurrency(cVal, 1, 0, '.', ',') + "%";
                            textLabel.setAttribute('added', 'true');
                            text.parentNode.appendChild(textLabel);
                        }
                    }
                    else if (found && rFound == hoverrow) {    // Elemente wurden bereits hinzugefügt --> nur noch mouseover betrachten                        
                        Array.prototype.forEach.call(text.parentNode.getElementsByTagName('text'), (text) => {
                            text.setAttribute('fill', mouseover ? 'black' : 'white');
                        });
                    }

                }
            }

        });
    }

    createObserver = (container: HTMLElement, chartWrapper: GoogleChartWrapper, data: GoogleDataTable|null) => {
        var observer = new MutationObserver((record, observer) => {
            //observer.disconnect();
            this.modifyTreeChart(container, chartWrapper, data);
            /*
            if (container) {
                this.createObserver(container, chartWrapper, data);
            }
            */
        });
        observer.observe(container, {            
            childList: true,
            subtree: true
        });
    }

    createTreeMapChart = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[]) => {
        const treeMapData = this.createTreeMapData(g, zeilen, g.jahr);

        const chartEvents: ReactGoogleChartEvent[] = [
            {
                eventName: "ready",
                callback: (eventCallbackArgs: {
                    chartWrapper: GoogleChartWrapper;
                    controlWrapper?: GoogleChartControl;
                    props: ReactGoogleChartProps;
                    google: GoogleViz;
                    eventArgs: any;
                }) => {                    
                    var container = document.getElementById(eventCallbackArgs.chartWrapper.getContainerId());
                    
                    
                    if (container && eventCallbackArgs.chartWrapper.getChartType() == "TreeMap") {

                        setTimeout(() => { this.modifyTreeChart(container, eventCallbackArgs.chartWrapper, eventCallbackArgs.chartWrapper.getDataTable()) }, 1);
                        this.createObserver(container, eventCallbackArgs.chartWrapper, eventCallbackArgs.chartWrapper.getDataTable());
                    }
                    google.visualization.events.addListener(eventCallbackArgs.chartWrapper.getChart(), "onmouseover", (e: { row: number }) => {
                        this.modifyTreeChart(container, eventCallbackArgs.chartWrapper, eventCallbackArgs.chartWrapper.getDataTable(), e.row, true);
                    });
                    google.visualization.events.addListener(eventCallbackArgs.chartWrapper.getChart(), "onmouseout", (e: { row: number }) => {
                        this.modifyTreeChart(container, eventCallbackArgs.chartWrapper, eventCallbackArgs.chartWrapper.getDataTable(), e.row, false);
                    });
                }
            }            
        ];

        if (treeMapData) {
            var data = google.visualization.arrayToDataTable(treeMapData);
            
            var options = {
                
                fontColor: "white",
                generateTooltip: (row: number, size: number, value: number) => {
                    return '<div class="kslplus-treemap-tooltip">' +
                        data.getValue(row, 0) + '<br/>' +
                        Utilities.FormatCurrency(data.getValue(row, 2), 2, 3, ".", ",") + " EUR (" +
                        Utilities.FormatCurrency(data.getValue(row, 4), 1, 0, ".", ",") + "%)" +
                        '</div>';
                }
            };


            return (<Chart
                width="100%"
                height={'300px'}
                chartType="TreeMap"
                chartLanguage='de'
                loader={<div>Loading Chart</div>}
                data={treeMapData}
                options={options}

                formatters={[
                    {
                        type: 'NumberFormat',
                        column: 1,
                        options: {
                            fractionDigits: 0,
                            suffix: ' TEUR'
                        }
                    }]}
                rootProps={{ 'data-testid': '3', 'observe': "true"  }}
                chartEvents={chartEvents}
            />);
        }
        else {
            return this.createBarChartHor(g, zeilen);
        }

    }

    createBarAbweichungen = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[] ) => {
        
        const data = Utilities.createBarDataAEAJRdoppisch(zeilen, g, this.props.plantyp === 4);

        if ( data.length < 2 )
            return null;
        
        return (<Chart
                width="100%"
                height={'400px'}
                chartType="BarChart"
                chartLanguage='de'
                loader={<div>Loading Chart</div>}
                data={data}                                                    
                options={{
                                                          
                        bar: { groupWidth: "50%" },
                        legend: { position: "none"},
                        chartArea: {width: '75%', left: '25%'},
                        hAxis: { gridlines: { count: 10 }}
                                                        
                }}
                                                    
                rootProps={{ 'data-testid': '2' }}
            />);
        

    }

    createBarAbweichungenDetails = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[] ) => {        
        const data = Utilities.createAenderungsBarDataJRdoppisch(zeilen, g);

        if ( data.length < 2 )
            return null;

        return (<Chart
                width="100%"
                height={'400px'}
                chartType="BarChart"
                chartLanguage='de'
                loader={<div>Loading Chart</div>}
                data={data}                                                    
                options={{
                                                          
                        bar: { groupWidth: "50%" },
                        legend: { position: "none"},
                        chartArea: {width: '75%', left: '25%'},
                        hAxis: { gridlines: { count: 10 }}
                                                        
                }}
                                                    
                rootProps={{ 'data-testid': '2' }}
            />);
        

    }


    createChart = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[]) => {
        switch (g.typ) {
            case 1:                
                return this.createBarChart(g, zeilen);

            case 2:
                return this.createPieChart(g, zeilen);
            
            case 3:
                return this.createBarAbweichungen(g, zeilen);
            
            case 4:
                return this.createBarAbweichungenDetails(g, zeilen);
            
            case 5:
                return this.createTreeMapChart(g, zeilen);

            case 6:
                return this.createBarChartHor(g, zeilen);

        }
        return null;
    }

    createGraphHeadline = (g: KSLPlusAPI.HaushaltsGrafik, id: number) => {
        return (
            <Col key={id.toString()}>
                <div className="kslplus-headline-third">{g.bezeichnung ? g.bezeichnung.replace("<jahr>", (this.props.jahr + (g.jahr !== undefined ? g.jahr : 0)).toString()): ""}</div>
            </Col>
        );

    }

    createChartItem = (g: KSLPlusAPI.HaushaltsGrafik, id: number) => {
        var chart = this.createChart(g, this.props.hh.zeilen ? this.props.hh.zeilen : []);

        if (chart)
            return (
                <Col key={id.toString()}>
                    <div id={"ksl-grafik-container_" + id.toString() + "_" + g.typ?.toString()} className="ksl-grafik-container">
                        {chart}
                        {(g.typ == 2 || g.typ == 5 || g.typ == 6) &&
                            <Col>

                                <ImgButton tttext="Tortendiagramm" id={"grafik-torte-" + id.toString()} className="ksl-grafik-toggle-button" type="piechart" onClick={() => {
                                    this.switchChartItems(2);
                                }} />
                                <ImgButton tttext="Balkendiagramm" id={"grafik-balken-" + id.toString()} className="ksl-grafik-toggle-button" type="barcharthor" onClick={() => {
                                    this.switchChartItems(6);
                                }} />
                                <ImgButton tttext="Flächendiagramm" id={"grafik-flaeche-" + id.toString()} className="ksl-grafik-toggle-button" type="aereachart" onClick={() => {
                                    this.switchChartItems(5);
                                }} />

                            </Col>
                        }
                        

                    </div>
                    
                </Col>
            );
        else
            return null;

    }

    isCollapsExpanded = (id: string) => {
        //return this.state.expandedCollaps.find(s => s === id) !== undefined;
        var o = this.state.expandedCollaps.find(s => s.name === id);       
        if ( o && o.expanded )
            return true;
        
        return false;
    }
        
    toggleCollaps = (id: string ) => {
        var o = this.state.expandedCollaps.find(s => s.name === id);
        if ( !o )
        {
            o = { name: id, expanded: false };
            this.state.expandedCollaps.push(o);               
        }
        o.expanded = !o.expanded;                
        this.setState({expandedCollaps: this.state.expandedCollaps});         
    }
    /*
    createGZOptions = () => {

        var items: JSX.Element[] = [];
        this.state.gzebenen.forEach((e) => {
            items.push(<option value={e.ebeneId}>{e.bezeichnung}</option>);
        });

        return items;
    }
    */

    getEbenenFilterOptions = () => {
        var items: { value: string, label: string }[] = [];

        this.state.grundzahlen.map(gz => {
            if (items.findIndex(i => i.value === gz.gzEbeneBez) == -1)
                items.push({ value: gz.gzEbeneBez??"", label: gz.gzEbeneBez??"" });
        });
       
        return items;

    }

    createGZColumns = (sets: KSLPlusAPI.DatensatzModel[]) => {
        var columns = [];                              

        columns.push(
            {
                dataField: 'gzEbeneBez',
                text: 'Typ',
                filter: selectFilter({
                    options: () => { return this.getEbenenFilterOptions() },
                    getFilter: (filter: any) => { this.getFilters.push(filter) }
                    
                }),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '220px' };
                }
            }
        );

        columns.push(
            {
                dataField: 'bezeichnung',
                text: 'Bezeichnung',
                filter: textFilter({
                        getFilter: (filter: any) => { this.getFilters.push(filter) }
                }),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '220px' };
                }
            }
        );

        if (!this.props.hh.jahre || this.props.hh.jahre.length === 0 || sets.length === 0)
            return columns;

        var jMax = sets[0].datens ? (sets[0].datens[sets[0].datens.length - 1].x ?? 0) : 0;
        var jMin = sets[0].datens ? (sets[0].datens[0].x ?? 0) : 0;
        
        var idx = 0;
        for (var j = jMin; j <= jMax; j++) {
            const idx1 = idx;
            columns.push(
                {
                    index: idx,
                    dataField: 'datens[' + idx.toString() + '].y',
                    text: j.toString(),                    
                    headerStyle: (colum: any, colIndex: any) => {
                        return { width: '120px' };
                    },
                    formatter: (cellContent: string, row: KSLPlusAPI.DatensatzModel) => {

                        if (row.datens && idx1 < row.datens.length && row.datens[idx1].y !== undefined && row.datens[idx1].id)                            
                            return (
                                <div>{Utilities.FormatCurrency(row.datens[idx1].y, row.nachkommastellen??2, 0, '.', ',')}</div>
                            );
                    }

                }
            );
            idx++;
        }

        

        return columns;
    };

    setGrundzahlenVisState = () => {

        this.setState({ gzopen: !this.state.gzopen });       
        this.getFilters.forEach(fct => fct(""));
    };

    setKennzahlenVisState = () => {

        this.setState({ kennzahlopen: !this.state.kennzahlopen });        
    };

    createGZItem = () => {                
        if (this.state.grundzahlen.length === 0)
            return null;

        if (!this.props.hh.jahre || this.props.hh.jahre.length === 0)
            return null;

        var jMax = this.props.jahr - 6;
        var jMin = this.props.jahr + 6;
        var sets = this.state.grundzahlen;

        sets.forEach(s => {
            s.datens = s.datens?.sort((d1, d2) => (d1.x ?? 0) - (d2.x ?? 0)).filter(d => (d.x ?? 0) > this.props.jahr - 6 && (d.x ?? 0) < this.props.jahr + 6);

            s.datens?.forEach(d => {
                if ((d?.x ?? 0) > jMax)
                    jMax = d?.x ?? 0;

                if ((d?.x ?? 0) < jMin)
                    jMin = d?.x ?? 0;

            });
        });


        sets.forEach(s => {
            for (var j = jMax; j >= jMin; j--) {
                if (s.datens?.find(d => d.x === j) === undefined) {
                    var nd = new KSLPlusAPI.Daten();
                    nd.x = j;
                    nd.y = 0;
                    s.datens?.push(nd);
                }

            }
            s.datens = s.datens?.sort((d1, d2) => (d1.x ?? 0) - (d2.x ?? 0)).filter(d => (d?.x ?? 0) >= jMin && (d?.x ?? 0) <= jMax);
        });

        this.state.grundzahlen = sets.filter(s => (s.datens?.length ?? 0) > 0);

        if (this.state.grundzahlen.length === 0)
            return null;

        //return <BootstrapTable wrapperClasses="kslplus-gzebenen-data" keyField='id' data={this.state.grundzahlen} columns={this.createGZColumns(this.state.grundzahlen)} filter={filterFactory()} filterPosition="top" />;

        return (
            <Row className="kslplus-grafik-collaps-wrapper">
                <button
                    className={"kslplus-beschreibung-toggle" + (this.state.gzopen ? " active" : "")}
                    onClick={() => this.setGrundzahlenVisState()}>
                    <div className="kslplus-beschreibung-text">  
                        <div className="kslplus-info-badge-container">
                            <Badge className="kslplus-info-badge" id={"kslplus-info-text-gz"}>i</Badge>
                            <UncontrolledPopover
                                size="sm"
                                placement="right"
                                target={"kslplus-info-text-gz"}
                                delay={{ show: 0, hide: 0 }}
                                trigger="hover"

                                className="kslplus-menu-popover1">
                                <PopoverBody className="kslplus-menu-popover-body1">
                                    {"Um die Grundzahlen zu sehen, bitte anklicken"}
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                        Grundzahlen
                    </div>
                    <div className="rotate90">
                        <svg
                            className={"kslplus-beschreibung-icon" + (this.state.gzopen ? " expanded" : "")}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>

                </button>
                

                <Collapse
                    isOpen={this.state.gzopen}
                    className={"kslplus-collapse-gradient " + (this.state.gzopen ? " active" : "")}>
                                            
                    <BootstrapTable wrapperClasses="kslplus-gzebenen-data" keyField='id' data={this.state.grundzahlen} columns={this.createGZColumns(this.state.grundzahlen)} filter={filterFactory()} filterPosition="top" />                                            
                </Collapse>                
            </Row>

        );

    }

    createKennzahlItems1 = () => {        
        var items: JSX.Element[] = [];

        for (var i = 0; i < this.state.kennzahlen.length; i++) {
            const id = "Kennzahl" + (i + 1).toString();
            const kennzahl = this.state.kennzahlen[i];
            
            if (this.props.plantyp == 3 || this.props.plantyp == 4) {
                items.push(
                    <div key={"pke" + id}>
                        <Row>
                            <Col>
                                <div className="kslplus-headline-third">{kennzahl.bezeichnung}</div>
                            </Col>
                        </Row>
                        <KennzahlJR produkt={this.props.produkt} kennzahl={kennzahl} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} uhconfig={this.props.uhconfig} bilanz={false} />
                    </div>
                );
            }
            else {
                items.push(
                    <div key={"pke" + id}>
                        <Row>
                            <Col>
                                <div className="kslplus-headline-third">{kennzahl.bezeichnung}</div>
                            </Col>
                        </Row>
                        <Kennzahl produkt={this.props.produkt}  kennzahl={kennzahl} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} />
                    </div>
                );
            }
        }

        return items;
    }

    createKennzahlItems = () => {
        var items: JSX.Element[] = [];

        for (var i = 0; i < this.state.kennzahlen.length; i++) {
            const kennzahl = this.state.kennzahlen[i];
            const id = "Kennzahl_" + kennzahl.id + "_" + this.props.produkt;    

            if (this.props.plantyp == 3 || this.props.plantyp == 4) {
                items.push(<Row className="kslplus-grafik-collaps-wrapper" key={id}>
                    <button
                        className={"kslplus-beschreibung-toggle" + (this.isCollapsExpanded(id) ? " active" : "")}
                        onClick={() => this.toggleCollaps(id)}
                    >

                        <div className="kslplus-beschreibung-text">
                            <div className="kslplus-info-badge-container">
                                <Badge className="kslplus-info-badge" id={"kslplus-info-text-" + id}>i</Badge>
                                <UncontrolledPopover
                                    size="sm"
                                    placement="right"
                                    target={"kslplus-info-text-" + id}
                                    delay={{ show: 0, hide: 0 }}
                                    trigger="hover"

                                    className="kslplus-menu-popover1">
                                    <PopoverBody className="kslplus-menu-popover-body1">
                                        {"Um die Kennzahl zu sehen, bitte anklicken"}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <span >{"Kennzahl / " + (kennzahl.bezeichnung != null ? kennzahl.bezeichnung : "")}</span>
                        </div>
                        <div className="rotate90">
                            <svg
                                className={"kslplus-beschreibung-icon" + (this.isCollapsExpanded(id) ? " expanded" : "")}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>

                    </button>
                    <Collapse
                        isOpen={this.isCollapsExpanded(id)}
                        className={"kslplus-collapse-gradient " + (this.isCollapsExpanded(id) ? " active" : "")}
                    >
                        <KennzahlJR produkt={this.props.produkt} kennzahl={kennzahl} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} uhconfig={this.props.uhconfig} bilanz={false} />
                    </Collapse>
                </Row>
                );
            }
            else {
                items.push(<Row className="kslplus-grafik-collaps-wrapper" key={id}>
                    <button
                        className={"kslplus-beschreibung-toggle" + (this.isCollapsExpanded(id) ? " active" : "")}
                        onClick={() => this.toggleCollaps(id)}
                    >

                        <div className="kslplus-beschreibung-text">
                            <div className="kslplus-info-badge-container">
                                <Badge className="kslplus-info-badge" id={"kslplus-info-text-" + id}>i</Badge>
                                <UncontrolledPopover
                                    size="sm"
                                    placement="right"
                                    target={"kslplus-info-text-" + id}
                                    delay={{ show: 0, hide: 0 }}
                                    trigger="hover"

                                    className="kslplus-menu-popover1">
                                    <PopoverBody className="kslplus-menu-popover-body1">
                                        {"Um die Kennzahl zu sehen, bitte anklicken"}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <span >{"Kennzahl / " + (kennzahl.bezeichnung != null ? kennzahl.bezeichnung : "")}</span>
                        </div>
                        <div className="rotate90">
                            <svg
                                className={"kslplus-beschreibung-icon" + (this.isCollapsExpanded(id) ? " expanded" : "")}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>

                    </button>
                    <Collapse
                        isOpen={this.isCollapsExpanded(id)}
                        className={"kslplus-collapse-gradient " + (this.isCollapsExpanded(id) ? " active" : "")}
                    >
                        <Kennzahl produkt={this.props.produkt} kennzahl={kennzahl} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} />
                    </Collapse>
                </Row>
                );
            }
        }

        return items;
    }


    createKennzahlItem = () => {
        if (this.state.kennzahlen.length === 0)
            return null;

        //return <BootstrapTable wrapperClasses="kslplus-gzebenen-data" keyField='id' data={this.state.grundzahlen} columns={this.createGZColumns(this.state.grundzahlen)} filter={filterFactory()} filterPosition="top" />;
        var kennzahlitems = this.createKennzahlItems();
        

        return (
            <Row className="kslplus-grafik-collaps-wrapper">
                <button
                    className={"kslplus-beschreibung-toggle" + (this.state.kennzahlopen ? " active" : "")}
                    onClick={() => this.setKennzahlenVisState()}>
                    <div className="kslplus-beschreibung-text">
                        Kennzahlen
                    </div>
                    <div className="rotate90">
                        <svg
                            className={"kslplus-beschreibung-icon" + (this.state.kennzahlopen ? " expanded" : "")}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>

                </button>


                <Collapse
                    isOpen={this.state.kennzahlopen}
                    className={"kslplus-collapse-gradient " + (this.state.kennzahlopen ? " active" : "")}>


                    {kennzahlitems}

                </Collapse>
            </Row>

        );

    }

    createGraphItems = () => {
        var items: JSX.Element[] = [];

        var gz = false;
        var kz = false;

        var idx = 0;
        if (this.props.hh.zeilen && this.props.hh.grafiken) {

            this.props.hh.grafiken.forEach(g => {
                if (g.typ === -3) {
                    var kzItems = this.createKennzahlItems();
                    if (kzItems)
                        items = items.concat(kzItems);
                    kz = true;
                }
                else if (g.typ === -2) {
                    var gzItem = this.createGZItem()
                    if (gzItem)
                        items.push(gzItem);
                    gz = true;
                }
                else if (!g.unterGrafiken || !g.unterGrafiken.length) {
                    var item = this.createChartItem(g, idx++);
                    
                    if (item) {
                        items.push(<Row key={"GrafikHeadline" + idx++}>
                            {this.createGraphHeadline(g, idx++)}
                        </Row>
                        );

                        items.push(
                            <Row key={"Grafik" + idx++}>
                                {item}
                            </Row>
                        );
                    }
                }                
                else if ( g.typ === -1 )
                {
                    var uidx = 0;
                    var ugs: (JSX.Element|null)[] = [];                    
                    g.unterGrafiken.forEach(g1 => {
                        var item = this.createChartItem(g1, uidx++);
                        if (item) {
                            ugs.push(<Row key={"UnterHeadlines" + idx++}>
                                {this.createGraphHeadline(g1, uidx++)}
                            </Row>);
                            ugs.push(<Row key={"UnterGrafik" + idx++}>{item}</Row>);
                            
                        }
                    });

                    var id = "UnterGrafik" + idx;

                    // nur hinufügen, wenn mindestens eine chart dargestellt werden muss
                    if (ugs.length > 0) {
                        
                        items.push(<Row className="kslplus-grafik-collaps-wrapper" key={id}>  
                                        <button
                                            className={"kslplus-beschreibung-toggle" + (this.isCollapsExpanded(id)?" active":"")}
                                            onClick={() => this.toggleCollaps(id)}
                                        >
                            
                                            <div className="kslplus-beschreibung-text">                                            
                                            <div className="kslplus-info-badge-container">
                                                <Badge className="kslplus-info-badge" id={"kslplus-info-text-" + id }>i</Badge>
                                                <UncontrolledPopover
                                                    size="sm"
                                                    placement="right"
                                                    target={"kslplus-info-text-" + id }
                                                    delay={ {show: 0, hide: 0} }
                                                    trigger="hover"
                                                    
                                                    className="kslplus-menu-popover1">
                                                    <PopoverBody className="kslplus-menu-popover-body1">
                                                        {"Für detaillierte Grafiken bitte anklicken"}                                                        
                                                    </PopoverBody>
                                                </UncontrolledPopover> 
                                            </div>
                                            <span >{g.bezeichnung != null ? g.bezeichnung : ""}</span>
                                            </div>
                                            <div className="rotate90">
                                            <svg
                                                className={"kslplus-beschreibung-icon" + (this.isCollapsExpanded(id)?" expanded":"")}
                                                viewBox="6 0 12 24"
                                            >
                                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                                            </svg>
                                            </div>
                            
                                        </button>
                                        <Collapse
                                            isOpen={this.isCollapsExpanded(id)}
                                            className={"kslplus-grafik-collapse-gradient " + (this.isCollapsExpanded(id)?" active":"")}
                                        >
                                        {ugs}
                                        </Collapse>
                                    </Row>
                        );
                        

                        
                    }
                    
                    
                }
                else {
                    

                    var uidx = 0;
                    var ugs: (JSX.Element|null)[] = [];
                    var hls: (JSX.Element|null)[] = [];
                    g.unterGrafiken.forEach(g1 => {
                        var item = this.createChartItem(g1, uidx++);
                        if (item) {
                            ugs.push(item);
                            hls.push(this.createGraphHeadline(g1, uidx++));
                        }
                    });

                    // nur hinufügen, wenn mindestens eine chart dargestellt werden muss
                    if (ugs.length > 0) {
                        if (g.bezeichnung) {
                            items.push(<Row key={"GrafikHeadline" + idx++}>
                                {this.createGraphHeadline(g, idx++)}
                            </Row>
                            );
                        }

                        items.push(
                            <Row key={"UnterHeadlines" + idx++}>
                                {hls}
                            </Row>
                        );
                        items.push(
                            <Row key={"UnterGrafik" + idx++}>
                                {ugs}
                            </Row>
                        );
                    }

                    

                }

                
            });
        }
       
        if (!gz) {            
            var gzItem = this.createGZItem()
            if (gzItem) {
                items.push(gzItem);                
            }
        }

        if (!kz) {
            var kzItems = this.createKennzahlItems();
            if (kzItems)
                items = items.concat(kzItems);
        }
        return items;
    }

    switchChartItem = (g: KSLPlusAPI.HaushaltsGrafik, typ: number) => {
        if (g.typ == 2 || g.typ == 5 || g.typ == 6)
            g.typ = typ;
    }

    switchChartItems = (typ: number) => {
        if (this.props.hh.zeilen && this.props.hh.grafiken) {

            this.props.hh.grafiken.forEach(g => {
                if (!g.unterGrafiken || !g.unterGrafiken.length) {
                    this.switchChartItem(g, typ);
                }                
                else {
                    g.unterGrafiken.forEach(g1 => {
                        this.switchChartItem(g1, typ);
                    });                    
                }
            });
        }
        this.setState({ ready: true });
    }

    render() {               
        if (!this.state.ready )
            return null;


        if (!this.props.isVisible)
            return null;
                
        //const graphItems = this.createGraphItems();
        const expandRow1 = {
            renderer: (row: KSLPlusAPI.HaushaltsZeilenDetails) => (
                row.unterEbenen ?
                    <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createDetailColumns(this.props.hh.zeilen ? this.props.hh.zeilen : [])} expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen) ? expandRow1 : undefined} rowClasses={this.rowClasses1} />
                    :
                    null

            ),
            className: 'kslplus-tabellen-expanded',
            //showExpandColumn: true,
            //expandColumnPosition: 'left',
            expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
                return null;
            },
            expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
                if (expanded) {
                    return (
                        <div className="kslplus-expand-column">(-)</div>
                    );
                }
                return (
                    <div className="kslplus-expand-column">(+)</div>
                );
            },
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand1

        };
        const expandRow = {
            renderer: (row: KSLPlusAPI.HaushaltsZeile) => (

                row.details && row.details.unterEbenen && row.details.unterEbenen.length ?
                    <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.details.unterEbenen} columns={this.createDetailColumns(this.props.hh.zeilen ? this.props.hh.zeilen : [])} expandRow={row.details.unterEbenen && row.details.unterEbenen.find(e => e.unterEbenen) ? expandRow1 : undefined} rowClasses={this.rowClasses1} />
                    :
                    null

            ),
            className: 'kslplus-tabellen-expanded',
            //showExpandColumn: true,
            //expandColumnPosition: 'left',
            expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
                return null;
            },
            expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
                if (expanded) {
                    return (
                        <div className="kslplus-expand-column">(-)</div>
                    );
                }
                return (
                    <div className="kslplus-expand-column">(+)</div>
                );
            },
            nonExpandable: this.props.hh.zeilen ? this.props.hh.zeilen.filter(d => !d.details || !d.details.unterEbenen || !d.details.unterEbenen.length).map(d => d.id) : [],
            onExpand: this.handleOnExpand,
            expanded: this.state.expanded
        };
        

        return (            
            <div className="kslplus-vbhaushalts-wrapper">                                                       
                {(this.props.renderTyp == 0 || this.props.renderTyp == 1) ?
                        
                        this.state.alleJahre ?
                            <Row>
                                <div></div>
                                <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                    <BootstrapTable ref={(n: any) => this.node = n} hover={true} keyField='id' data={this.props.hh.zeilen} columns={this.createColumns(this.props.hh.zeilen ? this.props.hh.zeilen : [])} hiddenRows={this.state.hiddenRows} rowStyle={this.rowStyles} rowClasses={this.rowClasses} expandRow={expandRow} rowEvents={this.rowEvents} />
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                    <BootstrapTable ref={(n: any) => this.node = n} hover={true} keyField='id' data={this.props.hh.zeilen} columns={this.createColumns(this.props.hh.zeilen ? this.props.hh.zeilen : [])} hiddenRows={this.state.hiddenRows} rowStyle={this.rowStyles} rowClasses={this.rowClasses} expandRow={expandRow} rowEvents={this.rowEvents} />
                                </Col>
                            </Row>                            
                    
                    :
                    null
                }
                
                {(this.props.renderTyp == 0 || this.props.renderTyp == 2) && this.createGraphItems()}
            </div>
        );
    }
}