import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent, useRef } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledTooltip
} from "reactstrap";
import { HiTrash, HiOutlineDocumentText, HiOutlineDocument, HiMiniTableCells } from "react-icons/hi2";
import { LuCopyPlus, LuSettings, LuDownload, LuLayers, LuUpload, LuEye, LuBarChartHorizontal } from "react-icons/lu";
import { GrTableAdd } from "react-icons/gr";
import { MdOutlinePreview, MdTableChart, MdPieChart } from "react-icons/md";

import { IconContext } from "react-icons";
    
type State = {            
}

type Props = {
    size: string,
    tttext: string,  
    text: string,
    disabled: boolean,
    onClick: () => void,
    type: string,
    color: string,
    id: string,
    className: string;
}

export class ImgButton extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        
    }
    
    static defaultProps = {
        size: "sm",               
        tttext: "",
        text:"",
        color: "primary",
        id: "",
        className: "",
        disabled: false
    }

    state: State = {
        
         
    }

    

    render() {  
        var img = <HiTrash />;
        if ( this.props.type == "del" )
            img = <HiTrash />;
        else if (this.props.type == "copy")
            img = <LuCopyPlus />;
        else if (this.props.type == "text")
            img = <HiOutlineDocumentText />;
        else if (this.props.type == "settings")
            img = <LuSettings />;
        else if (this.props.type == "download")
            img = <LuDownload />;
        else if (this.props.type == "upload")
            img = <LuUpload />;
        else if (this.props.type == "data")
            img = <HiMiniTableCells />;
        else if (this.props.type == "assign")
            img = <GrTableAdd />;
        else if (this.props.type == "layer")
            img = <LuLayers />;
        else if (this.props.type == "preview")
            img = <LuEye />;
        else if (this.props.type == "aereachart")
            img = <MdTableChart />;
        else if (this.props.type == "piechart")
            img = <MdPieChart />;
        else if (this.props.type == "barcharthor")
            img = <LuBarChartHorizontal />;
            /*
        if (this.props.tttext.length > 0)
            return (
            
                <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                        <Tooltip placement="top">
                            {props.tttext}
                        </Tooltip>
                    )}
                    placement="bottom"
                ><Button size={this.props.size} color={this.props.color} onClick={this.props.onClick}>{img}</Button>
                </OverlayTrigger>
                
            );
        else*/
        if (this.props.tttext.length > 0 && this.props.id.length > 0) {
            
            return (
                <>
                    <Button className={this.props.className}  id = { this.props.id } size={this.props.size} color={this.props.color} disabled={this.props.disabled} onClick={this.props.onClick}>{img}{this.props.text.length ? " " + this.props.text : ""}</Button>

                    <UncontrolledTooltip
                        placement='right'
                        target={this.props.id}>
                        {this.props.tttext}
                    </UncontrolledTooltip>

                    
                </>
            );
        }        
        else
            return <Button className={this.props.className}  size={this.props.size} color={this.props.color} disabled={this.props.disabled}  onClick={this.props.onClick}><IconContext.Provider value={{}}>{img}</IconContext.Provider>{this.props.text.length ? " " + this.props.text : ""}</Button>
    }
}
