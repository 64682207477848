import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import Chart from "react-google-charts";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import { VBTab } from './VBTabComp';
import { HHSummiertDoppisch } from './HHSummiertDoppischComp';
import { WeitereFinanzdatenDoppisch } from './WeitereFinanzdatenDoppischComp';

import { push } from "connected-react-router/seamless-immutable";

type State = {
    ready: boolean;    
    activeTab1: string,
    activeTab2: string,
    activeTabs: string[]
    activeMainTab: string,
    dropDownOpen: boolean,
    tab: string,
    activeDataTabId: string,
    isVisible: boolean,
    hiddenRows: (string|undefined)[];
}

type Props = {    
    kunde: number,
    gemeinde: number,
    jahr: number,
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    settings: KSLPlusAPI.SettingsModel,
    isVisible: boolean   
}



export class VorberichtDoppisch extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        
        this.dataSets = []; 
        this.vbtabs = [];       
        this.ergHH = null;
        this.finanzHH = null;
        this.aktiva = null;
        this.passiva = null;
        this.investA = null;
        this.investP = null;
    }

    node: any;
    ergHH: KSLPlusAPI.HaushaltSummiert | null;    
    finanzHH: KSLPlusAPI.HaushaltSummiert | null;    
    aktiva: KSLPlusAPI.HaushaltSummiert | null;    
    passiva: KSLPlusAPI.HaushaltSummiert | null;    
    investA: KSLPlusAPI.HaushaltSummiert | null;
    investP: KSLPlusAPI.HaushaltSummiert | null;    
    dataSets: KSLPlusAPI.Datensaetze[];    
    vbtabs: KSLPlusAPI.Vbtab[];

    state: State = {
        ready: true,        
        activeTab1: "1",
        activeTab2: "1",
        activeTabs: [],
        activeMainTab: "1",
        dropDownOpen: false,
        tab: "",
        activeDataTabId: "",
        isVisible: false,
        hiddenRows: []

    }    

    readErgHHData = (tries: number = 0) => {   
        if (this.props.plantyp == 4 && this.props.uhconfig && !this.props.uhconfig.ergKonten) {
            this.readFinanzHHData();
            return;
        }

        this.ergHH = null;
        this.setState({ ready: true });
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;        
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        
        client.getErgebnishaushaltSummiert("", request).then((hh) => {
            this.ergHH = hh;
            if (!this.ergHH.zeilen)
                return;
            
            //this.readHH2Data();
            this.readFinanzHHData();            
            this.setState({ ready: true, hiddenRows: this.ergHH.zeilen.filter(d => d.verbergen).map(d => d.zeile)  });
        }).catch(Reason => {
            if (tries < 3) {
                //setTimeout((tries) => { this.readErgHHData(++tries); }, 2000, ++tries);
                setTimeout(() => { this.readErgHHData(++tries); }, 2000);
                
                
            }
            else
                alert("Fehler beim Lesen des Ergebnishaushalts!");
        });

    }

    readFinanzHHData = (tries: number = 0) => {
        if (this.props.plantyp == 4 && this.props.uhconfig && !this.props.uhconfig.finKonten) {
            this.readInvestAData();
            return;
        }

        if (!this.props.settings.mitFinanzHH) {
            if (this.props.plantyp === 3)
                this.readAktivaData();
            else if (this.props.plantyp === 4)
                this.readInvestAData();
            else
                this.readDataSets();  

            return;
        }
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;        
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        
        client.getFinanzhaushaltSummiert("", request).then((hh) => {
            this.finanzHH = hh;
            if (!this.finanzHH.zeilen)
                return;
            
            //this.readHH2Data();
            if ( this.props.plantyp === 3 )
                this.readAktivaData();
            else if (this.props.plantyp === 4)
                this.readInvestAData();
            else
                this.readDataSets();                        
        }).catch(Reason => {            
            if (tries < 3)
                setTimeout(() => { this.readFinanzHHData(++tries) }, 2000);
            else
                alert("Fehler beim Lesen des Finanzhaushalts!");
        });

    }

    
    readAktivaData = (tries: number = 0) => {
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        
        client.getAktivaSummiert("", request).then((aktiva) => {
            this.aktiva = aktiva;
            if (!this.aktiva.zeilen)
                return;
            
            //this.readHH2Data();
            this.readPassivaData();                        
        }).catch(Reason => {            
            if (tries < 3)
                setTimeout(() => { this.readAktivaData(++tries) }, 2000);
            else
                alert("Fehler beim Lesen der Aktiva!");
        });

    }

    readPassivaData = (tries: number = 0) => {
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        
        client.getPassivaSummiert("", request).then((passiva) => {
            this.passiva = passiva;
            if (!this.passiva.zeilen)
                return;
            
            //this.readHH2Data();
            this.readDataSets();                        
        }).catch(Reason => {            
            if (tries < 3)
                setTimeout(() => { this.readPassivaData(++tries) }, 2000);
            else
                alert("Fehler beim Lesen der Passiva!");
        });

    }

    readInvestAData = (tries: number = 0) => {
        if (this.props.plantyp === 4 && this.props.uhconfig && !this.props.uhconfig.bilanzKonten) {
            this.readDataSets();
            return;
        }

        this.investA = null;
        this.setState({ ready: true });
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);


        client.getInvestASummiert("", request).then((hh) => {
            this.investA = hh;
            if (!this.investA.zeilen)
                return;

            //this.readHH2Data();
            this.readInvestPData();            
            this.setState({ ready: true });
        }).catch(Reason => {
            if (tries < 3)
                setTimeout(() => { this.readInvestAData(++tries) }, 2000);
            else
                alert("Fehler beim Lesen der aktiven Investitionen!");
        });

    }

    readInvestPData = (tries: number = 0) => {
        this.investP = null;
        this.setState({ ready: true });
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);


        client.getInvestPSummiert("", request).then((hh) => {
            this.investP = hh;
            if (!this.investP.zeilen)
                return;

            //this.readHH2Data();
            //this.readInvestPData();
            this.readDataSets();
            this.setState({ ready: true });
        }).catch(Reason => {
            if (tries < 3)
                setTimeout(() => { this.readInvestPData(++tries) }, 2000);
            else
                alert("Fehler beim Lesen der passiven Investitionen!");
        });

    }

    readDataSets = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.datensaetze(this.props.kunde, this.props.gemeinde, this.props.jahr, -1, this.props.plantyp === 3 ? 1 : 0, "").then((sets) => {
            this.dataSets = sets;          
            this.readMainTabs();  
            this.setState({ ready: true });            
        }).catch(Reason => {
        });
    }

    readMainTabs = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.tabs(this.props.kunde, this.props.gemeinde, this.props.jahr, 1, this.props.plantyp == 3 ? 1 : (this.props.plantyp == 4 ? 2 : 0), "").then((tabs) => {
            this.vbtabs = tabs;         
            if ( tabs.length > 0 && tabs[0].bezeichnung)
            {
                this.state.tab = tabs[0].bezeichnung;   
                this.state.activeDataTabId = "tab0";
            }
            this.setState({ ready: true });
        }).catch(Reason => {
        });
    }

    readData = () => {
        this.readErgHHData();        
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible)        
            this.setState({ activeMainTab: "1" });  

        if (prevProps.uhconfig !== this.props.uhconfig) {
            this.readData();
        }
    }

    componentDidMount() {
        if ( this.props.plantyp !== 4 || this.props.uhconfig != undefined )
            this.readData();
    }

    createChangeColumn = (field1: string, field2: string, id: number) => {
        return {
            dataField: "dummy" + id.toString(),
            text: "+/-",
            headerAlign: 'right',
            align: 'right',
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                var res = Utilities.calcArrowRotAndColor(row[field1], row[field2], row["ausgaben"] > 0, this.props.settings);


                var ttId = row.id ? "kslplus-label-" + id + "_" + row.id : "";
                ttId = ttId + ((row["ausgaben"] > 0) ? "-a" : "-e");

                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className="kslplus-change-popover-body">
                                    <div className="kslplus-change-popover-headline">{"Gegenüber Vorjahr: " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP-1)*100, 2, 0, '.', ',') + "  %)"}</div>                                                                                                                                                                                                            
                            </PopoverBody>
                        </UncontrolledPopover>  
                    </div>

                );

            },
            headerStyle: (colum: any, colIndex: any) => {
                return { width: "40px" };
            },
            style: (colum: any, colIndex: any) => {
                return { width: "40px" };
            }
        };
    }

    createDataColumn = (field: string, jahr: number, id: number) => {
        var text = "";
        if (  jahr - this.props.jahr < -1 )
            text = "Ergebnis ";
        else if ( jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0) )
            text = "Finanzplan ";
        else
            text = "Ansatz "; 

        return {
            dataField: field,
            text: text + jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',                       
            formatter: (cellContent: string, row: {[key: string]: number}) => {                
                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], 2, 3, '.', ',')} </div>
                );
                
            }
        };
    }

    hideRows = (zeilen: string[]|undefined) => {
        if (this.ergHH && this.ergHH.zeilen) {
            const rows = this.ergHH.zeilen.filter(d => (!zeilen && !d.summenZeile) || (zeilen && zeilen.findIndex(z => d.zeile === z) >= 0) );
            rows.forEach(r => r.verbergen = true);
            this.setState({ hiddenRows: this.ergHH.zeilen.filter(d => d.verbergen).map(d => d.zeile) });            
        }
    }

    showRows = (zeilen: string[]|undefined) => {
        if (this.ergHH && this.ergHH.zeilen ) {
            const rows = this.ergHH.zeilen.filter(d => (!zeilen && !d.summenZeile) || (zeilen && zeilen.findIndex(z => d.zeile === z) >= 0) );
            rows.forEach(r => r.verbergen = false);
            this.setState({ hiddenRows: this.ergHH.zeilen.filter(d => d.verbergen).map(d => d.zeile) });            
        }
    }

    createColumns = (data: KSLPlusAPI.HaushaltsZeile[]) =>  {
        var columns = [];

        columns.push({
            dataField: 'df1',
            text: "(+)",
            headerFormatter: (colum: any, colIndex: any) => {                
                if (this.ergHH && this.ergHH.zeilen && this.state.hiddenRows.length === this.ergHH.zeilen.filter(d => !d.summenZeile).length) {
                    return (
                        <div className="kslplus-tabellen-data" onClick={() => { this.showRows(undefined) }}>(+)</div>
                    );
                }
                else {
                    return (
                        <div className="kslplus-tabellen-data" onClick={() => { this.hideRows(undefined) }}>(-)</div>
                    );
                }
            },
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'left' };
            },
            style: (colum: any, colIndex: any) => {
                return { width: '50px' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsZeile) => {
                if (this.ergHH && this.ergHH.zeilen && row.unterzeilen && row.unterzeilen.length > 0) {
                    if (this.ergHH.zeilen.findIndex(d => row.unterzeilen && row.unterzeilen.findIndex(z => z === d.zeile) >= 0 && d.verbergen) >= 0) {
                        return (
                            <div className="kslplus-tabellen-data" onClick={() => { this.showRows(row.unterzeilen) }}>(+)</div>
                        );
                    }
                    else
                        return (
                            <div className="kslplus-tabellen-data" onClick={() => { this.hideRows(row.unterzeilen) }}>(-)</div>
                        );
                }
                else
                    return null;
            }

        });

        columns.push({
            dataField: 'zeile',
            text: "Zeile",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'left' };
            },

            style: (colum: any, colIndex: any) => {
                return { width: '50px' };
            }
            
        });

        columns.push({
            dataField: 'bezeichnung',
            text: "Ertrags- und Aufwandsarten (Konten/Zeilen)",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '400px', textAlign: 'left' };
            },
            
            style: (colum: any, colIndex: any) => {
                return { width: '400px' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsZeile) => {
                if (row.opZeichen && row.opZeichen.length > 0) {
                    return (
                        <div className="kslplus-tabellen-data">{row.opZeichen + " " + row.bezeichnung}</div>
                    );
                }
                else
                    return (
                        <div className="kslplus-tabellen-data">{row.bezeichnung}</div>
                    );

            }
        });

        

        if ( data == null )
            return columns;

        var id = 2;

        


        
        var fieldPrev: string | undefined;        

        if ( data.findIndex(d => d.ergebnisVvvJ !== 0) >= 0 )
        {
            columns.push(this.createDataColumn("ergebnisVvvJ", this.props.jahr - 3, id));
            id++;
            if (fieldPrev && id > 3) {
                columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvvJ", id));
                id++;
            }
            fieldPrev = "ergebnisVvvJ";            
        }
        else {
            fieldPrev = undefined;
        }

        if (data.findIndex(d => d.ergebnisVvJ !== 0) >= 0) {
            columns.push(this.createDataColumn("ergebnisVvJ", this.props.jahr - 2, id));
            id++;
            if (fieldPrev && id > 3) {
                columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ", id));
                id++;
            }
            fieldPrev = "ergebnisVvJ";
        }
        else {
            fieldPrev = undefined;
        }


        if (data.findIndex(d => d.ansatzVJ !== 0) >= 0) {
            columns.push(this.createDataColumn("ansatzVJ", this.props.jahr - 1, id));
            id++;
            if (fieldPrev && id > 3) {
                columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ", id));
                id++;
            }
            fieldPrev = "ansatzVJ";
        }
        else {
            fieldPrev = undefined;
        }


        if (data.findIndex(d => d.ergebnisVvJ !== 0) >= 0) {
            columns.push(this.createDataColumn("ansatzPj", this.props.jahr, id));
            id++;
            if (fieldPrev && id > 3) {
                columns.push(this.createChangeColumn(fieldPrev, "ansatzPj", id));
                id++;
            }
            fieldPrev = "ansatzPj";
        }
        else {
            fieldPrev = undefined;
        }

        if (data.findIndex(d => d.fp1 !== 0) >= 0) {
            columns.push(this.createDataColumn("fp1", this.props.jahr + 1, id));
            id++;
            if (fieldPrev && id > 3) {
                columns.push(this.createChangeColumn(fieldPrev, "fp1", id));
                id++;
            }
            fieldPrev = "fp1";
        }
        else {
            fieldPrev = undefined;
        }


        if (data.findIndex(d => d.fp2 !== 0) >= 0) {
            columns.push(this.createDataColumn("fp2", this.props.jahr + 2, id));
            id++;
            if (fieldPrev && id > 3) {
                columns.push(this.createChangeColumn(fieldPrev, "fp2", id));
                id++;
            }
            fieldPrev = "fp2";
        }
        else {
            fieldPrev = undefined;
        }


        if (data.findIndex(d => d.fp3 !== 0) >= 0) {
            columns.push(this.createDataColumn("fp3", this.props.jahr + 2, id));
            id++;
            if (fieldPrev && id > 3) {
                columns.push(this.createChangeColumn(fieldPrev, "fp3", id));
                id++;
            }
            fieldPrev = "fp3";
        }
        else {
            fieldPrev = undefined;
        }
            

        

        
        return columns;
    }
        
    setActiveTab = (tabIndex: number, tabValue: string) => {
        this.state.activeTabs[tabIndex] = tabValue;
        this.setState({ ready: true });
    }


    
    
    getActiveTab = (tabIndex: number) => {
        return this.state.activeTabs[tabIndex];
    }

    createTabItems = (tabs: KSLPlusAPI.Vbtab[]) => {
        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];   

        for (var i = 0; i < tabs.length; i++) {
            const tabId = "tab" + i.toString();
            const tab = tabs[i];
            mainNavItems.push(
                <NavItem key={"kenav" + tabId}>
                    <NavLink active={this.state.activeMainTab === tabId} onClick={() => this.setState({ activeMainTab: tabId })} >
                        <div className="kslplus-reiter-text-hauptzeile">{tab.bezeichnung}</div>
                    </NavLink>
                </NavItem>
            );
            mainContentItems.push(
                <TabPane tabId={tabId} key={"ketab" + tabId}>  
                    <VBTab modul={this.props.plantyp == 3 ? 1 : 0} tab={tabs[i]} datasets={this.dataSets} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} settings={this.props.settings}  className="kslplus-haushaltstab-wrapper-bottom" isVisible={this.state.activeMainTab === tabId}/>                  
                </TabPane>
            );
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };        
    }

    createTabItemsDropDown = (tabs: KSLPlusAPI.Vbtab[]) => {
        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];   

        for (var i = 0; i < tabs.length; i++) {
            const tabId = "tab" + i.toString();
            const tab = tabs[i];
            mainNavItems.push(
                <DropdownItem active={this.state.activeMainTab === tabId} key={"datanav" + tabId} onClick={() => this.setState({ activeMainTab: tabId, dropDownOpen: false, tab: tab.bezeichnung?tab.bezeichnung:"", activeDataTabId: tabId })}>                         
                            {tab.bezeichnung}                        
                </DropdownItem>

            );
            mainContentItems.push(
                <TabPane tabId={tabId} key={"ketab" + tabId}>  
                    <VBTab modul={this.props.plantyp == 3 ? 1 : 0} tab={tabs[i]} datasets={this.dataSets} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} settings={this.props.settings}  className="kslplus-haushaltstab-wrapper-bottom" isVisible={this.state.activeMainTab === tabId}/>                  
                </TabPane>
            );
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };        
    }

    toggleDataDropDown = () => {        
        if ( !this.state.dropDownOpen && this.state.activeMainTab !== this.state.activeDataTabId  ) 
            this.setState({activeMainTab: this.state.activeDataTabId});
        else 
            this.setState({dropDownOpen: !this.state.dropDownOpen}); 
    }
            
    rowStyles = (row: KSLPlusAPI.HaushaltsZeile, rowIndex:number) => {
        const style: any = {};
      if (row.summenZeile) {
          style.fontWeight = 'bold';
      }
      return style;
    }

    createBarChartDataRow = (r: KSLPlusAPI.Reihe, z: KSLPlusAPI.HaushaltsZeile, field: string, jahr: number) => {
        var data: any[] = [];

        const row = z as unknown as { [key: string]: number };        

        data.push(row[field]);
        var style = "color: " + r.rahmenFarbe;
        if (r.farbeNeg && r.farbePos) {
            if ( row[field] < 0 )
                style += ";fill-color: " + r.farbeNeg;
            else if ( row[field] > 0) 
                style += ";fill-color: " + r.farbePos;

            //style += ";stroke-width: 3; fill-opacity: 0.5";
            style += ";stroke-width: 3";
        }
        data.push(style);

        
        return data;
        
    }

    createPieChartData = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[], jahr: number|undefined) => {        

        var data = [];
        data.push(['Gruppe', 'Wert']);
        
        if ( g.reihen )
        {
            g.reihen.forEach(r => {
                const z = zeilen.find(z => z.zeile == r.zeile);
                if (z) {
                    var row : any[] = [z.bezeichnung];
                
                    switch (jahr) {
                        case -3:
                            row.push(z.ergebnisVvvJ);
                            break;

                        case -2:
                            row.push(z.ergebnisVvJ);
                            break;

                        case undefined:
                        case -1:
                            row.push(z.ansatzVJ);                        
                            break;

                        case 0:
                            row.push(z.ansatzPj);                                                
                            break;

                        case 1:
                            row.push(z.fp1);                                                                        
                            break;

                        case 2:
                            row.push(z.fp2);                                                
                            break;

                        case 3:
                            row.push(z.fp3);                                                
                            break;

                    }
                    data.push(row);
                }
            });
        }

        

        

        return data;      
    }
    
    createBarChartData = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[]) => {        

        var data: any[] = [];
        var colors : string[] = [];

        if (g.reihen) {
            var header:any[] = ["Jahr"];
            
            g.reihen.forEach(r => {
                const z = zeilen.find(z => z.zeile == r.zeile);
                if (z) {
                    header.push(z.bezeichnung);
                    header.push({ role: "style" });
                    colors.push(r.rahmenFarbe ? r.rahmenFarbe : "");
                }
            });

            data.push(header);

            
            if (zeilen.findIndex(z => z.ergebnisVvvJ !== 0) >= 0) {
                var reihe: any[] = [(this.props.jahr - 3).toString()];

                g.reihen.forEach(r => {
                    const z = zeilen.find(z => z.zeile == r.zeile);
                    if (z) {
                        reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisVvvJ", this.props.jahr - 3));
                    }
                    
                });

                data.push(reihe)
            }

            if (zeilen.findIndex(z => z.ergebnisVvJ !== 0) >= 0) {
                var reihe: any[] = [(this.props.jahr - 2).toString()];

                g.reihen.forEach(r => {
                    const z = zeilen.find(z => z.zeile == r.zeile);
                    if (z) {
                        reihe = reihe.concat(this.createBarChartDataRow(r, z, "ergebnisVvJ", this.props.jahr - 2));                        
                    }

                });                
                data.push(reihe)
            }

            if (zeilen.findIndex(z => z.ansatzVJ !== 0) >= 0) {
                var reihe: any[] = [(this.props.jahr - 1).toString()];

                g.reihen.forEach(r => {
                    const z = zeilen.find(z => z.zeile == r.zeile);
                    if (z) {
                        reihe = reihe.concat(this.createBarChartDataRow(r, z, "ansatzVJ", this.props.jahr - 1));
                    }

                });

                data.push(reihe)
            }

            if (zeilen.findIndex(z => z.ansatzPj !== 0) >= 0) {
                var reihe: any[] = [this.props.jahr.toString()];

                g.reihen.forEach(r => {
                    const z = zeilen.find(z => z.zeile == r.zeile);
                    if (z) {
                        reihe = reihe.concat(this.createBarChartDataRow(r, z, "ansatzPj", this.props.jahr));
                    }

                });

                data.push(reihe)
            }

            if (zeilen.findIndex(z => z.fp1 !== 0) >= 0) {
                var reihe: any[] = [(this.props.jahr + 1).toString()];

                g.reihen.forEach(r => {
                    const z = zeilen.find(z => z.zeile == r.zeile);
                    if (z) {
                        reihe = reihe.concat(this.createBarChartDataRow(r, z, "fp1", this.props.jahr + 1));
                    }

                });

                data.push(reihe)
            }

            if (zeilen.findIndex(z => z.fp2 !== 0) >= 0) {
                var reihe: any[] = [(this.props.jahr + 2).toString()];

                g.reihen.forEach(r => {
                    const z = zeilen.find(z => z.zeile == r.zeile);
                    if (z) {
                        reihe = reihe.concat(this.createBarChartDataRow(r, z, "fp2", this.props.jahr + 2));
                    }

                });

                data.push(reihe)
            }

            if (zeilen.findIndex(z => z.fp3 !== 0) >= 0) {
                var reihe: any[] = [(this.props.jahr + 3).toString()];

                g.reihen.forEach(r => {
                    const z = zeilen.find(z => z.zeile == r.zeile);
                    if (z) {
                        reihe = reihe.concat(this.createBarChartDataRow(r, z, "fp3", this.props.jahr + 3));
                    }

                });

                data.push(reihe);
            }

            
            
        }

        return { data: data, colors: colors };        
    }

    createBarChart = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[] ) => {
        const data = this.createBarChartData(g, zeilen);        
        return (<Chart
            width="100%"
            height={'300px'}
            chartType="ComboChart"
            chartLanguage='de'
            loader={<div>Loading Chart</div>}
            data={data.data}
            options={{
                colors: data.colors,
                vAxes: { 0: { title: 'EUR', minValue: 0  }, 1: { title: '%', minValue: 0 } },
                hAxis: { title: 'Jahr' },
                seriesType: 'bars',
                series: { 0: { targetAxisIndex: 0 }, 1: { targetAxisIndex: 0 }, 2: { type: 'line', targetAxisIndex: 1 }, 3: { type: 'line', targetAxisIndex: 1 } },                

            }}
            formatters={Utilities.createFormatters(g.reihen ? g.reihen.length + 1 : 2, 2)}
            rootProps={{ 'data-testid': '1' }}
            chartEvents={[
                {
                    eventName: "ready",
                    callback: ({ chartWrapper, google }) => {

                        const chart = chartWrapper.getChart();


                    }
                }
            ]}
        />);

    }

    createPieChart = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[] ) => {
        const data = this.createPieChartData(g, zeilen, g.jahr);        
        return (<Chart
                width="100%"
                height={'300px'}
                chartType="PieChart"
                chartLanguage='de'                                                            
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    backgroundColor: 'none',                                                
                    is3D: true
                }}
                formatters={[
                            {
                                type: 'NumberFormat',
                                column: 1,
                                options: {
                                suffix: ' EUR'
                                },
                            }]}
                rootProps={{ 'data-testid': '2' }}
       />);

    }

    createChart = (g: KSLPlusAPI.HaushaltsGrafik, zeilen: KSLPlusAPI.HaushaltsZeile[]) => {
        switch (g.typ) {
            case 1:                
                return this.createBarChart(g, zeilen);

            case 2:
                return this.createPieChart(g, zeilen);

        }
        return null;
    }

    createGraphHeadline = (g: KSLPlusAPI.HaushaltsGrafik, id: number) => {
        return (
            <Col key={id.toString()}>
                <div className="kslplus-headline-third">{g.bezeichnung}</div>
            </Col>
        );

    }

    createChartItem = (g: KSLPlusAPI.HaushaltsGrafik, id: number) => {
        return (
            <Col key={id.toString()}>
                <div className="ksl-grafik-container">
                    {this.createChart(g, this.ergHH && this.ergHH.zeilen ? this.ergHH.zeilen : [])}
                </div>
            </Col>
        );

    }

    createGraphItems = () => {
        var items: JSX.Element[] = [];

        var idx = 0;
        if (this.ergHH && this.ergHH.zeilen && this.ergHH.grafiken) {

            this.ergHH.grafiken.forEach(g => {
                if (!g.unterGrafiken || !g.unterGrafiken.length) {
                    items.push(<Row key={"GrafikHeadline" + idx++}>
                        {this.createGraphHeadline(g, idx++)}
                    </Row>
                    );

                    items.push(
                        <Row key={"Grafik" + idx++}>
                            {this.createChartItem(g, idx++)}
                        </Row>
                    );
                }
                else {
                    if (g.bezeichnung) {
                        items.push(<Row key={"GrafikHeadline" + idx++}>
                            {this.createGraphHeadline(g, idx++)}
                        </Row>
                        );
                    }

                    var uidx = 0;
                    var ugs: JSX.Element[] = [];
                    var hls: JSX.Element[] = [];
                    g.unterGrafiken.forEach(g1 => {
                        ugs.push(this.createChartItem(g1, uidx++));
                        hls.push(this.createGraphHeadline(g1, uidx++));
                    });


                    items.push(
                        <Row key={"UnterHeadlines" + idx++}>
                            {hls}
                        </Row>
                    );
                    items.push(
                        <Row key={"UnterGrafik" + idx++}>
                            {ugs}
                        </Row>
                    );


                    

                }

                
            });
        }

        return items;
    }

    render() {               
        if (!this.state.ready )
            return null;
        
        const tabItems = this.createTabItems(this.vbtabs);

        const graphItems = this.createGraphItems();

        return (
            <div className="kslplus-haushaltstab-wrapper-middle">  
                <Nav tabs>                                    
                    {(this.props.plantyp !== 4 || (this.props.uhconfig && this.props.uhconfig.ergKonten)) &&                        
                        <NavItem id="kslplus-tab-ergebnishaushalt-summiert">
                            <NavLink active={this.state.activeMainTab === "1"} onClick={() => this.setState({ activeMainTab: "1" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{this.props.plantyp === 3 || this.props.plantyp === 4 ? "Ergebnisrechnung summiert" : "Ergebnishaushalt summiert"}</div>
                            </NavLink>
                        </NavItem>                            
                    }
                    {(this.props.plantyp !== 4 || (this.props.uhconfig && this.props.uhconfig.ergKonten)) &&
                        Utilities.createTTItem(this.props.settings, "ergebnishaushalt-summiert")                       
                    }
                    {(this.props.plantyp === 3 || (this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.ergKonten)) &&                        
                        <NavItem id="kslplus-tab-weitere-finanzdaten">
                            <NavLink active={this.state.activeMainTab === "3"} onClick={() => this.setState({ activeMainTab: "3" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{this.props.plantyp === 3 || this.props.plantyp === 4 ? "Ergebnisrechnung weitere Finanzdaten" : "Ergebnishaushalt weitere Finanzdaten"}</div>

                            </NavLink>
                        </NavItem>                            
                    }
                    {(this.props.plantyp === 3 || (this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.ergKonten)) &&
                        Utilities.createTTItem(this.props.settings, "weitere-finanzdaten")
                    }
                    {this.props.plantyp === 3 &&                        
                        <NavItem id="kslplus-tab-bilanz-summiert">
                            <NavLink active={this.state.activeMainTab === "4"} onClick={() => this.setState({ activeMainTab: "4" })} >
                                <div className="kslplus-reiter-text-hauptzeile">Bilanz summiert</div>

                            </NavLink>
                        </NavItem>                            
                    }
                    {this.props.plantyp === 3 &&
                        Utilities.createTTItem(this.props.settings, "bilanz-summiert")
                    }
                    {(this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.bilanzKonten) &&                        
                        <NavItem id="kslplus-tab-investitionen-summiert-bilanzkonten">
                            <NavLink active={this.state.activeMainTab === "4_1"} onClick={() => this.setState({ activeMainTab: "4_1" })} >
                                <div className="kslplus-reiter-text-hauptzeile">Investitionen summiert</div>

                            </NavLink>
                        </NavItem>                            
                    }
                    {(this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.bilanzKonten) &&
                        Utilities.createTTItem(this.props.settings, "investitionen-summiert-bilanzkonten")
                    }
                    {this.props.plantyp === 3 &&                        
                        <NavItem id="kslplus-tab-weitere-bilanzdaten">
                            <NavLink active={this.state.activeMainTab === "5"} onClick={() => this.setState({ activeMainTab: "5" })} >
                                <div className="kslplus-reiter-text-hauptzeile">Bilanz weitere Finanzdaten</div>

                            </NavLink>
                        </NavItem>                            
                    }
                    {this.props.plantyp === 3 &&
                        Utilities.createTTItem(this.props.settings, "weitere-bilanzdaten")
                    }
                    {this.props.plantyp !== 4 && this.props.settings.mitFinanzHH &&
                        <NavItem id="kslplus-tab-finanzhaushalt-summiert">
                            <NavLink active={this.state.activeMainTab === "2"} onClick={() => this.setState({ activeMainTab: "2" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{this.props.plantyp === 3 || this.props.plantyp === 4 ? "Finanzrechnung summiert" : "Finanzhaushalt summiert"}</div>

                            </NavLink>
                        </NavItem>                            
                    }
                    {this.props.plantyp !== 4 &&
                        Utilities.createTTItem(this.props.settings, "finanzhaushalt-summiert")
                    }
                    {(this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.finKonten) &&
                        
                        <NavItem id="kslplus-tab-investitionen-summiert-finanzkonten">
                            <NavLink active={this.state.activeMainTab === "2"} onClick={() => this.setState({ activeMainTab: "2" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{ "Finanzrechnung summiert"}</div>

                            </NavLink>
                        </NavItem>                            
                    }
                    {(this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.finKonten) &&
                        Utilities.createTTItem(this.props.settings, "investitionen-summiert-finanzkonten")
                    }
                   {tabItems.navItems}                    
                </Nav>                
                <TabContent activeTab={this.state.activeMainTab}>                    
                        
                        <TabPane tabId="1">     
                            {this.ergHH != null   ?    
                            <HHSummiertDoppisch hh={this.ergHH} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "1"} settings={this.props.settings} summenTyp={1} uhconfig={this.props.uhconfig} callerID={"1"} />                            
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }
                        </TabPane>
                        {(this.props.plantyp === 3 || (this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.ergKonten)) &&
                            <TabPane tabId="3">
                            <WeitereFinanzdatenDoppisch kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} isVisible={this.state.activeMainTab === "3"} bilanz={false} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                            </TabPane>
                        }
                        <TabPane tabId="4">  
                            {this.aktiva != null   ?    
                            <HHSummiertDoppisch hh={this.aktiva} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4"} settings={this.props.settings} summenTyp={11} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"11_1"} />                            
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }                          
                            {this.passiva != null   ?    
                            <HHSummiertDoppisch hh={this.passiva} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4"} settings={this.props.settings} summenTyp={12} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"12_1"} />                            
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }      
                            {this.aktiva != null   ?    
                            <HHSummiertDoppisch hh={this.aktiva} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4"} settings={this.props.settings} summenTyp={11} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"11_2"} />                            
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }                          
                            {this.passiva != null   ?    
                            <HHSummiertDoppisch hh={this.passiva} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4"} settings={this.props.settings} summenTyp={12} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"12_2"} />                            
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }                     
                        </TabPane>
                        <TabPane tabId="4_1">
                            {this.investA != null ?
                            <HHSummiertDoppisch hh={this.investA} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4_1"} settings={this.props.settings} summenTyp={21} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"21_1"} />
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }
                            {this.investP != null ?
                            <HHSummiertDoppisch hh={this.investP} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4_1"} settings={this.props.settings} summenTyp={22} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"22_1"} />
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }
                            {this.investA != null ?
                            <HHSummiertDoppisch hh={this.investA} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4_1"} settings={this.props.settings} summenTyp={21} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"21_2"} />
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }
                            {this.investP != null ?
                            <HHSummiertDoppisch hh={this.investP} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4_1"} settings={this.props.settings} summenTyp={22} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"22_2"} />
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }
                            
                        </TabPane>
                        <TabPane tabId="5">
                        <WeitereFinanzdatenDoppisch kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} isVisible={this.state.activeMainTab === "5"} bilanz={true} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                        </TabPane>
                        
                        <TabPane tabId="2">
                            {this.finanzHH != null   ?    
                            <HHSummiertDoppisch hh={this.finanzHH} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "2"} settings={this.props.settings} summenTyp={2} uhconfig={this.props.uhconfig} callerID={"2"} />                            
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                            }
                        </TabPane>                                                                                                                    
                        {tabItems.contentItems}
                    </TabContent>
                               
            </div>
        );
    }
}