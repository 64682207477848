import React, { Component, ChangeEvent } from 'react';
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Col, Row, Nav, TabContent, TabPane, Badge, UncontrolledPopover, PopoverBody, Spinner, UncontrolledTooltip } from 'reactstrap';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


import Chart from "react-google-charts";

import Collapse from "@kunukn/react-collapse";

import { env } from './env';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';



//import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';


import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import  filterFactory, { selectFilter, multiSelectFilter, textFilter  } from 'react-bootstrap-table2-filter';

import * as KSLPlusAPI from "./services/KSLPlusAPI"
import { Login } from './components/LoginComp';
import { ForgotPW } from './components/ForgotPWComp';


import { MessageBox } from './components/MessageBoxComp';
import { Dokumente } from './components/DokumentenComp';
import { Vorbericht } from './components/VorberichtComp';
import { Finanzdaten } from './components/FinanzdatenComp';

import { VorberichtDoppisch } from './components/VorberichtDoppischComp';
import { FinanzdatenDoppisch } from './components/FinanzdatenDoppischComp';

import * as Utilities from "./components/Utilities"

import { isUndefined } from "util";
import { MdExpand, MdExpandLess, MdExpandMore } from "react-icons/md";

import * as QueryString from "query-string";

import './custom.css'
import { ENGINE_METHOD_PKEY_ASN1_METHS } from 'constants';
import { request } from 'http';
import { isJSDocVariadicType } from 'typescript/lib/tsserverlibrary';
import { useRef } from 'react';





type State = {
    activeTab: string,
    ready: boolean,
    selectedGLEbene: string;
    hauptTextActive: boolean;
    user: KSLPlusAPI.LoginResponse | undefined | null;
    token: string;
    logFailed: boolean;
    forgotPW: boolean;    
    userName: string;
    customerName: string | null;
    uhconfig: KSLPlusAPI.UhConfigModel | undefined;
    invalidURL: boolean;
    unauthorized: boolean;
    needsLogin: boolean;
    missingTabCount: number;
    fullHeader: boolean;
}


class App extends Component<RouteComponentProps> {
    static displayName = App.name;
        
        

    wappen: string;
    kunde: number;
    gemeinde: number;
    jahr: number;
    plantyp: number;
    planstufe: number;
    verwaltungsansicht: boolean;
    psObject: KSLPlusAPI.Planstufen | null;
    hauptText: KSLPlusAPI.Texte|null;
    settings: KSLPlusAPI.SettingsModel|undefined;
    css: string|undefined;    
    massnahmen: KSLPlusAPI.Massnahman[] = [];
    uhconfigs: KSLPlusAPI.UhConfigModel[] = [];
    
    constructor(props: any) {
        super(props);


        this.wappen = "";
        this.kunde = -1;
        this.gemeinde = -1;
        this.jahr = -1;
        this.plantyp = -1;
        this.planstufe = -1;
        this.verwaltungsansicht = false;
        this.psObject = null;
        this.hauptText = null;           

    }

    state: State = {
        activeTab: "1",
        ready: false,
        selectedGLEbene: "",
        hauptTextActive: false,
        user: null,  
        token: "",
        logFailed: false,
        forgotPW: false,        
        userName: "",
        customerName: "",
        uhconfig: undefined,
        invalidURL: false,
        unauthorized: false,
        needsLogin: false,
        missingTabCount: 0,
        fullHeader: true
    }

    componentDidMount() {
        this.parseParams();

        this.initApp();

        /*
        if (this.plantyp == 4) {
            this.refreshToken(true);
        }
        else
            this.readData();
        */
    }

    handleLogout = () => {
        localStorage.removeItem("KSLPlusAdmin_Token");
        localStorage.removeItem("KSLPlusAdmin_RefreshToken");
        this.setState({ unauthorized: false, user: undefined, logFailed: false, userName: "", token: "", ready: false });
    }

    handleLogin = (username: string, password: string) => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var request = new KSLPlusAPI.LoginModel();
        request.username = username;
        request.password = password;


        var client = new KSLPlusAPI.Authenticate_Client(api);
        client.login("", request).then(Response => {
            if (Response.roles && ((Response.roles.indexOf("Admin") == -1 && Response.roles.indexOf("CustomerAdmin") == -1 && Response.roles.indexOf("CustomerUser") == -1 && Response.roles.indexOf("User") == -1) || (Response.roles.indexOf("Admin") == -1 && Response.customer == null))) {
                this.setState({ failed: true });
                return;
            }
            if (!Response.needNewPassword) {
                if (Response.token)
                    localStorage.setItem("KSLPlusAdmin_Token", Response.token);
                if (Response.refreshToken)
                    localStorage.setItem("KSLPlusAdmin_RefreshToken", Response.refreshToken);
            }
            this.setState({ user: Response, logFailed: false, username: username, customername: Response.customerName, token: "Bearer " + Response.token });
            this.readData();

        }).catch(Reason => {
            //this.state.failed = true;
            this.setState({ logFailed: true });


        });
    };

    handleForgotPWClick = () => {
        this.setState({ forgotPW: true });
    };

    handleForgotPW = (username: string) => {
        this.setState({ forgotPW: false });

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";


        var client = new KSLPlusAPI.Authenticate_Client(api);
        client.sendPasswordResetToken(username, "").then(Response => {
        }).catch(Reason => {

        });

    };


    refreshToken = async (useStorage: boolean = false): Promise<KSLPlusAPI.LoginResponse | null> => {
                
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var request = new KSLPlusAPI.RefreshTokenModel();
        var username: null | string = null;
        if (!useStorage && this.state.user) {
            request.token = this.state.user.token;
            request.refreshToken = this.state.user.refreshToken;
        }
        else {            
            var remember = localStorage.getItem("KSLPlusAdmin_RememberMe");
            username = localStorage.getItem("KSLPlusAdmin_UserName");
            var token = localStorage.getItem("KSLPlusAdmin_Token");
            var refreshToken = localStorage.getItem("KSLPlusAdmin_RefreshToken");            
            if (remember !== "true" || username == null || token == null || refreshToken == null) {
                this.setState({ user: undefined, mounted: true });
                this.handleLogout();
                return Promise.resolve<KSLPlusAPI.LoginResponse | null>(null);
            }

            request.token = token != null ? token : "";
            request.refreshToken = refreshToken != null ? refreshToken : "";            
        }



        var client = new KSLPlusAPI.Authenticate_Client(api);
        try {
            
            var Response = await client.refreshToken("", request);            
            if (Response.token)
                localStorage.setItem("KSLPlusAdmin_Token", Response.token);
            if (Response.refreshToken)
                localStorage.setItem("KSLPlusAdmin_RefreshToken", Response.refreshToken);


            this.state.token = "Bearer " + Response.token;
            this.state.logFailed = false;            
            this.state.user = Response;
            //this.state.userName = username != null ? username : "";
            this.state.userName = Response.userName ? Response.userName : "";
            this.state.customerName = Response.customerName ? Response.customerName : null;

            
            this.readData();
            
            //this.state.user.token = Response.token;
            //this.state.user.refreshToken = Response.refreshToken;
            return Promise.resolve<KSLPlusAPI.LoginResponse | null>(Response);
        }
        catch (e) {            
            this.setState({ user: undefined, mounted: true });
            this.handleLogout();
        }
        
        return Promise.resolve<KSLPlusAPI.LoginResponse | null>(null);
    };
    

    readMassnahmen = () => {
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.kunde === undefined)
            return;
        request.kunde = this.kunde;
        var gemeinde = 0;
        if (this.gemeinde === undefined)
            return;
        request.gemeinde = this.gemeinde;
        var jahr = 0;
        if (this.jahr === undefined)
            return;
        request.jahr = this.jahr;    
    
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
                

        client.getMassnahmen("", request).then(mns => {
            this.massnahmen = mns;            
        }).catch(Reason => {
            this.massnahmen = [];
        });
    }

    parseParams = () => {
        const { location } = this.props;
        const params = QueryString.parse(location.search);

        if (params.css != null) {
            if (Array.isArray(params.css))
                this.css = params.css[0];
            else
                this.css = params.css;
        }

        if (params.kunde != null) {
            if (Array.isArray(params.kunde))
                this.kunde = +params.kunde[0];
            else
                this.kunde = +params.kunde;
        }
        if (params.gemeinde != null) {
            if (Array.isArray(params.gemeinde))
                this.gemeinde = +params.gemeinde[0];
            else
                this.gemeinde = +params.gemeinde;
        }
        if (params.jahr != null) {
            if (Array.isArray(params.jahr))
                this.jahr = +params.jahr[0];
            else
                this.jahr = +params.jahr;
        }
        if (params.plantyp != null) {
            if (Array.isArray(params.plantyp))
                this.plantyp = +params.plantyp[0];
            else
                this.plantyp = +params.plantyp;
        }
        if (params.planstufe != null) {
            if (Array.isArray(params.planstufe))
                this.planstufe = +params.planstufe[0];
            else
                this.planstufe = +params.planstufe;
        }
        if (params.verwaltung != null) {
            if (Array.isArray(params.verwaltung))
                this.verwaltungsansicht = (+params.verwaltung[0] == 1);
            else
                this.verwaltungsansicht = (+params.verwaltung == 1);
        }
    }

    initApp = () => {


        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
       
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
        
        client.needsLogin(this.kunde, this.gemeinde, this.jahr, this.plantyp, this.planstufe, this.state.token).then(b => {                
            if (b)
                this.refreshToken(true);
            else
                this.readData();            
            this.setState({ needsLogin: b });
        }).catch(Reason => {
            this.setState({ invalidURL: true });
        });
    }

    readData = () => {
        
        
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        
        
        
        // custom.css installieren, fall angegeben
        if ( this.css )
        {            
            var p;
            var tl = document.getElementsByTagName("link")[0]
            if (tl) 
                    p = tl.parentNode; 
            else 
                    p = document.getElementsByTagName('head')[0];
            var c = document.createElement("link");
            c.href = env.REACT_APP_PUBLIC_URL + "css/" + this.css;     
            c.href += "?" + Math.random();         
            c.rel = "stylesheet";
            
            if ( p )            
                p.appendChild(c);                                                   
        }

        


        

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        // css lesen
        client.texte(this.kunde, this.gemeinde, this.jahr, 3, "").then(t => { 
            if ( t != null && t.length > 0 && t[0].text && t[0].text.length > 0 )
            {
                var head = document.head || document.getElementsByTagName('head')[0];
                var style = document.createElement('style');

                head.appendChild(style);

                style.type = 'text/css';                
                style.appendChild(document.createTextNode(t[0].text));                
            }  
            
        }).catch(Reason => {
        });
        
        client.bilder(this.kunde, this.gemeinde, this.jahr, 1, "").then(bilder => {            
            if (bilder.length > 0 && bilder[0].url !== undefined)
                this.wappen = bilder[0].url;
            this.setState({ready:true})
        }).catch(Reason => {            
        });

        client.planstufe(this.kunde, this.gemeinde, this.jahr, this.plantyp, this.planstufe, "").then(ps => {            
            this.psObject = ps;
            this.setState({ ready: true })
        }).catch(Reason => {            
        });

        client.texte(this.kunde, this.gemeinde, this.jahr, this.plantyp == 3 ? 5 : (this.plantyp == 4 ? 7 : 1), "").then(t => {            
            if (t != null && t.length > 0) {
                this.hauptText = t[0];
            }
            this.setState({ ready: true })
        }).catch(Reason => {
        });

                

        client.settings(this.kunde, this.gemeinde, this.jahr, this.plantyp, this.planstufe, this.plantyp === 3 ? 1 : (this.plantyp === 4 ? 2 : 0), this.state.token).then(s => {
            this.settings = s;

            if (this.plantyp < 3 && s.pjFarbenFuerHover && window.document.styleSheets.length > 0) {
                var sheet = window.document.styleSheets[window.document.styleSheets.length - 1] as CSSStyleSheet;

                if  ( s.hFarbePJ )                
                    sheet.insertRule('.table-hover tbody .kslplus-row-sum-0:hover td, .table-hover tbody .kslplus-row-sum-0:hover th {  background-color: <color>; }'.replace("<color>", s.hFarbePJ), sheet.cssRules.length);

                if (s.hFarbePJ)
                    sheet.insertRule('.table-hover tbody .kslplus-row-sum-1:hover td, .table-hover tbody .kslplus-row-sum-1:hover th {  background-color: <color>; }'.replace("<color>", s.hFarbePJ), sheet.cssRules.length);

                if (s.hFarbeSumPJ)
                    sheet.insertRule('.table-hover tbody .kslplus-row-sum-2:hover td, .table-hover tbody .kslplus-row-sum-2:hover th {  background-color: <color>; }'.replace("<color>", s.hFarbeSumPJ), sheet.cssRules.length);
            }
            if (this.plantyp == 3 && s.jrFarbenFuerHover && window.document.styleSheets.length > 0) {
                var sheet = window.document.styleSheets[window.document.styleSheets.length - 1] as CSSStyleSheet;

                if (s.hFarbeJR)
                    sheet.insertRule('.table-hover tbody .kslplus-row-sum-0:hover td, .table-hover tbody .kslplus-row-sum-0:hover th {  background-color: <color>; }'.replace("<color>", s.hFarbeJR), sheet.cssRules.length);

                if (s.hFarbeJR)
                    sheet.insertRule('.table-hover tbody .kslplus-row-sum-1:hover td, .table-hover tbody .kslplus-row-sum-1:hover th {  background-color: <color>; }'.replace("<color>", s.hFarbeJR), sheet.cssRules.length);

                if (s.hFarbeSumJR)
                    sheet.insertRule('.table-hover tbody .kslplus-row-sum-2:hover td, .table-hover tbody .kslplus-row-sum-2:hover th {  background-color: <color>; }'.replace("<color>", s.hFarbeSumJR), sheet.cssRules.length);
            }

            if (s.buchungsart == 1)
                this.readMassnahmen();
                  

            this.setState({ ready: true })
        }).catch(Reason => {
            alert(Reason.message);
            if (Reason.status == 401)
                this.setState({ unauthorized: true });
            else 
                this.setState({ invalidURL: true });
        });

        //var clientAdmin = new KSLPlusAPI.DigitalHousholdAdmin_Client(api);        
        if (this.plantyp == 4) {
            client.getUHConfigs(this.kunde, this.gemeinde, this.jahr, "").then(c => {
                this.uhconfigs = c;
                if ( c.length > 0 )
                    this.state.uhconfig = c[0];
                this.setState({ ready: true })
            }).catch(Reason => {
            });
        }        

    }
   
    createTTItems = () => {
        var items: JSX.Element[] = [];
        
        if ( !this.settings || !this.settings.ttTexte)
            return items;
        
        var missing = false;
        var missingTabs = [];
        for (var i = 0; i < this.settings.ttTexte.length; i++) {                        
            var id = "kslplus-tab-" + this.settings.ttTexte[i].bezeichnung;            
            if ( document.querySelector("#" + id) )
            {                
                items.push(                
                    <UncontrolledPopover
                        size="lg"                        
                        placement="top"                        
                        target={id}
                        delay={ {show: 1000, hide: 0} }
                        trigger="hover"
                        key={"kslplus-menu-tooltip-" + i.toString()}
                        className="kslplus-menu-popover">
                        <PopoverBody className="kslplus-menu-popover-body">
                            {this.settings.ttTexte[i].text}                            
                        </PopoverBody>
                    </UncontrolledPopover> 
                );                  
            }            
            else 
            {                        
                missingTabs.push(this.settings.ttTexte[i].bezeichnung);
               missing = true;
            }
            
        }
        
        //<UncontrolledTooltip className="kslplus-menu-tooltip" placement="auto" delay={ {show: 1000, hide: 0} } target={id} key={"kslplus-menu-tooltip-" + i.toString()}>{this.settings.ttTexte[i].text}</UncontrolledTooltip>
          
        
        if (missing) {
            this.state.missingTabCount++;
            if ( this.state.missingTabCount < 10 )
                setTimeout(() => { this.setState({ ready: true }) }, 2000);
        }
            
        

        return items;
    }

    createUHConfigOptions = () => {

        var items: JSX.Element[] = [];
        this.uhconfigs.forEach((c) => {
            items.push(<option value={c.id}>{c.bezeichnung}</option>);
        });

        return items;
    }

    handleUHConfigChange(event: ChangeEvent<HTMLSelectElement>) {
        if (event.target != null) {
            var c = this.uhconfigs.find(c => c.id === +event.target.value);
           
            this.setState({ uhconfig: c })
        }
    }


    render() {
        const { location } = this.props;
        const params = QueryString.parse(location.search);
        var kennz = "";
        if (params.kennz != null) {
            if (Array.isArray(params.kennz))
                kennz = params.kennz[0];
            else
                kennz = params.kennz;
        }

        
                

        if (this.state.forgotPW) {
            return (
                <div className="auth-wrapper">
                    <div className="auth-inner">
                        <ForgotPW onSubmit={this.handleForgotPW} onGoBack={() => this.setState({ forgotPW: false })} />
                    </div>
                </div>);
        }
        else if (this.state.needsLogin && this.state.user === undefined) {
            return (
                <div className="auth-wrapper">
                    <div className="auth-inner">
                        <Login onSubmit={this.handleLogin} onForgotPW={this.handleForgotPWClick} failed={this.state.logFailed} />
                    </div>
                </div>);
        }                   

        if (this.state.invalidURL) {
            return (
                <MessageBox header="Fehler" text="Für die aufgerufenen URL liegen leider keine Daten vor!" open={this.state.invalidURL} b1="Weiterleiten zu KSLSoftwerk" onCancel={() => { window.location.href = "https://www.ksl-softwerk.de" }} onB1={() => { window.location.href = "https://www.ksl-softwerk.de" }} />
            );
        }

        if (this.state.unauthorized) {
            return (
                <MessageBox header="Fehler" text="Sie haben nicht die Berechtigung um auf diese URL zuzugreifen!" open={this.state.unauthorized} b1="Zurück zum Login" onCancel={this.handleLogout} onB1 = {this.handleLogout} />
            );
        }

        if (!this.state.ready || this.settings === undefined)
            return <div>Loading</div>;

        if (this.state.missingTabCount > 9)
            this.state.missingTabCount = 0;
        //var ttItems = this.createTTItems();
        var configOptions = this.createUHConfigOptions();

        var vbText = this.plantyp == 3 || this.plantyp == 4 ? "Vorschau" : "Vorbericht";
        var detailsText = this.plantyp == 3 || this.plantyp == 4 ? "Detailansicht" : "Finanzdaten";
        
        if (this.settings.tabTexte) {            
            var text = this.settings.tabTexte.find(t => t.bezeichnung === "vorberichtvorschau");
            if (text && text.text && text.text.length > 0)
                vbText = text.text;

            var text = this.settings.tabTexte.find(t => t.bezeichnung === "finanzdatendetailansicht");
            if (text && text.text && text.text.length > 0)
                detailsText = text.text;
        }
        

        return (
            <div className="kslplus-app-wrapper">
                <Container className="kslplus-app-container">
                    <div className="kslplus-headline">
                        
                        {this.state.fullHeader ?
                            <Row lg="14">
                                <Col lg="4" className="kslplus-headline-wrapper-left">
                                    <div >
                                        <div id="kslplus-headline" className="kslplus-headline-main">{(this.psObject !== null && this.psObject.ueberschrift !== null && this.psObject.ueberschrift !== "") ? this.psObject.ueberschrift : ((this.settings && this.settings.doppelhaushalt) ? ("Doppelhaushalt " + this.jahr + "/" + (this.jahr + 1).toString()) : ("Haushaltsplan " + this.jahr))}</div>
                                        {this.settings.gemeindeName && <div id="kslplus-headline" className="kslplus-headline-main">{this.settings.gemeindeName}</div>}
                                        <div id="kslplus-headline1" className="kslplus-headline-second">{this.psObject !== null ? this.psObject.bezeichnung : ""}</div>
                                    </div>
                                </Col>
                                <Col lg="4" className="kslplus-headline-wrapper-middle"><div ><div id="kslplus-wappen" ><img className="kslplus-img-wappen" src={this.wappen} /></div></div></Col>
                                <Col lg="4" className="kslplus-headline-wrapper-right">
                                    <div >
                                        <Dokumente modul={this.plantyp == 3 ? 1 : 0} kunde={this.kunde} gemeinde={this.gemeinde} jahr={this.jahr} />
                                    </div>
                                    
                                </Col>
                                <MdExpandMore size={50} className="kslplus-expand-header" onClick={() => this.setState({ fullHeader: false })} />
                            </Row> :
                            <Row lg="14">
                                <div className="kslplus-headline-small">  
                                    
                                    <div id="kslplus-headline" className="kslplus-headline-main ">{(this.psObject !== null && this.psObject.ueberschrift !== null && this.psObject.ueberschrift !== "") ? this.psObject.ueberschrift : ((this.settings && this.settings.doppelhaushalt) ? ("Doppelhaushalt " + this.jahr + "/" + (this.jahr + 1).toString()) : ("Haushaltsplan " + this.jahr))}
                                        
                                    </div>
                                    <MdExpandLess size={50} className="kslplus-expand-header" onClick={() => this.setState({ fullHeader: true })} />
                                    
                                </div>                                                                                                    
                            </Row>
                            }
                        
                    </div>                    
                    
                    {(this.hauptText && this.hauptText.bezeichnung && this.hauptText.bezeichnung.length > 0) &&
                        <Row>
                            <Col className="kslplus-maintext-wrapper">
                                <button
                                    className={"kslplus-beschreibung-toggle" + (this.state.hauptTextActive ? " active" : "")}
                                    onClick={() => this.setState({ hauptTextActive: !this.state.hauptTextActive })}
                                >

                                    <span className="kslplus-beschreibung-text">{this.hauptText != null ? this.hauptText.bezeichnung : ""}</span>
                                    <div className="rotate90">
                                        <svg
                                            className={"kslplus-beschreibung-icon" + (this.state.hauptTextActive ? " expanded" : "")}
                                            viewBox="6 0 12 24"
                                        >
                                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                                        </svg>
                                    </div>

                                </button>
                                <Collapse
                                    isOpen={this.state.hauptTextActive}
                                    className={"kslplus-beschreibung-collapse-gradient " + + (this.state.hauptTextActive ? " active" : "")}
                                >
                                    <Row>
                                        <Col className="kslplus-beschreibung-content">
                                            <div className="content" dangerouslySetInnerHTML={{ __html: this.hauptText != null && this.hauptText.text ? this.hauptText.text : "" }}></div>

                                        </Col>
                                    </Row>
                                </Collapse>
                            </Col>

                        </Row>}
                    {this.plantyp === 4 ?
                        <Row>
                            
                            <div className="kslplus-uhconfig-wrapper">
                                <label >Prognosemodell:&nbsp;&nbsp;</label>
                                <select className="form-select" onChange={(e) => this.handleUHConfigChange(e)} value={this.state.uhconfig ? this.state.uhconfig.id : 0}>
                                    {configOptions}
                                </select> 
                            </div>
                        </Row> : null}
                    <Row>
                        <Col >
                            <div className="kslplus-haushaltstab-wrapper-top">
                                <Nav tabs>
                                    <NavItem id={"kslplus-tab-vorberichtvorschau"}>
                                        <NavLink  active={this.state.activeTab==="1"}  onClick={() => this.setState({ activeTab: "1" })} >
                                            <div className="kslplus-reiter-text-hauptzeile">{vbText}</div>
                                        </NavLink>
                                    </NavItem>  
                                    {Utilities.createTTItem(this.settings, "vorberichtvorschau")}
                            
                                    <NavItem id={"kslplus-tab-finanzdatendetailansicht"}>
                                        <NavLink active={this.state.activeTab === "2"} onClick={() => this.setState({ activeTab: "2" })} >
                                            <div className="kslplus-reiter-text-hauptzeile">{detailsText}</div>
                                        </NavLink>
                                    </NavItem>        
                                    {Utilities.createTTItem(this.settings, "finanzdatendetailansicht")}
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        {this.settings.buchungsart === 1 ?
                                            <Vorbericht kunde={this.kunde} gemeinde={this.gemeinde} jahr={this.jahr} plantyp={this.plantyp} planstufe={this.planstufe} uhconfig={this.state.uhconfig} settings={this.settings} isVisible={this.state.activeTab === "1"} verwaltungsansicht={this.verwaltungsansicht} />
                                            :
                                            <VorberichtDoppisch kunde={this.kunde} gemeinde={this.gemeinde} jahr={this.jahr} plantyp={this.plantyp} planstufe={this.planstufe} settings={this.settings} isVisible={this.state.activeTab === "1"} uhconfig={this.state.uhconfig} verwaltungsansicht={this.verwaltungsansicht} />
                                        }
    
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {this.settings.buchungsart === 1 ?
                                            <Finanzdaten kunde={this.kunde} gemeinde={this.gemeinde} jahr={this.jahr} plantyp={this.plantyp} planstufe={this.planstufe} settings={this.settings} massnahmen={this.massnahmen} isVisible={this.state.activeTab === "2"} uhconfig={this.state.uhconfig} verwaltungsansicht={this.verwaltungsansicht} />
                                        :
                                            <FinanzdatenDoppisch kunde={this.kunde} gemeinde={this.gemeinde} jahr={this.jahr} plantyp={this.plantyp} planstufe={this.planstufe} settings={this.settings} isVisible={this.state.activeTab === "2"} uhconfig={this.state.uhconfig} verwaltungsansicht={this.verwaltungsansicht} />
                                        }                                                                                                                                                                
                                    </TabPane>                                                                                                                                   
                                </TabContent>
                            </div>
                        </Col>                        
                    </Row>                    
                </Container>
                
            </div>);

    }
}

export default withRouter(App);