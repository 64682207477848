import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container
} from "reactstrap";

import Collapse from "@kunukn/react-collapse";


import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import Chart from "react-google-charts";
import { GoogleChartWrapper, GoogleChartControl, GoogleViz, ReactGoogleChartProps, ReactGoogleChartEvent, GoogleDataTable, GoogleVizEventName } from "react-google-charts/dist/types";
import { ImgButton } from './ImgButtonComp';

import TrackVisibility from 'react-on-screen';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import Drawer from '@material-ui/core/Drawer';

//import { HaushaltsNav, EbenenTyp } from './HaushaltsNavComp';
import { HaushaltsNavTree, EbenenTyp } from './HaushaltsNavTreeComp';

import { Kennzahl } from './KennzahlComp';
import { KennzahlJR } from './KennzahlJRComp';

import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

const paginationoptions = {
    sizePerPageList: [{
        text: '10', value: 10
    },{
        text: '15', value: 15
    }, {
        text: '20', value: 20
    }] // A numeric array is also available. the purpose of above example is custom the text
};

type expandSet = {
    name: string;
    expanded: boolean;
}

type State = {
    ready: boolean,
    ebene: KSLPlusAPI.HaushaltsEbene | undefined,
    expandedItem: string,
    openBeschreibungen: boolean[],
    alleJahre: boolean,
    expanded: string[],
    suchText: string,
    chartTyp: number,
    grundzahlen: KSLPlusAPI.DatensatzModel[];
    gzopen: boolean;
    kennzahlen: KSLPlusAPI.Kennzahlen[];
    kennzahlopen: boolean;
    expandedCollaps: expandSet[];
}

type Props = {    
    hh: KSLPlusAPI.HaushaltsEbene,
    jahr: number,
    kunde: number,
    gemeinde: number,    
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    massnahmen: KSLPlusAPI.Massnahman[],
    settings: KSLPlusAPI.SettingsModel,
    isvmhh: boolean,
    selected:string,
    onSelChange: (ebeneId: string) => void,
    hideNav: boolean,
    isVisible: boolean
}


export class GLHaushalt extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.dokumente=  [];
    }

    static defaultProps = {
        hideNav: false
    }

    node: any;
    dokumente: KSLPlusAPI.Dokumente[];
    container: any = null;
    getFilters: { (filter: string | undefined): void }[] = [];      

    state: State = {
        ready: false,
        expandedItem: "",
        ebene: this.props.hh,
        openBeschreibungen: [],
        alleJahre: false,
        expanded: [],
        suchText: "",
        chartTyp: 0,
        grundzahlen: [],
        gzopen: false,
        kennzahlen: [],
        kennzahlopen: false,
        expandedCollaps: []
    }

    redrawCharts = () => {        
        if (this.state.ebene )
            this.handleSelectHHEbene(this.state.ebene, this.state.suchText);
    }

    componentDidUpdate(prevProps: Props) {
        //if ((prevProps.selected !== this.props.selected || prevProps.isVisible !== this.props.isVisible) && this.props.hh.unterEbenen) {

            
        if (this.props.hh.unterEbenen) {            
            var ebene = this.findEbene(this.props.selected, this.props.hh.unterEbenen);
            if (ebene && ebene !== this.state.ebene) {                                
                this.readFiltered(ebene);                
            }
        }
        if (prevProps.uhconfig !== this.props.uhconfig && this.props.plantyp === 4 && this.state.ebene) {            
            this.readFiltered(this.state.ebene);
        }

    } 

    readKennzahlen = (ebene: KSLPlusAPI.HaushaltsEbene) => {
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;
        request.modul = (this.props.plantyp == 3 ? 1 : (this.props.plantyp == 4 ? 2 : 0));

        var gl = ""; 
        if (ebene.unterabschnitt !== undefined && ebene.uaStellen)
            gl = Utilities.Num2Str(ebene.unterabschnitt, ebene.uaStellen);
        else
            return;
        

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdAdmin_Client(api);
                
        client.getProduktKennzahlen(gl, this.props.isvmhh ? 1 : 0, "", request).then((kennzahlen) => {
            this.state.kennzahlen = kennzahlen;                
            this.setState({ ready: true, expandedCollaps: [] });
        }).catch(Reason => {              
            this.readKennzahlen(ebene);
        });

    }

    readGrundzahlen = (ebene: KSLPlusAPI.HaushaltsEbene ) => {
        this.state.grundzahlen = [];

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        var gl = "";
        if (ebene.unterabschnitt !== undefined && ebene.uaStellen)
            gl = Utilities.Num2Str(ebene.unterabschnitt, ebene.uaStellen);
        else
            return;
            

        client.grundzahlen(this.props.kunde, this.props.gemeinde, this.props.jahr, this.props.plantyp === 3 ? 1 : (this.props.plantyp === 4 ? 2 : 0), gl, "").then((gz) => {
            this.getFilters = [];
            this.setState({ grundzahlen: gz });
        }).catch(Reason => {            
        });
    }


    componentDidMount() {
        window.addEventListener('resize', this.redrawCharts, false);
        if (this.state.ebene) {            
            if (this.props.hh.unterEbenen) {
                var ebene = this.findEbene(this.props.selected, this.props.hh.unterEbenen);
                if (ebene && ebene !== this.state.ebene) {
                    this.readFiltered(ebene);
                }
                else
                    this.readFiltered(this.state.ebene);
            }
            else
                this.readFiltered(this.state.ebene);
        }
    }

    collapsRow = (row: KSLPlusAPI.HaushaltsEbene) => {
        this.state.expanded = this.state.expanded.filter(x => x !== row.id);
        if (row.unterEbenen)
            row.unterEbenen.forEach(r => this.collapsRow(r));


    }

    handleOnExpand = (row: KSLPlusAPI.HaushaltsEbene, isExpand: boolean, rowIndex: number, e: any) => {
        if (isExpand) {
            this.state.expanded.push(row.id ? row.id : "");
        } else {
            this.state.expanded = this.state.expanded.filter(x => x !== row.id);
            if (row.unterEbenen)
                row.unterEbenen.forEach(r => this.collapsRow(r));
            this.setState({ expanded: this.state.expanded })
        }
    }

    setStyle = (name: string, style: string) => {
        var trElem = document.getElementsByClassName(name);


        if (trElem && trElem.length > 0)
            trElem[0].setAttribute("style", style);

    }

    scrollIntoView = (name: string) => {

        var trElem = document.getElementsByClassName(name);

/*
        if (trElem && trElem.length > 0) {
            trElem[0].scrollIntoView({ block: 'center' });
            trElem[0].setAttribute("style", "background-color: #FDFF47");
            setTimeout(() => this.setStyle(name, ""), 10000);

        }
        else
            setTimeout(() => this.scrollIntoView(name), 1000);
            */

    }

    expandBySuchtext = (row: KSLPlusAPI.HaushaltsEbene) => {
        var any = false;

        if (row.unterEbenen)
            row.unterEbenen.forEach(r => {
                if (this.expandBySuchtext(r)) {
                    any = true;
                }

            });
        /*
    if (row.suchText && this.props.suchText.length > 0 && row.suchText.toLowerCase().indexOf(this.props.suchText) != -1) {
        this.scrollIntoView("kslplus-row-id-" + row.id);
        any = true;
    }
    */
        if (row.suchText && this.state.suchText.length > 0 && row.suchText.toLowerCase().indexOf(this.state.suchText) != -1) {
            this.scrollIntoView("kslplus-row-id-" + row.id);
            any = true;
        }

        if (any) {

            this.handleOnExpand(row, true, 0, 0);

            //this.state.expanded.push(row.id ? row.id : "");
        }

        return any;
    }

    rowClasses = (row: KSLPlusAPI.HaushaltsEbene, rowIdx: number) => {

        return "kslplus-row-sum-0 " + "kslplus-row-id-" + row.id;
    }

    expandRowEinnahmen = {
      renderer: (row: KSLPlusAPI.HaushaltsEbene) => (
            row.unterEbenen ?                                  
                <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createColumns(row, row, null, null, null, false, false, this.props.hh.jahre ? this.props.hh.jahre : [])} hiddenRows={this.createHiddenRowKeys(row.unterEbenen, false)} expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen) ? this.expandRowEinnahmen : undefined} rowClasses={this.rowClasses} />
            :
                null
        
      ),
      className: 'kslplus-tabellen-expanded',
      showExpandColumn: true,
      expandColumnPosition: 'left',
      expandHeaderColumnRenderer: ({ isAnyExpands } : { isAnyExpands: boolean}) => {
        return null;        
      },
      expandColumnRenderer: ({ expanded } : { expanded: boolean}) => {
        if (expanded) {
          return (
            <div className="kslplus-expand-column">(-)</div>
          );
        }
        return (
          <div className="kslplus-expand-column">(+)</div>
        );
      },
      expanded: this.state.expanded,
      onExpand: this.handleOnExpand      
    };

    expandRowAusgaben = {
      renderer: (row: KSLPlusAPI.HaushaltsEbene) => (            
            row.unterEbenen ?        
                <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createColumns(row, row, null, row, null, true, false, this.props.hh.jahre ? this.props.hh.jahre : [])} hiddenRows={this.createHiddenRowKeys(row.unterEbenen, true)} expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen) ? this.expandRowAusgaben : undefined} rowClasses={this.rowClasses} />
            :
                null
            
        
      ),
      className: 'kslplus-tabellen-expanded',
      showExpandColumn: true,
      expandColumnPosition: 'left',
      expandHeaderColumnRenderer: ({ isAnyExpands } : { isAnyExpands: boolean}) => {
        return null;        
      },
      expandColumnRenderer: ({ expanded } : { expanded: boolean}) => {
        if (expanded) {
          return (
            <div className="kslplus-expand-column">(-)</div>
          );
        }
        return (
          <div className="kslplus-expand-column">(+)</div>
        );
       },
       expanded: this.state.expanded,
       onExpand: this.handleOnExpand
      
      
    };


    


    createHighDifItems = (rows: KSLPlusAPI.HaushaltsEbene[], field1: string, field2: string) => {
        var items = [];

        for ( let i = 0; i < Math.min(3, rows.length); i++ )
        {              
            const rowAsArray = rows[i] as unknown as { [key: string]: number };
            if ( rowAsArray[field2]-rowAsArray[field1] != 0 )                                    
                items.push(<div key={i.toString()}><Row><Col lg="8"><div className="kslplus-change-popover-bezeichnung">{Utilities.Num2Str(rows[i].untergruppe, rows[i].ugStellen) + " " + rows[i].bezeichnung}</div></Col><Col><div className="kslplus-change-popover-wert">{Utilities.FormatCurrency(rowAsArray[field2]-rowAsArray[field1], 2, 0, '.', ',') + " EUR"}</div></Col></Row></div>);
        }
        return items;
    }   

   
    createChangeColumn = (field1: string, field2: string, footer1: number, footer2: number, ausgaben: boolean, id: number, showFooter: boolean = true, text: string = "Vorjahr", pjFarben: boolean = false, jrFarben: boolean = false) => {         
        return {
            dataField: "dummy"  + id.toString(),
            text: "+/-",
            headerAlign: 'right',
            align: 'right',
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsEbene) => {                
                const rowAsArray = row as unknown as { [key: string]: number };
                
                let sorted : KSLPlusAPI.HaushaltsEbene[] = [];                
                if ( row.unterEbenen && row.ugStellen && row.ugStellen < 3  )                    
                    sorted = row.unterEbenen.slice();
                    sorted = sorted.sort((r1, r2) => {
                        const row1 = r1 as unknown as { [key: string]: number };
                        const row2 = r2 as unknown as { [key: string]: number };

                        return ( Math.abs(row2[field1]-row2[field2]) - Math.abs(row1[field1]-row1[field2]))

                });               
                var items = this.createHighDifItems(sorted, field1, field2);
                
                var res = Utilities.calcArrowRotAndColor(rowAsArray[field1], rowAsArray[field2], ausgaben, this.props.settings);
                
                var ttId = row.id ? "kslplus-label-" + id + "_" + row.id : "";
                ttId = ttId + (ausgaben ? "-a" : "-e");

                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className="kslplus-change-popover-body">
                                <Container className={items.length > 0 ? "kslplus-change-popover-block":""}>                                          
                                    <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP-1)*100, 2, 0, '.', ',') + "  %)"}</div>                                                                                                                                         
                                    {items.length > 0 && <br/>}
                                    {items.length > 0 && <div className="kslplus-change-popover-headline">Davon wesentlich:</div>}
                                    {items.length > 0 && 
                                        <div className="kslplus-change-popover-container-werte">
                                            {items}
                                        </div>
                                    }
                                </Container>
                            </PopoverBody>
                        </UncontrolledPopover>                        
                    </div>
                    
                );

            }, 
            footerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                if (jrFarben)
                    return { backgroundColor: this.props.settings.hFarbeJR, color: this.props.settings.vFarbeJR };



                return {};
            },
            headerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                if (jrFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbeJR, color: this.props.settings.vFarbeJR };


                return { width: "40px" };
            },
            style: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                if (jrFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbeJR, color: this.props.settings.vFarbeJR };



                return { width: "40px" };
            },           
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: number) => {
                var res = Utilities.calcArrowRotAndColor(footer1, footer2, ausgaben, this.props.settings);
                
                var ttId = "kslplus-label-change-gl-" + (this.props.isvmhh?"vm-" : "vw-") +  "-" + (ausgaben ? "a-" : "e-") + columnIndex;
                
                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className="kslplus-change-popover-body">
                                    <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP-1)*100, 2, 0, '.', ',') + "  %)"}</div>                                                                                                                                                                                                            
                            </PopoverBody>
                        </UncontrolledPopover>     
                        
                    </div>
                    
                );
            }
        };
    }

    createDataColumnUH = (field: string, jahr: number, footer: number, footer2: number | undefined, id: number, text: string, hidden: boolean = false, ttE: boolean = false, ttF: boolean = false, testfield: string = "", komfield: string = "", pkTip: boolean = false, gsTip: boolean = false, ausgefuehrtTip: boolean = false) => {
        return {
            dataField: field,
            text: text + " " + jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerFormatter: (column: any, columnIndex: any) => {
                if (ausgefuehrtTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Ausgeführt errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.verfuegtSpaltenDic &&
                                            Utilities.createAusgefuehrtTooltipItems(this.props.uhconfig.verfuegtSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (gsTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Gesamtsoll errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.fortAnsatzSpaltenDic &&
                                            Utilities.createGSTooltipItems(this.props.uhconfig.fortAnsatzSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (ttE) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Ergebnis (Rechnungsergebnis) errechnet sich wie folgt:</div></div>
                                        <br />
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ausgeführtes Soll auf lfd. Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang auf Kassenreste aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Neue Haushaltsreste</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang Haushaltsreste aus Vj.</div></div>


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (ttF) {

                    var ttId = "header-tt-gl-fort-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>
                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Der fortgeschrieben Ansatz errechnet sich wie folgt:</div></div>
                                        <br />
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Nachtragswerte</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Sollveränderungen</div></div>
                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else {
                    return (
                        <div className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>
                    );
                }
            },
            formatter: (cellContent: string, row: { [key: string]: any }) => {                
                if (pkTip && row["pkTip"]) {
                    return (<div className="kslplus-tabellen-data" >{row["pkTip"] ? <div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-progtip-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-progtip-" + row.id : ""}>{row["pkTip"]}</UncontrolledTooltip></div> : null}{Utilities.FormatCurrency(row[field] as number, 2, 3, '.', ',')}</div>);
                }
                if (komfield !== "") {
                    return (<div className="kslplus-tabellen-data" >{row[komfield] ? <div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-progkomm-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-progkomm-" + row.id : ""}>{row[komfield]}</UncontrolledTooltip></div> : null}{Utilities.FormatCurrency(row[field] as number, 2, 3, '.', ',')}</div>);
                }

                if (row["jahrTips"] && row["jahrTips"][jahr]) {
                    return (<div className="kslplus-tabellen-data" >{<div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-jahr-" + jahr + "-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-jahr-" + jahr + "-" + row.id : ""}><div className="kslplus-tooltip">{row["jahrTips"][jahr]}</div></UncontrolledTooltip></div>}{Utilities.FormatCurrency(row[field] as number, 2, 3, '.', ',')}</div>);
                }

                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], 2, 3, '.', ',')} </div>
                );

            },
            footer: id,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if (footer2 !== undefined)
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, 2, 3, '.', ',')} </div></div>);

                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div>
                );
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if (testfield != "" && row[testfield])
                    return { backgroundColor: this.props.uhconfig && this.props.uhconfig.hfarbeProgMan ? this.props.uhconfig.hfarbeProgMan : 'lightcoral', color: this.props.uhconfig && this.props.uhconfig.vfarbeProgMan ? this.props.uhconfig.vfarbeProgMan : 'black' }
            }
        };
    }

    createDataColumnJR = (field: string, jahr: number, footer: number, footer2: number|undefined, id: number, showFooter : boolean, text: string, ttE: boolean = false, ttF: boolean = false) => {        
        return {
            dataField: field,
            text: text + " "+ jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',       
            headerFormatter: (column: any, columnIndex: any) => {
                if ( ttE )    
                {
                    
                    var ttId = "header-tt-gl-erg-" + field;
                
                    return (  
                        <div>                                          
                            <div id={ttId} className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>
                    
                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>                                          
                                            <div><div className="kslplus-header-tt-block1">Das Ergebnis (Rechnungsergebnis) errechnet sich wie folgt:</div></div>
                                            <br/>                                        
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ausgeführtes Soll auf lfd. Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang auf Kassenreste aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Neue Haushaltsreste</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang Haushaltsreste aus Vj.</div></div>
                                        

                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>                    
                        </div>
                        ); 
                }
                else if ( ttF )    
                {
                    
                    var ttId = "header-tt-gl-fort-" + field;
                
                    return (             
                            <div>                               
                                <div id={ttId} className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>                    
                                <UncontrolledPopover
                                    size="xl"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-header-popover">
                                    <PopoverBody className="kslplus-header-popover-body">
                                        <Container>                                          
                                             <div><div className="kslplus-header-tt-block1">Der fortgeschrieben Ansatz errechnet sich wie folgt:</div></div>
                                             <br/>                                        
                                            <div className="kslplus-header-tt-block"><div className="kslplus-label">Ansatz</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Nachtragswerte</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">HHReste a. VJ</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Sollveränderungen</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Abgänge auf HHReste a. Vj</div></div>                                                                                    
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Über-/außerplanmäßige Bewilligungen</div></div>                                                                                    
                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>                    
                            </div>
                        ); 
                }
                else
                {
                    return (                                            
                        <div className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>
                    );
                }
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if (this.props.plantyp === 3 && jahr - this.props.jahr === 0) {
                    return { backgroundColor: this.props.settings.hFarbeJR, color: this.props.settings.vFarbeJR };
                }

                return {};
            },
            headerStyle: (colum: any, colIndex: any) => {
                if (this.props.plantyp === 3 && jahr - this.props.jahr === 0) {
                    return { backgroundColor: this.props.settings.hFarbeJR, color: this.props.settings.vFarbeJR };
                }

                return {};
            },
            formatter: (cellContent: string, row: { [key: string]: any }) => {   

                if (row["jahrTips"] && row["jahrTips"][jahr]) {
                    return (<div className="kslplus-tabellen-data" >{<div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-jahr-" + jahr + "-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-jahr-" + jahr + "-" + row.id : ""}><div className="kslplus-tooltip">{row["jahrTips"][jahr]}</div></UncontrolledTooltip></div>}{Utilities.FormatCurrency(row[field] as number, 2, 3, '.', ',')}</div>);
                }

                return (                    
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], 2, 3, '.', ',')} </div>                    
                );
                
            },
            footer: showFooter ? id : undefined,
            footerStyle: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if (this.props.plantyp === 3 && jahr - this.props.jahr === 0) {
                    return { backgroundColor: this.props.settings.hFarbeJR, color: this.props.settings.vFarbeJR };
                }

                return {};

            },
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if ( footer2 !== undefined )
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, 2, 3, '.', ',')} </div></div>);
            
                return (                    
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div>
                    );
            }
        };
    }

    createDataColumn = (field: string, jahr: number, footer: number, footer2: number | undefined, id: number, showFooter : boolean = true) => {
        var text = "";
        if (jahr - this.props.jahr == -2 && this.props.plantyp < 3 && this.props.settings.vvjVorl)
            text = "vorl. Ergebnis ";
        else if (  jahr - this.props.jahr < -1 )
            text = "Ergebnis ";
        else if ( jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0) )
            text = "Finanzplan ";
        else
            text = "Ansatz "; 

        return {
            dataField: field,
            text: text + jahr + " (EUR)",
            headerAlign: 'center',
            align: 'right',
            formatter: (cellContent: string, row: { [key: string]: any }) => {
                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }

                if (row["jahrTips"] && row["jahrTips"][jahr]) {
                    return (<div className="kslplus-tabellen-data" >{<div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-jahr-" + jahr + "-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-jahr-" + jahr + "-" + row.id : ""}><div className="kslplus-tooltip">{row["jahrTips"][jahr]}</div></UncontrolledTooltip></div>}{Utilities.FormatCurrency(row[field] as number, nk, 3, '.', ',')}</div>);
                }

                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], nk, 3, '.', ',')} </div>
                );

            },           
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }

                if (footer2 !== undefined)
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, nk, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, nk, 3, '.', ',')} </div></div>);

                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, nk, 3, '.', ',')} </div>
                );
            },
            footerStyle: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                return {};

            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }

                //return { width: '15%' };
            },
            headerStyle: (colum: any, colIndex: any) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                else
                    return {};
            }
        };
    }
    
    createDataPercentColumn = (field: string, field1: string, jahr: number, id: number, text: string = "", hidden: boolean = false, ttE: boolean = false, ttF: boolean = false, footVal: number, footVal1: number) => {    
        if (text == "") {
            if (jahr - this.props.jahr < -1)
                text = "Ergebnis";
            else if (jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0))
                text = "Finanzplan";
            else
                text = "Ansatz";
        }
        return {
            dataField: field,
            text: text + " " + jahr + " (%)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(%)</div></div>
                );
            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                if (Math.round(row[field] * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-data">0,00</div>
                    );
                else if (row[field1] === 0 && row[field] < 0) {
                    return (<div className="kslplus-tabellen-data">-Ꝏ</div>);
                }
                else if (row[field1] === 0) {

                    return (<div className="kslplus-tabellen-data">Ꝏ</div>);
                }
                else
                    return (
                        <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field] / row[field1] * 100.0, 2, 3, '.', ',')}</div>
                    );

            },
            footer: id,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if (Math.round(footVal * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-footer">0,00</div>
                    );
                else if (footVal1 === 0 && footVal < 0) {
                    return (<div className="kslplus-tabellen-footer">-Ꝏ</div>);
                }
                else if (footVal1 === 0) {

                    return (<div className="kslplus-tabellen-footer">Ꝏ</div>);
                }
                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footVal / footVal1 * 100.0, 2, 3, '.', ',')} </div>
                );
            }
        };
    }


    createColumnsJR = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum: KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben: KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean, showFooter : boolean = true, jahre: number[] = []) => {
        var columns = [];
        
        if ( data && data.unterEbenen && !data.unterEbenen.find(e => e.unterEbenen) ) {        
            columns.push({            
                dataField: 'df1',
                text: "",
                isDummyField: true,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                footer: showFooter ? "1" : undefined,
                
            });
        }
        columns.push({            
            dataField: 'bezeichnung',
            text: ausgaben ? "Ausgaben/Bezeichnung" : "Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsEbene) => {
                //var label = "";
                var label: JSX.Element[] = [];
                var tooltip = "";
                var tooltipMn = "";
                if (row.unterEbenen) {
                    //label = row.untergruppe !== undefined && row.ugStellen !== undefined ? Utilities.Num2Str(row.untergruppe, row.ugStellen) + " - " : "";
                    if (row.untergruppe !== undefined && row.ugStellen !== undefined)
                        label.push(<span key={"kslplus-label-" + row.id + "-ug"}>{Utilities.Num2Str(row.untergruppe, row.ugStellen) + " - "}</span>);
                    
                }
                else {
                    /*
                    if (row.unterabschnitt !== undefined && row.uaStellen !== undefined)
                        label += Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + ".";
                    if (row.massnahme !== null && row.massnahme !== undefined)
                        label += Utilities.Num2Str(row.massnahme, 3) + ".";
                    if (row.unterkonto !== undefined && row.ugStellen !== undefined)
                        label += Utilities.Num2Str(row.untergruppe, row.ugStellen);
                    if (row.unterkonto)
                        label += "." + Utilities.Num2Str(row.unterkonto, 2);

                    label += " - ";
                    */
                    if ( row.unterabschnitt !== undefined && row.uaStellen )
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id} key={"kslplus-label-" + row.id}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen)}</span>);
                    if (row.massnahme !== null && row.massnahme !== undefined) {
                        var mn = this.props.massnahmen.find(m => m.nr == row.massnahme && m.unterabschnitt == row.unterabschnitt && m.untergruppe == row.untergruppe);
                        if (mn && mn.bezeichnung)
                            tooltipMn = mn.bezeichnung;
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id + "mn"} key={"kslplus-label-" + row.id + "mn"}>{(label.length > 0 ? "." : "") +  Utilities.Num2Str(row.massnahme, 3)}</span>);
                    }
                    if ( row.untergruppe !== undefined && row.ugStellen )                        
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ug"}>{(label.length > 0 ? "." : "") +  Utilities.Num2Str(row.untergruppe, row.ugStellen)}</span>);
                    if (row.unterkonto)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uk"}>{(label.length > 0 ? "." : "") +  Utilities.Num2Str(row.unterkonto, 3)}</span>);
                        
                    label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "sep"}> - </span>);
                    tooltip = row.uaText?row.uaText:"";
                }
                //label = label + (row.ugText ? row.ugText : cellContent);
                label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ugtext"}>{row.ugText ? row.ugText : cellContent}</span>);
                if (row.isHHS )
                    return (                 
                        <div>
                            <div className="kslplus-tabellen-bezeichnung" >{label} {row.isHHS && row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                            {tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null}                        
                        </div>
                    );
                else
                    return (
                        <div>
                            <div className="kslplus-tabellen-bezeichnung" >{label} {row.toolTip != null && row.toolTip.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}><div className="kslplus-tooltip">{row.toolTip}</div></UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                            {tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null}
                        </div>
                    );


            },
            style: (colum: any, colIndex: any) => {
                if ( !showFooter )                
                    return { width: '250px', paddingLeft: "20px" };
                return { width: '250px' };
            },
            footer: showFooter ? "2" : undefined,
            footerFormatter: (column: any, columnIndex: any) => {
                if (ausgaben)
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);

                return <div>Einnahmen gesamt</div>;
            }
        });

        if (jahre.length == 0 && data && data.jahre)
            jahre = data.jahre;

        if (data == null)
            return columns;

        var id = 3;
        
        if (ausgaben) {
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            
            if (jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_soll != null /*&& totalSum.ergebnisPJM4_soll != 0*/ && data.ergebnisPJM4_haben != null && data.ergebnisPJM4_soll != null /*&& data.ergebnisPJM4_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM4_haben != null && totalHaben.ergebnisPJM4_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_soll != undefined ? totalSub.ergebnisPJM4_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM4_haben != undefined ? totalHabenSub.ergebnisPJM4_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM4_soll", this.props.jahr - 4, totalSum.ergebnisPJM4_soll - subsoll, totalHaben.ergebnisPJM4_haben - totalSum.ergebnisPJM4_soll - subErgebnis + subsoll, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_soll", sumPrev, totalSum.ergebnisPJM4_soll - subsoll, true, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM4_soll";
                sumPrev = totalSum.ergebnisPJM4_soll - subsoll;                
            }
            else {
                fieldPrev = undefined;
            }
            

            if (jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_haben != null && totalSum.ergebnisPJM3_soll != null /*&& totalSum.ergebnisPJM3_soll != 0*/ && data.ergebnisPJM3_haben != null && data.ergebnisPJM3_soll != null /*&& data.ergebnisPJM3_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM3_haben != null && totalHaben.ergebnisPJM3_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_soll != undefined ? totalSub.ergebnisPJM3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM3_haben != undefined ? totalHabenSub.ergebnisPJM3_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM3_soll", this.props.jahr - 3, totalSum.ergebnisPJM3_soll - subsoll, totalHaben.ergebnisPJM3_haben - totalSum.ergebnisPJM3_soll - subErgebnis + subsoll, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_soll", sumPrev, totalSum.ergebnisPJM3_soll - subsoll, true, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM3_soll";
                sumPrev = totalSum.ergebnisPJM3_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_haben != null && totalSum.ergebnisPJM2_soll != null /*&& totalSum.ergebnisPJM2_soll != 0*/ && data.ergebnisPJM2_haben != null && data.ergebnisPJM2_soll != null /*&& data.ergebnisPJM2_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM2_haben != null && totalHaben.ergebnisPJM2_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_soll != undefined ? totalSub.ergebnisPJM2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM2_haben != undefined ? totalHabenSub.ergebnisPJM2_haben : 0);               
                columns.push(this.createDataColumnJR("ergebnisPJM2_soll", this.props.jahr - 2, totalSum.ergebnisPJM2_soll - subsoll, totalHaben.ergebnisPJM2_haben - totalSum.ergebnisPJM2_soll - subErgebnis + subsoll, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_soll", sumPrev, totalSum.ergebnisPJM2_soll - subsoll, true, id, showFooter));
                    id++;
                }                
                fieldPrev = "ergebnisPJM2_soll";
                sumPrev = totalSum.ergebnisPJM2_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_haben != null && totalSum.ergebnisPJM1_soll != null /*&& totalSum.ergebnisPJM1_soll != 0*/ && data.ergebnisPJM1_haben != null && data.ergebnisPJM1_soll != null /*&& data.ergebnisPJM1_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM1_haben != null && totalHaben.ergebnisPJM1_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_soll != undefined ? totalSub.ergebnisPJM1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM1_haben != undefined ? totalHabenSub.ergebnisPJM1_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM1_soll", this.props.jahr - 1, totalSum.ergebnisPJM1_soll - subsoll, totalHaben.ergebnisPJM1_haben - totalSum.ergebnisPJM1_soll - subErgebnis + subsoll, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_soll", sumPrev, totalSum.ergebnisPJM1_soll - subsoll, true, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM1_soll";
                sumPrev = totalSum.ergebnisPJM1_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (jahre.findIndex(d => d == this.props.jahr) >= 0 &&  totalSum.ansatzPJfort_haben != null && totalSum.ansatzPJfort_soll != null /*&& totalSum.ansatzPJfort_soll != 0*/ && data.ansatzPJfort_haben != null && data.ansatzPJfort_soll != null /*&& data.ansatzPJfort_soll != 0*/ && totalHaben != null && totalHaben.ansatzPJfort_haben != null && totalHaben.ansatzPJfort_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_soll != undefined ? totalSub.ansatzPJfort_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPJfort_haben != undefined ? totalHabenSub.ansatzPJfort_haben : 0);                
                columns.push(this.createDataColumnJR("ansatzPJfort_soll", this.props.jahr, totalSum.ansatzPJfort_soll - subsoll, totalHaben.ansatzPJfort_haben - totalSum.ansatzPJfort_soll - subErgebnis + subsoll, id, showFooter, "Fortgeschriebener Ansatz", false, true));
                id++;
                
                fieldPrev = "ansatzPJfort_soll";
                sumPrev = totalSum.ansatzPJfort_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ergebnisPJ_haben != null && totalSum.ergebnisPJ_soll != null /*&& totalSum.ergebnisPJ_soll != 0*/ && data.ergebnisPJ_haben != null && data.ergebnisPJ_soll != null /*&& data.ergebnisPJ_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJ_haben != null && totalHaben.ergebnisPJ_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_haben != undefined ? totalSub.ergebnisPJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_soll != undefined ? totalSub.ergebnisPJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJ_haben != undefined ? totalHabenSub.ergebnisPJ_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJ_soll", this.props.jahr, totalSum.ergebnisPJ_soll - subsoll, totalHaben.ergebnisPJ_haben - totalSum.ergebnisPJ_soll - subErgebnis + subsoll, id, showFooter, "Ergebnis", true));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ_soll", sumPrev, totalSum.ergebnisPJ_soll - subsoll, true, id, showFooter, "fortgeschriebenem Ansatz", false, true));
                    id++;
                }
                fieldPrev = "ergebnisPJ_soll";
                sumPrev = totalSum.ergebnisPJ_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }                        

        }
        else {            
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            if (jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_soll != null && totalSum.ergebnisPJM4_haben != null /*&& totalSum.ergebnisPJM4_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);                            
                columns.push(this.createDataColumnJR("ergebnisPJM4_haben", this.props.jahr - 4, totalSum.ergebnisPJM4_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_haben", sumPrev, totalSum.ergebnisPJM4_haben - subhaben, false, id, showFooter ));
                    id++;
                }
                fieldPrev = "ergebnisPJM4_haben";
                sumPrev = totalSum.ergebnisPJM4_haben - subhaben;
            }
            else {                
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_soll != null && totalSum.ergebnisPJM3_haben != null /*&& totalSum.ergebnisPJM3_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM3_haben", this.props.jahr - 3, totalSum.ergebnisPJM3_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_haben", sumPrev, totalSum.ergebnisPJM3_haben - subhaben, false, id, showFooter ));
                    id++;
                }
                fieldPrev = "ergebnisPJM3_haben";
                sumPrev = totalSum.ergebnisPJM3_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_soll != null && totalSum.ergebnisPJM2_haben != null /*&& totalSum.ergebnisPJM2_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM2_haben", this.props.jahr - 2, totalSum.ergebnisPJM2_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_haben", sumPrev, totalSum.ergebnisPJM2_haben - subhaben, false, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM2_haben";
                sumPrev = totalSum.ergebnisPJM2_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_soll != null && totalSum.ergebnisPJM1_haben != null /*&& totalSum.ergebnisPJM1_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM1_haben", this.props.jahr - 1, totalSum.ergebnisPJM1_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_haben", sumPrev, totalSum.ergebnisPJM1_haben - subhaben, false, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM1_haben";
                sumPrev = totalSum.ergebnisPJM1_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_soll != null && totalSum.ansatzPJfort_haben != null /*&& totalSum.ansatzPJfort_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnJR("ansatzPJfort_haben", this.props.jahr, totalSum.ansatzPJfort_haben - subhaben, undefined, id, showFooter, "Fortgeschriebener Ansatz", false, true));
                id++;                
                fieldPrev = "ansatzPJfort_haben";
                sumPrev = totalSum.ansatzPJfort_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ergebnisPJ_soll != null && totalSum.ergebnisPJ_haben != null /*&& totalSum.ergebnisPJ_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_haben != undefined ? totalSub.ergebnisPJ_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJ_haben", this.props.jahr, totalSum.ergebnisPJ_haben - subhaben, undefined, id, showFooter, "Ergebnis", true));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ_haben", sumPrev, totalSum.ergebnisPJ_haben - subhaben, false, id, showFooter, "fortgeschriebenem Ansatz", false, true));
                    id++;
                }
                fieldPrev = "ergebnisPJ_haben";
                sumPrev = totalSum.ergebnisPJ_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }                        

        }
        return columns;
    }
    

    createColumnsUH = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum: KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben: KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean, showFooter: boolean = true, jahre: number[] = []) => {
        var columns = [];

        if (data && data.unterEbenen && !data.unterEbenen.find(e => e.unterEbenen)) {
            columns.push({
                dataField: 'df1',
                text: "",
                isDummyField: true,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                footer: showFooter ? "1" : undefined,

            });
        }
        columns.push({
            dataField: 'bezeichnung',
            text: ausgaben ? "Ausgaben/Bezeichnung" : "Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsEbene) => {
                //var label = "";
                var label: JSX.Element[] = [];
                var tooltip = "";
                var tooltipMn = "";
                if (row.unterEbenen) {
                    //label = row.untergruppe !== undefined && row.ugStellen !== undefined ? Utilities.Num2Str(row.untergruppe, row.ugStellen) + " - " : "";
                    if (row.untergruppe !== undefined && row.ugStellen !== undefined)
                        label.push(<span key={"kslplus-label-" + row.id + "-ug"}>{Utilities.Num2Str(row.untergruppe, row.ugStellen) + " - "}</span>);

                }
                else {
                    /*
                    if (row.unterabschnitt !== undefined && row.uaStellen !== undefined)
                        label += Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + ".";
                    if (row.massnahme !== null && row.massnahme !== undefined)
                        label += Utilities.Num2Str(row.massnahme, 3) + ".";
                    if (row.unterkonto !== undefined && row.ugStellen !== undefined)
                        label += Utilities.Num2Str(row.untergruppe, row.ugStellen);
                    if (row.unterkonto)
                        label += "." + Utilities.Num2Str(row.unterkonto, 2);

                    label += " - ";
                    */
                    if (row.unterabschnitt !== undefined && row.uaStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id} key={"kslplus-label-" + row.id}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen)}</span>);
                    if (row.massnahme !== null && row.massnahme !== undefined) {
                        var mn = this.props.massnahmen.find(m => m.nr == row.massnahme && m.unterabschnitt == row.unterabschnitt && m.untergruppe == row.untergruppe);
                        if (mn && mn.bezeichnung)
                            tooltipMn = mn.bezeichnung;
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id + "mn"} key={"kslplus-label-" + row.id + "mn"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.massnahme, 3)}</span>);
                    }
                    if (row.untergruppe !== undefined && row.ugStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ug"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.untergruppe, row.ugStellen)}</span>);
                    if (row.unterkonto)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uk"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.unterkonto, 3)}</span>);

                    label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "sep"}> - </span>);
                    tooltip = row.uaText ? row.uaText : "";
                }
                //label = label + (row.ugText ? row.ugText : cellContent);
                label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ugtext"}>{row.ugText ? row.ugText : cellContent}</span>);
                if (row.isHHS)
                    return (
                        <div>
                            <div className="kslplus-tabellen-bezeichnung" >{label} {row.isHHS && row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                            {tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null}
                        </div>
                );
                else
                    return(
                        <div>
                            <div className="kslplus-tabellen-bezeichnung" >{label} {row.toolTip != null && row.toolTip.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}><div className="kslplus-tooltip">{row.toolTip}</div></UncontrolledTooltip></div> : null}</div>{ tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null }
                            { tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null }
                        </div >
                    );


            },
            style: (colum: any, colIndex: any) => {
                if (!showFooter)
                    return { width: '250px', paddingLeft: "20px" };
                return { width: '250px' };
            },
            footer: showFooter ? "2" : undefined,
            footerFormatter: (column: any, columnIndex: any) => {
                if (ausgaben)
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);

                return <div>Einnahmen gesamt</div>;
            }
        });

        if (jahre.length == 0 && data && data.jahre)
            jahre = data.jahre;

        if (data == null)
            return columns;

        if (jahre.findIndex(d => d == this.props.jahr - 3) != -1 || jahre.findIndex(d => d == this.props.jahr - 4) != -1) {
            columns.push({
                dataField: '',
                isDummyField: true,
                headerAlign: 'middle',
                headerFormatter: (column: any, columnIndex: any) => {
                    var ttId = "kslplus-tt-pjcol-gl" + (ausgaben ? "a" : "e");
                    return (<div>
                        <div id={ttId}>{this.state.alleJahre ? "-" : "+"}</div >
                        <UncontrolledPopover
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-header-popover">
                            <PopoverBody className="kslplus-header-popover-body">
                                <Container>
                                    <div>
                                        {"Zusätzliche Jahre " + (this.state.alleJahre ? "verbergen" : "anzeigen")}
                                    </div>
                                </Container>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div >);

                },
                text: this.state.alleJahre ? "-" : "+",
                headerStyle: (colum: any, colIndex: any) => {
                    return { fontWeight: 900, width: '30px', textAlign: 'left' };
                },

                style: (colum: any, colIndex: any) => {
                    return { width: '30px' };
                },
                footer: -1,
                headerEvents: {
                    onClick: (e: any, column: any, columnIndex: any) => { this.setState({ alleJahre: !this.state.alleJahre }) }
                }
            });
        }

        var id = 3;

        if (ausgaben) {
            var fieldPrev: string | undefined;
            var sumPrev = 0;


            if (jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_soll != null /*&& totalSum.ergebnisPJM4_soll != 0*/ && data.ergebnisPJM4_haben != null && data.ergebnisPJM4_soll != null /*&& data.ergebnisPJM4_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM4_haben != null && totalHaben.ergebnisPJM4_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_soll != undefined ? totalSub.ergebnisPJM4_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM4_haben != undefined ? totalHabenSub.ergebnisPJM4_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM4_soll", this.props.jahr - 4, totalSum.ergebnisPJM4_soll - subsoll, totalHaben.ergebnisPJM4_haben - totalSum.ergebnisPJM4_soll - subErgebnis + subsoll, id, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_soll", sumPrev, totalSum.ergebnisPJM4_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4_soll";
                //sumPrev = totalSum.ergebnisPJM4_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }


            if (jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_haben != null && totalSum.ergebnisPJM3_soll != null /*&& totalSum.ergebnisPJM3_soll != 0*/ && data.ergebnisPJM3_haben != null && data.ergebnisPJM3_soll != null /*&& data.ergebnisPJM3_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM3_haben != null && totalHaben.ergebnisPJM3_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_soll != undefined ? totalSub.ergebnisPJM3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM3_haben != undefined ? totalHabenSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM3_soll", this.props.jahr - 3, totalSum.ergebnisPJM3_soll - subsoll, totalHaben.ergebnisPJM3_haben - totalSum.ergebnisPJM3_soll - subErgebnis + subsoll, id, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_soll", sumPrev, totalSum.ergebnisPJM3_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3_soll";
                //sumPrev = totalSum.ergebnisPJM3_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_haben != null && totalSum.ergebnisPJM2_soll != null /*&& totalSum.ergebnisPJM2_soll != 0*/ && data.ergebnisPJM2_haben != null && data.ergebnisPJM2_soll != null /*&& data.ergebnisPJM2_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM2_haben != null && totalHaben.ergebnisPJM2_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_soll != undefined ? totalSub.ergebnisPJM2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM2_haben != undefined ? totalHabenSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM2_soll", this.props.jahr - 2, totalSum.ergebnisPJM2_soll - subsoll, totalHaben.ergebnisPJM2_haben - totalSum.ergebnisPJM2_soll - subErgebnis + subsoll, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_soll", sumPrev, totalSum.ergebnisPJM2_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM2_soll";
                //sumPrev = totalSum.ergebnisPJM2_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_haben != null && totalSum.ergebnisPJM1_soll != null /*&& totalSum.ergebnisPJM1_soll != 0*/ && data.ergebnisPJM1_haben != null && data.ergebnisPJM1_soll != null /*&& data.ergebnisPJM1_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM1_haben != null && totalHaben.ergebnisPJM1_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_soll != undefined ? totalSub.ergebnisPJM1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM1_haben != undefined ? totalHabenSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM1_soll", this.props.jahr - 1, totalSum.ergebnisPJM1_soll - subsoll, totalHaben.ergebnisPJM1_haben - totalSum.ergebnisPJM1_soll - subErgebnis + subsoll, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_soll", sumPrev, totalSum.ergebnisPJM1_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1_soll";
                //sumPrev = totalSum.ergebnisPJM1_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_haben != null && totalSum.ansatzPJfort_soll != null /*&& totalSum.ansatzPJfort_soll != 0*/ && data.ansatzPJfort_haben != null && data.ansatzPJfort_soll != null /*&& data.ansatzPJfort_soll != 0*/ && totalHaben != null && totalHaben.ansatzPJfort_haben != null && totalHaben.ansatzPJfort_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_soll != undefined ? totalSub.ansatzPJfort_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPJfort_haben != undefined ? totalHabenSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnUH("ansatzPJfort_soll", this.props.jahr, totalSum.ansatzPJfort_soll - subsoll, totalHaben.ansatzPJfort_haben - totalSum.ansatzPJfort_soll - subErgebnis + subsoll, id, "Gesamtsoll", false, false, false, "", "", false, true));
                id++;

                fieldPrev = "ansatzPJfort_soll";
                sumPrev = totalSum.ansatzPJfort_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegt_haben != null && totalSum.verfuegt_soll != null /*&& totalSum.ergebnisPJ_soll != 0*/ && data.verfuegt_haben != null && data.verfuegt_soll != null /*&& data.ergebnisPJ_soll != 0*/ && totalHaben != null && totalHaben.verfuegt_haben != null && totalHaben.verfuegt_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_haben != undefined ? totalSub.verfuegt_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_soll != undefined ? totalSub.verfuegt_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.verfuegt_haben != undefined ? totalHabenSub.verfuegt_haben : 0);                
                columns.push(this.createDataColumnUH("verfuegt_soll", this.props.jahr, totalSum.verfuegt_soll - subsoll, totalHaben.verfuegt_haben - totalSum.verfuegt_soll - subErgebnis + subsoll, id, "Ausgeführt", false, false, false, "", "", false, false, true));
                id++;

                fieldPrev = "verfuegt_soll";
                //sumPrev = totalSum.verfuegt_soll - subsoll;

                columns.push(this.createDataPercentColumn("verfuegt_soll", "ansatzPJfort_soll", this.props.jahr, id, "Ausgeführt", this.state.alleJahre, false, false, totalSum.verfuegt_soll - subsoll, sumPrev));
                id++;
                fieldPrev = undefined;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegbar_haben != null && totalSum.verfuegbar_soll != null /*&& totalSum.ergebnisPJ_soll != 0*/ && data.verfuegbar_haben != null && data.verfuegbar_soll != null /*&& data.ergebnisPJ_soll != 0*/ && totalHaben != null && totalHaben.verfuegbar_haben != null && totalHaben.verfuegbar_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_haben != undefined ? totalSub.verfuegbar_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_haben != undefined ? totalSub.verfuegbar_haben : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.verfuegbar_soll != undefined ? totalHabenSub.verfuegbar_soll : 0);
                columns.push(this.createDataColumnUH("verfuegbar_soll", this.props.jahr, totalSum.verfuegbar_soll - subsoll, totalHaben.verfuegbar_haben - totalSum.verfuegbar_soll - subErgebnis + subsoll, id, "Verfügbar", false, false, false));
                id++;

                fieldPrev = "verfuegbar_soll";
                //sumPrev = totalSum.verfuegbar_soll - subsoll;

                columns.push(this.createDataPercentColumn("verfuegbar_soll", "ansatzPJfort_soll", this.props.jahr, id, "Verfügbar", this.state.alleJahre, false, false, totalSum.verfuegbar_soll - subsoll, sumPrev));
                id++;
                fieldPrev = undefined;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progAuto_haben != null && totalSum.progAuto_soll != null /*&& totalSum.ergebnisPJ_soll != 0*/ && data.progAuto_haben != null && data.progAuto_soll != null /*&& data.ergebnisPJ_soll != 0*/ && totalHaben != null && totalHaben.progAuto_haben != null && totalHaben.progAuto_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progAuto_haben != undefined ? totalSub.progAuto_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.progAuto_soll != undefined ? totalSub.progAuto_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.progAuto_haben != undefined ? totalHabenSub.progAuto_haben : 0);
                columns.push(this.createDataColumnUH("progAuto_soll", this.props.jahr, totalSum.progAuto_soll - subsoll, totalHaben.progAuto_haben - totalSum.progAuto_soll - subErgebnis + subsoll, id, "Prognose errechnet", false, false, false, "", "", true));
                id++;

                fieldPrev = "progAuto_soll";
                //sumPrev = totalSum.progAuto_soll - subsoll;

                columns.push(this.createChangeColumn("ansatzPJfort_soll", "progAuto_soll", sumPrev, totalSum.progAuto_soll - subsoll, true, id, true, "Gesamtsoll"));

                id++;

            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progMan_haben != null && totalSum.progMan_soll != null /*&& totalSum.ergebnisPJ_soll != 0*/ && data.progMan_haben != null && data.progMan_soll != null /*&& data.ergebnisPJ_soll != 0*/ && totalHaben != null && totalHaben.progMan_haben != null && totalHaben.progMan_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progMan_haben != undefined ? totalSub.progMan_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.progMan_soll != undefined ? totalSub.progMan_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.progMan_haben != undefined ? totalHabenSub.progMan_haben : 0);
                columns.push(this.createDataColumnUH("progMan_soll", this.props.jahr, totalSum.progMan_soll - subsoll, totalHaben.progMan_haben - totalSum.progMan_soll - subErgebnis + subsoll, id, "Prognose manuell", false, false, false, "hasProgMan", "progKomm"));
                id++;

                fieldPrev = "progMan_soll";
                //sumPrev = totalSum.progMan_soll - subsoll;

                columns.push(this.createChangeColumn("ansatzPJfort_soll", "progMan_soll", sumPrev, totalSum.progMan_soll - subsoll, true, id, true, "Gesamtsoll"));

                id++;

            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.istAo_haben != null && totalSum.istAo_soll != null && totalSum.istAo_soll != 0 && data.istAo_haben != null && data.istAo_soll != null && data.istAo_soll != 0 && totalHaben != null && totalHaben.istAo_haben != null && totalHaben.istAo_haben !== undefined) {            
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.istAo_haben != undefined ? totalSub.istAo_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.istAo_soll != undefined ? totalSub.istAo_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.istAo_haben != undefined ? totalHabenSub.istAo_haben : 0);
                columns.push(this.createDataColumnUH("istAo_soll", this.props.jahr, totalSum.istAo_soll - subsoll, totalHaben.istAo_haben - totalSum.istAo_soll - subErgebnis + subsoll, id, "Ist AO", false, false, false));
                id++;

                fieldPrev = "istAo_soll";
                sumPrev = totalSum.istAo_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

        }
        else {
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            if (jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_soll != null && totalSum.ergebnisPJM4_haben != null /*&& totalSum.ergebnisPJM4_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM4_haben", this.props.jahr - 4, totalSum.ergebnisPJM4_haben - subhaben, undefined, id, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_haben", sumPrev, totalSum.ergebnisPJM4_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4_haben";
                //sumPrev = totalSum.ergebnisPJM4_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_soll != null && totalSum.ergebnisPJM3_haben != null /*&& totalSum.ergebnisPJM3_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM3_haben", this.props.jahr - 3, totalSum.ergebnisPJM3_haben - subhaben, undefined, id, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_haben", sumPrev, totalSum.ergebnisPJM3_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3_haben";
                //sumPrev = totalSum.ergebnisPJM3_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_soll != null && totalSum.ergebnisPJM2_haben != null /*&& totalSum.ergebnisPJM2_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM2_haben", this.props.jahr - 2, totalSum.ergebnisPJM2_haben - subhaben, undefined, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_haben", sumPrev, totalSum.ergebnisPJM2_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM2_haben";
                //sumPrev = totalSum.ergebnisPJM2_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_soll != null && totalSum.ergebnisPJM1_haben != null /*&& totalSum.ergebnisPJM1_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM1_haben", this.props.jahr - 1, totalSum.ergebnisPJM1_haben - subhaben, undefined, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_haben", sumPrev, totalSum.ergebnisPJM1_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1_haben";
                //sumPrev = totalSum.ergebnisPJM1_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_soll != null && totalSum.ansatzPJfort_haben != null /*&& totalSum.ansatzPJfort_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnUH("ansatzPJfort_haben", this.props.jahr, totalSum.ansatzPJfort_haben - subhaben, undefined, id, "Gesamtsoll", false, false, false, "", "", false, true));
                id++;
                fieldPrev = "ansatzPJfort_haben";
                sumPrev = totalSum.ansatzPJfort_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }



            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegt_haben != null /*&& totalSum.verfuegt_haben != 0*/) {            
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_haben != undefined ? totalSub.verfuegt_haben : 0);
                columns.push(this.createDataColumnUH("verfuegt_haben", this.props.jahr, totalSum.verfuegt_haben - subhaben, undefined, id, "Eingenommen", false, false, false, "", "", false, false, true));
                id++;
                fieldPrev = "verfuegt_haben";
                //sumPrev = totalSum.verfuegt_haben - subhaben;

                columns.push(this.createDataPercentColumn("verfuegt_haben", "ansatzPJfort_haben", this.props.jahr, id, "Eingenommen", this.state.alleJahre, false, false, totalSum.verfuegt_haben - subhaben, sumPrev ));
                id++;
                fieldPrev = undefined;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegbar_haben != null /*&& totalSum.verfuegbar_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_haben != undefined ? totalSub.verfuegbar_haben : 0);
                columns.push(this.createDataColumnUH("verfuegbar_haben", this.props.jahr, totalSum.verfuegbar_haben - subhaben, undefined, id, "Einzunehmen", false, false, false));
                id++;
                fieldPrev = "verfuegbar_haben";
                //sumPrev = totalSum.verfuegbar_haben - subhaben;

                columns.push(this.createDataPercentColumn("verfuegbar_haben", "ansatzPJfort_haben", this.props.jahr, id, "Einzunehmen", this.state.alleJahre, false, false, totalSum.verfuegbar_haben - subhaben, sumPrev ));
                id++;
                fieldPrev = undefined;
            }
            else {
                fieldPrev = undefined;
            }

            
            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progAuto_haben != null /*&& totalSum.progAuto_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progAuto_haben != undefined ? totalSub.progAuto_haben : 0);
                columns.push(this.createDataColumnUH("progAuto_haben", this.props.jahr, totalSum.progAuto_haben - subhaben, undefined, id, "Prognose errechnet", false, false, false, "", "", true));
                id++;
                fieldPrev = "progAuto_haben";
                //sumPrev = totalSum.progAuto_haben - subhaben;

                columns.push(this.createChangeColumn("ansatzPJfort_haben", "progAuto_haben", sumPrev, totalSum.progAuto_haben - subhaben, false, id, true, "Gesamtsoll"));

                id++;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progMan_haben != null /*&& totalSum.progMan_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progMan_haben != undefined ? totalSub.progMan_haben : 0);
                columns.push(this.createDataColumnUH("progMan_haben", this.props.jahr, totalSum.progMan_haben - subhaben, undefined, id, "Prognose manuell", false, false, false, "hasProgMan", "progKomm"));
                id++;
                fieldPrev = "progMan_haben";
                //sumPrev = totalSum.progMan_haben - subhaben;

                columns.push(this.createChangeColumn("ansatzPJfort_haben", "progMan_haben", sumPrev, totalSum.progMan_haben - subhaben, false, id, true, "Gesamtsoll"));

                id++;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.istAo_haben != null && totalSum.istAo_haben != 0 && totalSum.istAo_soll != 0 ) {            
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.istAo_haben != undefined ? totalSub.istAo_haben : 0);
                columns.push(this.createDataColumnUH("istAo_haben", this.props.jahr, totalSum.istAo_haben - subhaben, undefined, id, "Ist AO"));
                id++;

            }
            else {
                fieldPrev = undefined;
            }

        }
        return columns;
    }

    createColumns = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum: KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben: KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean, showFooter : boolean = true, jahre: number[] = []) => {
        if ( this.props.plantyp == 3 )
            return this.createColumnsJR(data, totalSum, totalSub, totalHaben, totalHabenSub, ausgaben, showFooter, jahre);
        if (this.props.plantyp == 4)
            return this.createColumnsUH(data, totalSum, totalSub, totalHaben, totalHabenSub, ausgaben, showFooter, jahre);

        var columns = [];
        
        if ( data && data.unterEbenen && !data.unterEbenen.find(e => e.unterEbenen) ) {        
            columns.push({            
                dataField: 'df1',
                text: "",
                isDummyField: true,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                footer: showFooter ? "1" : undefined,
                
            });
        }
        columns.push({            
            dataField: 'bezeichnung',
            text: ausgaben ? "Ausgaben/Bezeichnung" : "Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsEbene) => {
                //var label = "";
                var label: JSX.Element[] = [];
                var tooltip = "";
                var tooltipMn = "";
                if (row.unterEbenen) {
                    //label = row.untergruppe !== undefined && row.ugStellen !== undefined ? Utilities.Num2Str(row.untergruppe, row.ugStellen) + " - " : "";
                    if (row.untergruppe !== undefined && row.ugStellen !== undefined)
                        label.push(<span key={"kslplus-label-" + row.id + "-ug"}>{Utilities.Num2Str(row.untergruppe, row.ugStellen) + " - "}</span>);
                    
                }
                else {
                    /*
                    if (row.unterabschnitt !== undefined && row.uaStellen !== undefined)
                        label += Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + ".";
                    if (row.massnahme !== null && row.massnahme !== undefined)
                        label += Utilities.Num2Str(row.massnahme, 3) + ".";
                    if (row.unterkonto !== undefined && row.ugStellen !== undefined)
                        label += Utilities.Num2Str(row.untergruppe, row.ugStellen);
                    if (row.unterkonto)
                        label += "." + Utilities.Num2Str(row.unterkonto, 2);

                    label += " - ";
                    */
                    if ( row.unterabschnitt !== undefined && row.uaStellen )
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id} key={"kslplus-label-" + row.id}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen)}</span>);
                    if (row.massnahme !== null && row.massnahme !== undefined) {
                        var mn = this.props.massnahmen.find(m => m.nr == row.massnahme && m.unterabschnitt == row.unterabschnitt && m.untergruppe == row.untergruppe);
                        if (mn && mn.bezeichnung)
                            tooltipMn = mn.bezeichnung;
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id + "mn"} key={"kslplus-label-" + row.id + "mn"}>{(label.length > 0 ? "." : "") +  Utilities.Num2Str(row.massnahme, 3)}</span>);
                    }
                    if ( row.untergruppe !== undefined && row.ugStellen )                        
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ug"}>{(label.length > 0 ? "." : "") +  Utilities.Num2Str(row.untergruppe, row.ugStellen)}</span>);
                    if (row.unterkonto)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uk"}>{(label.length > 0 ? "." : "") +  Utilities.Num2Str(row.unterkonto, 3)}</span>);
                        
                    label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "sep"}> - </span>);
                    tooltip = row.uaText?row.uaText:"";
                }
                //label = label + (row.ugText ? row.ugText : cellContent);
                label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ugtext"}>{row.ugText ? row.ugText : cellContent}</span>);
                if (row.isHHS)
                    return (                 
                        <div>
                            <div className="kslplus-tabellen-bezeichnung" >{label} {row.isHHS && row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                            {tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null}                        
                        </div>
                );
                else
                    return(
                        <div>
                            <div className="kslplus-tabellen-bezeichnung" >{label} {row.toolTip != null && row.toolTip.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}><div className="kslplus-tooltip">{row.toolTip}</div></UncontrolledTooltip></div> : null}</div>{ tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null }
                                            { tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null }
                                        </div >
                );

            },
            style: (colum: any, colIndex: any) => {
                if ( !showFooter )                
                    return { width: '250px', paddingLeft: "20px" };
                return { width: '250px' };
            },
            footer: showFooter ? "2" : undefined,
            footerFormatter: (column: any, columnIndex: any) => {
                if (ausgaben)
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);

                return <div>Einnahmen gesamt</div>;
            }
        });

        if (jahre.length == 0 && data && data.jahre)
            jahre = data.jahre;

        if (data == null)
            return columns;

        var id = 3;
        if (ausgaben) {
            var fieldPrev: string|undefined;
            var sumPrev = 0;
            /*
            if (totalSum.ergebnisVvvJ_haben != null && totalSum.ergebnisVvvJ_soll != null && data.ergebnisVvvJ_haben != null && totalHaben != null && totalHaben.ergebnisVvvJ_haben != null && totalHaben.ergebnisVvvJ_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_haben != undefined ? totalSub.ergebnisVvvJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_soll != undefined ? totalSub.ergebnisVvvJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisVvvJ_haben != undefined ? totalHabenSub.ergebnisVvvJ_haben : 0);
                columns.push(this.createDataColumn("ergebnisVvvJ_soll", this.props.jahr - 3, totalSum.ergebnisVvvJ_soll - subsoll, totalHaben.ergebnisVvvJ_haben - totalSum.ergebnisVvvJ_soll - subErgebnis + subsoll, id, showFooter));
                id++;
            }*/
            

            

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisVvJ_haben != null && totalSum.ergebnisVvJ_soll != null && data.ergebnisVvJ_haben != null && data.ergebnisVvJ_soll != null && totalHaben != null && totalHaben.ergebnisVvJ_haben != null && totalHaben.ergebnisVvJ_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_haben != undefined ? totalSub.ergebnisVvJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_soll != undefined ? totalSub.ergebnisVvJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisVvJ_haben != undefined ? totalHabenSub.ergebnisVvJ_haben : 0);
                
                columns.push(this.createDataColumn("ergebnisVvJ_soll", this.props.jahr - 2, totalSum.ergebnisVvJ_soll - subsoll, totalHaben.ergebnisVvJ_haben - totalSum.ergebnisVvJ_soll - subErgebnis + subsoll, id, showFooter));
                id++
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ_soll", sumPrev, totalSum.ergebnisVvJ_soll - subsoll, true, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisVvJ_soll";
                sumPrev = totalSum.ergebnisVvJ_soll - subsoll;                
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ansatzVJ_haben != null && totalSum.ansatzVJ_soll != null && data.ansatzVJ_haben != null && data.ansatzVJ_soll != null && totalHaben != null && totalHaben.ansatzVJ_haben != null && totalHaben.ansatzVJ_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_haben != undefined ? totalSub.ansatzVJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_soll != undefined ? totalSub.ansatzVJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzVJ_haben != undefined ? totalHabenSub.ansatzVJ_haben : 0);                
                columns.push(this.createDataColumn("ansatzVJ_soll", this.props.jahr - 1, totalSum.ansatzVJ_soll - subsoll, totalHaben.ansatzVJ_haben - totalSum.ansatzVJ_soll - subErgebnis + subsoll, id, showFooter));                
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ_soll", sumPrev, totalSum.ansatzVJ_soll - subsoll, true, id, showFooter));
                    id++;
                }                  
                fieldPrev = "ansatzVJ_soll";
                sumPrev = totalSum.ansatzVJ_soll - subsoll;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPj_haben != null && totalSum.ansatzPj_soll != null && data.ansatzPj_haben != null && data.ansatzPj_soll != null && totalHaben != null && totalHaben.ansatzPj_haben != null && totalHaben.ansatzPj_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_haben != undefined ? totalSub.ansatzPj_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_soll != undefined ? totalSub.ansatzPj_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPj_haben != undefined ? totalHabenSub.ansatzPj_haben : 0);                
                columns.push(this.createDataColumn("ansatzPj_soll", this.props.jahr, totalSum.ansatzPj_soll - subsoll, totalHaben.ansatzPj_haben - totalSum.ansatzPj_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj_soll", sumPrev, totalSum.ansatzPj_soll - subsoll, true, id, showFooter, "Vorjahr", true ));
                    id++;
                }                                               
                fieldPrev = "ansatzPj_soll";
                sumPrev = totalSum.ansatzPj_soll - subsoll;
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 1) >= 0 && totalSum.fp1_haben != null && totalSum.fp1_soll != null && data.fp1_haben != null && data.fp1_soll != null && totalHaben != null && totalHaben.fp1_haben != null && totalHaben.fp1_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp1_haben != undefined ? totalSub.fp1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp1_soll != undefined ? totalSub.fp1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp1_haben != undefined ? totalHabenSub.fp1_haben : 0);                
                columns.push(this.createDataColumn("fp1_soll", this.props.jahr + 1, totalSum.fp1_soll - subsoll, totalHaben.fp1_haben - totalSum.fp1_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1_soll", sumPrev, totalSum.fp1_soll - subsoll, true, id, showFooter, "Vorjahr", this.props.settings.doppelhaushalt ? true : false));
                    id++;
                }                
                fieldPrev = "fp1_soll";
                sumPrev = totalSum.fp1_soll - subsoll;
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 2) >= 0 && totalSum.fp2_haben != null && totalSum.fp2_soll != null && data.fp2_haben != null && data.fp2_soll != null && totalHaben != null && totalHaben.fp2_haben != null && totalHaben.fp2_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp2_haben != undefined ? totalSub.fp2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp2_soll != undefined ? totalSub.fp2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp2_haben != undefined ? totalHabenSub.fp2_haben : 0);                
                columns.push(this.createDataColumn("fp2_soll", this.props.jahr + 2, totalSum.fp2_soll - subsoll, totalHaben.fp2_haben - totalSum.fp2_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2_soll", sumPrev, totalSum.fp2_soll - subsoll, true, id, showFooter));
                    id++;
                }                
                fieldPrev = "fp2_soll";
                sumPrev = totalSum.fp2_soll - subsoll;
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 3) >= 0 && totalSum.fp3_haben != null && totalSum.fp3_soll != null && data.fp3_haben != null && data.fp3_soll != null && totalHaben != null && totalHaben.fp3_haben != null && totalHaben.fp3_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp3_haben != undefined ? totalSub.fp3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp3_soll != undefined ? totalSub.fp3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp3_haben != undefined ? totalHabenSub.fp3_haben : 0);                
                columns.push(this.createDataColumn("fp3_soll", this.props.jahr + 3, totalSum.fp3_soll - subsoll, totalHaben.fp3_haben - totalSum.fp3_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3_soll", sumPrev, totalSum.fp3_soll - subsoll, true, id, showFooter));
                    id++;
                }                
                fieldPrev = "fp3_soll";
                sumPrev = totalSum.fp3_soll - subsoll;
            }
            else
            {
                fieldPrev = undefined;
            }


        }
        else {
            var fieldPrev: string|undefined;
            var sumPrev = 0;
            /*
            if (totalSum.ergebnisVvvJ_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_haben != undefined ? totalSub.ergebnisVvvJ_haben : 0);
                columns.push(this.createDataColumn("ergebnisVvvJ_haben", this.props.jahr - 3, totalSum.ergebnisVvvJ_haben - subhaben, undefined, id, showFooter));
                id++;
            }*/
            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisVvJ_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_haben != undefined ? totalSub.ergebnisVvJ_haben : 0);                
                columns.push(this.createDataColumn("ergebnisVvJ_haben", this.props.jahr - 2, totalSum.ergebnisVvJ_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ_haben", sumPrev, totalSum.ergebnisVvJ_haben - subhaben, false, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisVvJ_haben";
                sumPrev = totalSum.ergebnisVvJ_haben - subhaben;
            }
            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ansatzVJ_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_haben != undefined ? totalSub.ansatzVJ_haben : 0);                
                columns.push(this.createDataColumn("ansatzVJ_haben", this.props.jahr - 1, totalSum.ansatzVJ_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ_haben", sumPrev, totalSum.ansatzVJ_haben - subhaben, false, id, showFooter));
                    id++;
                }                
                fieldPrev = "ansatzVJ_haben";
                sumPrev = totalSum.ansatzVJ_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }
            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPj_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_haben != undefined ? totalSub.ansatzPj_haben : 0);                
                columns.push(this.createDataColumn("ansatzPj_haben", this.props.jahr, totalSum.ansatzPj_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj_haben", sumPrev, totalSum.ansatzPj_haben - subhaben, false, id, showFooter, "Vorjahr", true));
                    id++;
                }   
                fieldPrev = "ansatzPj_haben";
                sumPrev = totalSum.ansatzPj_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 1) >= 0 && totalSum.fp1_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp1_haben != undefined ? totalSub.fp1_haben : 0);                
                columns.push(this.createDataColumn("fp1_haben", this.props.jahr + 1, totalSum.fp1_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1_haben", sumPrev, totalSum.fp1_haben - subhaben, false, id, showFooter, "Vorjahr", this.props.settings.doppelhaushalt ? true : false));
                    id++;
                }                
                fieldPrev = "fp1_haben";
                sumPrev = totalSum.fp1_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }
            if (jahre.findIndex(d => d == this.props.jahr + 2) >= 0 && totalSum.fp2_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp2_haben != undefined ? totalSub.fp2_haben : 0);                
                columns.push(this.createDataColumn("fp2_haben", this.props.jahr + 2, totalSum.fp2_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2_haben", sumPrev, totalSum.fp2_haben - subhaben, false, id, showFooter));
                    id++;
                }                
                fieldPrev = "fp2_haben";
                sumPrev = totalSum.fp2_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }
            if (jahre.findIndex(d => d == this.props.jahr + 3) >= 0 && totalSum.fp3_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp3_haben != undefined ? totalSub.fp3_haben : 0);                
                columns.push(this.createDataColumn("fp3_haben", this.props.jahr + 3, totalSum.fp3_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3_haben", sumPrev, totalSum.fp3_haben - subhaben, false, id, showFooter));
                    id++;
                }                
                fieldPrev = "fp3_haben";
                sumPrev = totalSum.fp3_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }


        }
        return columns;
    }

    readFiltered = (ebene: KSLPlusAPI.HaushaltsEbene) => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        if (this.props.isvmhh) {
            request.gruppen = [3, 9];
            request.ignorierteGruppen = [0, 1, 2, 4, 5, 6, 7, 8];
        }
        else {
            request.gruppen = [0, 1, 2, 4, 5, 6, 7, 8];
            request.ignorierteGruppen = [3, 9];
        }
        request.uaStellen = ebene.uaStellen;
        request.unterabschnitt = ebene.unterabschnitt;
        request.sonderUAEbene = ebene.sonderUAEbene;
        this.state.ebene = ebene;

        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31];
        client.getFilteredHaushaltByGruppierung("", request).then((hh) => {
            ebene.filter = hh;   
            
            this.setState({ ebene: ebene, expanded: [] });            
            
            if (this.state.suchText !== "" && hh.unterEbenen)
                hh.unterEbenen.forEach(z => this.expandBySuchtext(z));            
            
            this.setState({ expanded: this.state.expanded });          
            //this.setState({ ready: true });
        }).catch(Reason => {
        });

        this.readGrundzahlen(ebene);
        this.readKennzahlen(ebene);        
        
    }

    createHiddenRowKeys = (ebenen: KSLPlusAPI.HaushaltsEbene[], ausgaben: boolean ) => {
        var hidden : string[] = [];        
        for ( var i = 0; i < ebenen.length; i++ )
        {
            const ebene = ebenen[i];
            if ( ebene.untergruppe === undefined )
                continue;


            if ( ausgaben && !ebene.containsAusgaben )
            {
                hidden.push(ebene.id?ebene.id:"");
            }        
            else if ( !ausgaben && !ebene.containsEinnahmen )
            {   
                hidden.push(ebene.id ? ebene.id : "");
            }            
        }
        
        return hidden;
    }

    handleSelectHHEbene = (ebene: KSLPlusAPI.HaushaltsEbene, suchtext: string) => {           
        this.state.suchText = suchtext;
        this.setState({ ebene: undefined, suchText: suchtext });        
        if (!ebene.filter && !ebene.isHHS) {            
            this.readFiltered(ebene);
        }
        else {            
            setTimeout(() => { this.setState({ ebene: ebene }) }, 10);
            this.state.expanded = [];
            this.setState({ ebene: ebene, expanded: [] });

            if (this.state.suchText !== "" && ebene.filter && ebene.filter.unterEbenen)
                ebene.filter.unterEbenen.forEach(z => this.expandBySuchtext(z));

            this.setState({ expanded: this.state.expanded });          
            this.readGrundzahlen(ebene);
            this.readKennzahlen(ebene);
        }
        
        if ( ebene.id === this.props.hh.id || (this.props.hh.filter && ebene.id === this.props.hh.filter.id))
        {
            this.props.onSelChange("");
        }
        else if ( ebene.unterabschnitt !== undefined && ebene.uaStellen !== undefined )
        {
            const num = Utilities.Num2Str(ebene.unterabschnitt, ebene.uaStellen);
            this.props.onSelChange(num);
        }
    }

    toggleBeschreibung = (idx: number) => {        
        this.state.openBeschreibungen[idx] = !this.state.openBeschreibungen[idx];
        this.setState({ready: true});
    }
    createBeschreibungsItems = (texte: KSLPlusAPI.Texte[]|undefined) => { 
        var items: JSX.Element[] = [];
        
        
        if ( !texte )
            return items;

        var numInRow = texte.length > 4 || texte.length == 3 ? 3 : 2;
        
        for ( var i = 0; i < texte.length ; i = i + numInRow )
        {
            const idx = i / numInRow;            
            this.state.openBeschreibungen.push(false);
            var spanItems : JSX.Element[] = [];
            var colItems : JSX.Element[] = [];
            for ( var j = i; j < i+numInRow && j < texte.length; j++ )
            {
                spanItems.push(
                    <span className="kslplus-beschreibung-text" key={"bespan" + j.toString()}>{texte[j].bezeichnung}</span>
                );
                colItems.push(
                    <Col className="kslplus-beschreibung-content" key={"becol" + j.toString()}>
                            {texte[j].text}                                                      
                    </Col>
                );
            }
            items.push(
                <button
                          className={"kslplus-beschreibung-toggle" + (this.state.openBeschreibungen[idx]?" active":"")}
                                                      onClick={() => this.toggleBeschreibung(idx)}
                            key={"beb" + idx.toString()}
                        >
                          
                          {spanItems}
                          <div className="rotate90">
                            <svg
                              className={"kslplus-beschreibung-icon" + (this.state.openBeschreibungen[idx]?" expanded":"")}
                              viewBox="6 0 12 24"
                            >
                              <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12"  />
                            </svg>
                          </div>
                            
                        </button>
            );
            items.push(
                <Collapse
                            isOpen={this.state.openBeschreibungen[idx]}
                            className={"kslplus-beschreibung-collapse-gradient " + + (this.state.openBeschreibungen[idx]?" active":"")}
                            key={"bec" + idx.toString()}
                        >
                        <Row>   
                        {colItems}
                        </Row>
                </Collapse>
            );
        }

        return items;
        
    }

    findEbene = (selected: string, ebenen: KSLPlusAPI.HaushaltsEbene[]): KSLPlusAPI.HaushaltsEbene | undefined => {
        if (ebenen.length == 0)
            return undefined;

        var stellen = ebenen[0].uaStellen;

        if (stellen === undefined)
            return undefined;

        var num = selected;
        if (!ebenen[0].isHHS)
            num = selected.substring(0, stellen);

        var ebene = ebenen.find(e => e.unterabschnitt !== undefined && e.uaStellen !== undefined && Utilities.Num2Str(e.unterabschnitt, Math.abs(e.uaStellen)) === selected.substring(0, e.uaStellen) && e.uaStellen == selected.length);
        if (ebene)
            return ebene; 

        ebene = ebenen.find(e => e.unterabschnitt !== undefined && e.uaStellen !== undefined && Utilities.Num2Str(e.unterabschnitt, Math.abs(e.uaStellen)) === selected.substring(0, e.uaStellen));
                
        if ( ebene && ebene.unterEbenen && stellen < selected.length )
        {
            return this.findEbene(selected, ebene.unterEbenen);
        }
          
        return ebene;        
    }

    modifyTreeChart = (container: HTMLElement | null, chartWrapper: GoogleChartWrapper, data: GoogleDataTable | null, hoverrow: number = -1, mouseover: boolean = false) => {
        const colors = ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411'];


        if (!data || !container || chartWrapper.getChartType() != "TreeMap")
            return;



        // find existing / build new labels
        Array.prototype.forEach.call(container.getElementsByTagName('text'), (text) => {

            // nur elemente ohne added attribute sind orignal elemente
            if (text.getAttribute('text-anchor') === 'middle' && !text.getAttribute('added')) {

                var rect = text.parentNode.getElementsByTagName('rect')[0];

                var added = false;
                if (text.parentNode) {

                    text.parentNode.childNodes.forEach((c: any) => {
                        if (c.getAttribute("added")) {
                            added = true;
                        }
                    });
                }
                // exclude top node
                if (rect.getAttribute('fill') !== '#cccccc') {
                    // angezeigten Text ohne ... ermitteln
                    var val = text.textContent as string;
                    val = val.replace(/…+$/, "");

                    var color = colors[0];
                    var cVal = 0;
                    var found = false;
                    var rFound = -1;
                    for (var r = 0; r < data.getNumberOfRows(); r++) {
                        if ((data.getValue(r, 0) as string).startsWith(val)) {
                            rFound = r;
                            if (found) {    // wenn mehr als ein Eintrag gefunden wurden, wird nichts unternommen
                                found = false;
                                break;
                            }

                            found = true;
                        }

                    }
                    if (found) {
                        color = colors[data.getValue(rFound, 3) as number % 31];
                        cVal = data.getValue(rFound, 4) as number;
                    }
                    if (!added) {
                        rect.setAttribute('fill', color);
                        if (found) {
                            var textLabel = text.cloneNode()
                            textLabel.setAttribute('y', (parseFloat(text.getAttribute('y')) + parseFloat(text.getAttribute('font-size')) + 4).toString());
                            textLabel.textContent = Utilities.FormatCurrency(cVal, 1, 0, '.', ',') + "%";
                            textLabel.setAttribute('added', 'true');
                            text.parentNode.appendChild(textLabel);
                        }
                    }
                    else if (found && rFound == hoverrow) {    // Elemente wurden bereits hinzugefügt --> nur noch mouseover betrachten                        
                        Array.prototype.forEach.call(text.parentNode.getElementsByTagName('text'), (text) => {
                            text.setAttribute('fill', mouseover ? 'black' : 'white');
                        });
                    }

                }
            }

        });
    }

    createObserver = (container: HTMLElement, chartWrapper: GoogleChartWrapper, data: GoogleDataTable | null) => {
        var observer = new MutationObserver((record, observer) => {
            //observer.disconnect();
            this.modifyTreeChart(container, chartWrapper, data);
            /*
            if (container) {
                this.createObserver(container, chartWrapper, data);
            }
            */
        });
        observer.observe(container, {
            childList: true,
            subtree: true
        });
    }

    createChart = (data: KSLPlusAPI.HaushaltsEbene | null | undefined, jahr: number, gruppen: number[] | undefined, ausgaben: boolean) => {

        if (this.state.chartTyp == 0) {
            const options = {
                backgroundColor: 'none',
                is3D: this.props.settings.tD3d,
                pieSliceText: this.props.settings.tdText,
                legend: { position: this.props.settings.tdLegend, labeledValueText: this.props.settings.tdText == "value" ? "percentage" : "value" },
                pieSliceTextStyle: { fontSize: this.props.settings.tdFontSize != -1 ? this.props.settings.tdFontSize : undefined },
                sliceVisibilityThreshold: 0.00001
            };
            var pieData: any[][];
            if (this.props.plantyp == 3 || this.props.plantyp == 4)
                pieData = Utilities.createPieDataJR(data, jahr, gruppen, ausgaben, this.props.plantyp === 4);
            else
                pieData = Utilities.createPieData(data, jahr, gruppen, ausgaben, this.props.plantyp === 4);

            return (<div className="ksl-grafik-container">
                <Chart
                    width="100%"
                    height={'300px'}
                    chartType="PieChart"
                    chartLanguage='de'
                    loader={<div>Loading Chart</div>}
                    data={pieData}
                    options={options}
                    formatters={[
                        {
                            type: 'NumberFormat',
                            column: 1,
                            options: {
                                fractionDigits: 0,
                                suffix: ' TEUR'
                            },
                        }]}
                    rootProps={{ 'data-testid': '2' }}
                />
                <Col>

                    <ImgButton tttext="Tortendiagramm" id={"grafik-torte-" + jahr.toString()} className="ksl-grafik-toggle-button" type="piechart" onClick={() => {
                        this.setState({ chartTyp: 0 });
                    }} />
                    <ImgButton tttext="Balkendiagramm" id={"grafik-balken-" + jahr.toString()} className="ksl-grafik-toggle-button" type="barcharthor" onClick={() => {
                        this.setState({ chartTyp: 1 });
                    }} />
                    <ImgButton tttext="Flächendiagramm" id={"grafik-flaeche-" + jahr.toString()} className="ksl-grafik-toggle-button" type="aereachart" onClick={() => {
                        this.setState({ chartTyp: 2 });
                    }} />

                </Col>
            </div>);
        }
        else if (this.state.chartTyp == 1) {
            var barData: any[][] | undefined;
            if (this.props.plantyp == 3 || this.props.plantyp == 4)
                barData = Utilities.createBarHorzDataJR(data, jahr, gruppen, ausgaben, this.props.plantyp === 4);
            else
                barData = Utilities.createBarHorzData(data, jahr, gruppen, ausgaben, this.props.plantyp === 4);

            return (<div className="ksl-grafik-container">
                <Chart
                    width="100%"
                    height={'300px'}
                    chartType="BarChart"
                    chartLanguage='de'
                    loader={<div>Loading Chart</div>}
                    data={barData}
                    options={{
                        bar: { groupWidth: "50%" },
                        legend: { position: "none" },
                        chartArea: { width: '50%' }

                    }}
                    rootProps={{ 'data-testid': '2' }}
                />
                <Col>

                    <ImgButton tttext="Tortendiagramm" id={"grafik-torte-" + jahr.toString()} className="ksl-grafik-toggle-button" type="piechart" onClick={() => {
                        this.setState({ chartTyp: 0 });
                    }} />
                    <ImgButton tttext="Balkendiagramm" id={"grafik-balken-" + jahr.toString()} className="ksl-grafik-toggle-button" type="barcharthor" onClick={() => {
                        this.setState({ chartTyp: 1 });
                    }} />
                    <ImgButton tttext="Flächendiagramm" id={"grafik-flaeche-" + jahr.toString()} className="ksl-grafik-toggle-button" type="aereachart" onClick={() => {
                        this.setState({ chartTyp: 2 });
                    }} />

                </Col>
            </div>);

        }
        else if (this.state.chartTyp == 2) {

            const chartEvents: ReactGoogleChartEvent[] = [
                {
                    eventName: "ready",
                    callback: (eventCallbackArgs: {
                        chartWrapper: GoogleChartWrapper;
                        controlWrapper?: GoogleChartControl;
                        props: ReactGoogleChartProps;
                        google: GoogleViz;
                        eventArgs: any;
                    }) => {
                        var container = document.getElementById(eventCallbackArgs.chartWrapper.getContainerId());


                        if (container && eventCallbackArgs.chartWrapper.getChartType() == "TreeMap") {

                            setTimeout(() => { this.modifyTreeChart(container, eventCallbackArgs.chartWrapper, eventCallbackArgs.chartWrapper.getDataTable()) }, 1);
                            this.createObserver(container, eventCallbackArgs.chartWrapper, eventCallbackArgs.chartWrapper.getDataTable());
                        }
                        google.visualization.events.addListener(eventCallbackArgs.chartWrapper.getChart(), "onmouseover", (e: { row: number }) => {
                            this.modifyTreeChart(container, eventCallbackArgs.chartWrapper, eventCallbackArgs.chartWrapper.getDataTable(), e.row, true);
                        });
                        google.visualization.events.addListener(eventCallbackArgs.chartWrapper.getChart(), "onmouseout", (e: { row: number }) => {
                            this.modifyTreeChart(container, eventCallbackArgs.chartWrapper, eventCallbackArgs.chartWrapper.getDataTable(), e.row, false);
                        });
                    }
                }
            ];
            
            var treeMapData: any[][];
            if (this.props.plantyp == 3 || this.props.plantyp == 4)
                treeMapData = Utilities.createTreeMapDataJR(data, jahr, gruppen, ausgaben, this.props.plantyp === 4);
            else
                treeMapData = Utilities.createTreeMapData(data, jahr, gruppen, ausgaben, this.props.plantyp === 4);
                
            

            
            var treeData = google.visualization.arrayToDataTable(treeMapData);

            var options = {

                fontColor: "white",
                generateTooltip: (row: number, size: number, value: number) => {
                    return '<div class="kslplus-treemap-tooltip">' +
                        treeData.getValue(row, 0) + '<br/>' +
                        Utilities.FormatCurrency(treeData.getValue(row, 2), 2, 3, ".", ",") + " EUR (" +
                        Utilities.FormatCurrency(treeData.getValue(row, 4), 1, 0, ".", ",") + "%)" +
                        '</div>';
                }
            };

            return (<div className="ksl-grafik-container">
                <Chart
                    width="100%"
                    height={'300px'}
                    chartType="TreeMap"
                    chartLanguage='de'
                    loader={<div>Loading Chart</div>}
                    data={treeMapData}
                    options={options}
                    rootProps={{ 'data-testid': '2' }}
                    chartEvents={chartEvents}
                />
                <Col>

                    <ImgButton tttext="Tortendiagramm" id={"grafik-torte-" + jahr.toString()} className="ksl-grafik-toggle-button" type="piechart" onClick={() => {
                        this.setState({ chartTyp: 0 });
                    }} />
                    <ImgButton tttext="Balkendiagramm" id={"grafik-balken-" + jahr.toString()} className="ksl-grafik-toggle-button" type="barcharthor" onClick={() => {
                        this.setState({ chartTyp: 1 });
                    }} />
                    <ImgButton tttext="Flächendiagramm" id={"grafik-flaeche-" + jahr.toString()} className="ksl-grafik-toggle-button" type="aereachart" onClick={() => {
                        this.setState({ chartTyp: 2 });
                    }} />

                </Col>
            </div>);

        }
        return null;
    }


    getEbenenFilterOptions = () => {
        var items: { value: string, label: string }[] = [];

        this.state.grundzahlen.map(gz => {
            if (items.findIndex(i => i.value === gz.gzEbeneBez) == -1)
                items.push({ value: gz.gzEbeneBez ?? "", label: gz.gzEbeneBez ?? "" });
        });
        
        return items;
    }

    createGZColumns = (sets: KSLPlusAPI.DatensatzModel[]) => {
        var columns = [];



     

        columns.push(
            {
                dataField: 'gzEbeneBez',
                text: 'Typ',
                filter: selectFilter({
                    options: () => { return this.getEbenenFilterOptions() },
                    getFilter: (filter: any) => { this.getFilters.push(filter) }
                }),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '220px' };
                }
            }
        );

        columns.push(
            {
                dataField: 'bezeichnung',
                text: 'Bezeichnung',
                filter: textFilter({
                    getFilter: (filter: any) => { this.getFilters.push(filter) }
                }),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '220px' };
                }
            }
        );

        if (!this.props.hh.jahre || this.props.hh.jahre.length === 0 || sets.length === 0)
            return columns;

        var jMax = sets[0].datens ? (sets[0].datens[sets[0].datens.length - 1].x??0) : 0;
        var jMin = sets[0].datens ? (sets[0].datens[0].x??0) : 0;
        /*
        sets.forEach(s => {
            s.datens?.forEach(d => {
                if ((d?.x ?? 0) > jMax)
                    jMax = Math.min(d?.x ?? 0, this.props.jahr + 5);

                if ((d?.x ?? 0) < jMin)
                    jMin = Math.max(d?.x ?? 0, this.props.jahr - 5);

            });
        });
        */

        
        var idx = 0;
        for (var j = jMin; j <= jMax; j++) {
            const idx1 = idx;
            columns.push(
                {
                    index: idx,
                    dataField: 'datens[' + idx.toString() + '].y',
                    text: j.toString(),
                    headerStyle: (colum: any, colIndex: any) => {
                        return { width: '120px' };
                    },
                    formatter: (cellContent: string, row: KSLPlusAPI.DatensatzModel) => {

                        if (row.datens && idx1 < row.datens.length && row.datens[idx1].y !== undefined && row.datens[idx1].id)
                            return (
                                <div>{Utilities.FormatCurrency(row.datens[idx1].y, row.nachkommastellen ?? 2, 0, '.', ',')}</div>
                            );
                    }

                }
            );
            idx++;
        }



        return columns;
    };
    

    setGrundzahlenVisState = () => {

        this.setState({ gzopen: !this.state.gzopen });
        this.getFilters.forEach(fct => fct(""));
    };

    createGZItem = () => {
        if (this.state.grundzahlen.length === 0)
            return null;

        if (!this.props.hh.jahre || this.props.hh.jahre.length === 0)
            return null;

        var jMax = this.props.jahr - 6;
        var jMin = this.props.jahr + 6;
        var sets = this.state.grundzahlen;
        
        sets.forEach(s => {
            s.datens = s.datens?.sort((d1, d2) => (d1.x ?? 0) - (d2.x ?? 0)).filter(d => (d.x ?? 0) > this.props.jahr - 6 && (d.x ?? 0) < this.props.jahr + 6 );            
            
            s.datens?.forEach(d => {
                if ((d?.x ?? 0) > jMax)
                    jMax = d?.x??0;

                if ((d?.x ?? 0) < jMin)
                    jMin = d?.x ?? 0;

            });
        });
        
        
        sets.forEach(s => {
            for (var j = jMax; j >= jMin; j--) {
                if (s.datens?.find(d => d.x === j) === undefined) {
                    var nd = new KSLPlusAPI.Daten();
                    nd.x = j;
                    nd.y = 0;
                    s.datens?.push(nd);
                }

            }
            s.datens = s.datens?.sort((d1, d2) => (d1.x ?? 0) - (d2.x ?? 0)).filter(d => (d?.x ?? 0) >= jMin && (d?.x ?? 0) <= jMax);
        });
        
        this.state.grundzahlen = sets.filter(s => (s.datens?.length??0) > 0);

        if (this.state.grundzahlen.length === 0)
            return null;
        //return <BootstrapTable wrapperClasses="kslplus-gzebenen-data" keyField='id' data={this.state.grundzahlen} columns={this.createGZColumns(this.state.grundzahlen)} filter={filterFactory()} filterPosition="top" />;

        return (
            <Row className="kslplus-grafik-collaps-wrapper">
                <button
                    className={"kslplus-beschreibung-toggle" + (this.state.gzopen ? " active" : "")}
                    onClick={() => this.setGrundzahlenVisState()}>
                    <div className="kslplus-beschreibung-text">
                        <div className="kslplus-info-badge-container">
                            <Badge className="kslplus-info-badge" id={"kslplus-info-text-gz"}>i</Badge>
                            <UncontrolledPopover
                                size="sm"
                                placement="right"
                                target={"kslplus-info-text-gz"}
                                delay={{ show: 0, hide: 0 }}
                                trigger="hover"

                                className="kslplus-menu-popover1">
                                <PopoverBody className="kslplus-menu-popover-body1">
                                    {"Um die Grundzahlen zu sehen, bitte anklicken"}
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                        Grundzahlen
                    </div>
                    <div className="rotate90">
                        <svg
                            className={"kslplus-beschreibung-icon" + (this.state.gzopen ? " expanded" : "")}
                            viewBox="6 0 12 24"
                        >
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>

                </button>


                <Collapse
                    isOpen={this.state.gzopen}
                    className={"kslplus-collapse-gradient " + (this.state.gzopen ? " active" : "")}>


                    <BootstrapTable wrapperClasses="kslplus-gzebenen-data" keyField='id' data={this.state.grundzahlen} columns={this.createGZColumns(this.state.grundzahlen)} filter={filterFactory()} filterPosition="top" />

                </Collapse>
            </Row>

        );

    }

    isCollapsExpanded = (id: string) => {
        //return this.state.expandedCollaps.find(s => s === id) !== undefined;
        var o = this.state.expandedCollaps.find(s => s.name === id);
        if (o && o.expanded)
            return true;

        return false;
    }

    toggleCollaps = (id: string) => {
        var o = this.state.expandedCollaps.find(s => s.name === id);
        if (!o) {
            o = { name: id, expanded: false };
            this.state.expandedCollaps.push(o);
        }
        o.expanded = !o.expanded;        
        this.setState({ expandedCollaps: this.state.expandedCollaps });
    }

    createKennzahlItems = () => {
        var items: JSX.Element[] = [];

        var gl = "";
        if (this.state.ebene?.unterabschnitt !== undefined && this.state.ebene?.uaStellen)
            gl = Utilities.Num2Str(this.state.ebene?.unterabschnitt, this.state.ebene?.uaStellen);

        for (var i = 0; i < this.state.kennzahlen.length; i++) {
            
            const kennzahl = this.state.kennzahlen[i];
            const id = "Kennzahl_" + kennzahl.id + "_" + gl;            

            if (this.props.plantyp == 3 || this.props.plantyp == 4) {
                items.push(<Row className="kslplus-grafik-collaps-wrapper" key={id}>
                    <button
                        className={"kslplus-beschreibung-toggle" + (this.isCollapsExpanded(id) ? " active" : "")}
                        onClick={() => this.toggleCollaps(id)}
                    >

                        <div className="kslplus-beschreibung-text">
                            <div className="kslplus-info-badge-container">
                                <Badge className="kslplus-info-badge" id={"kslplus-info-text-" + id}>i</Badge>
                                <UncontrolledPopover
                                    size="sm"
                                    placement="right"
                                    target={"kslplus-info-text-" + id}
                                    delay={{ show: 0, hide: 0 }}
                                    trigger="hover"

                                    className="kslplus-menu-popover1">
                                    <PopoverBody className="kslplus-menu-popover-body1">
                                        {"Um die Kennzahl zu sehen, bitte anklicken"}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <span >{"Kennzahl / " + (kennzahl.bezeichnung != null ? kennzahl.bezeichnung : "")}</span>
                        </div>
                        <div className="rotate90">
                            <svg
                                className={"kslplus-beschreibung-icon" + (this.isCollapsExpanded(id) ? " expanded" : "")}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>

                    </button>
                    <Collapse
                        isOpen={this.isCollapsExpanded(id)}
                        className={"kslplus-collapse-gradient " + (this.isCollapsExpanded(id) ? " active" : "")}
                    >
                        <KennzahlJR produkt={gl} kennzahl={kennzahl} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} uhconfig={this.props.uhconfig} bilanz={false} />                        
                    </Collapse>
                </Row>
                );
            }
            else {
                items.push(<Row className="kslplus-grafik-collaps-wrapper" key={id}>
                    <button
                        className={"kslplus-beschreibung-toggle" + (this.isCollapsExpanded(id) ? " active" : "")}
                        onClick={() => this.toggleCollaps(id)}
                    >

                        <div className="kslplus-beschreibung-text">
                            <div className="kslplus-info-badge-container">
                                <Badge className="kslplus-info-badge" id={"kslplus-info-text-" + id}>i</Badge>
                                <UncontrolledPopover
                                    size="sm"
                                    placement="right"
                                    target={"kslplus-info-text-" + id}
                                    delay={{ show: 0, hide: 0 }}
                                    trigger="hover"

                                    className="kslplus-menu-popover1">
                                    <PopoverBody className="kslplus-menu-popover-body1">
                                        {"Um die Kennzahl zu sehen, bitte anklicken"}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <span >{"Kennzahl / " + (kennzahl.bezeichnung != null ? kennzahl.bezeichnung : "")}</span>
                        </div>
                        <div className="rotate90">
                            <svg
                                className={"kslplus-beschreibung-icon" + (this.isCollapsExpanded(id) ? " expanded" : "")}
                                viewBox="6 0 12 24"
                            >
                                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                            </svg>
                        </div>

                    </button>
                    <Collapse
                        isOpen={this.isCollapsExpanded(id)}
                        className={"kslplus-collapse-gradient " + (this.isCollapsExpanded(id) ? " active" : "")}
                    >
                        <Kennzahl produkt={gl} kennzahl={kennzahl} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} />                        
                    </Collapse>
                </Row>
                );
            }
        }

        return items;
    }


    

    render() {
        if (!this.props.isVisible)
            return null;

        const expandRowEinnahmen = {
            renderer: (row: KSLPlusAPI.HaushaltsEbene) => (
                row.unterEbenen ?
                    <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createColumns(row, row, null, null, null, false, false, this.props.hh.jahre ? this.props.hh.jahre : [])} hiddenRows={this.createHiddenRowKeys(row.unterEbenen, false)} expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen) ? expandRowEinnahmen : undefined} rowClasses={this.rowClasses} />
                    :
                    null

            ),
            className: 'kslplus-tabellen-expanded',
            showExpandColumn: true,
            expandColumnPosition: 'left',
            expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
                return null;
            },
            expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
                if (expanded) {
                    return (
                        <div className="kslplus-expand-column">(-)</div>
                    );
                }
                return (
                    <div className="kslplus-expand-column">(+)</div>
                );
            },
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand
        };

        const expandRowAusgaben = {
            renderer: (row: KSLPlusAPI.HaushaltsEbene) => (
                row.unterEbenen ?
                    <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createColumns(row, row, null, row, null, true, false, this.props.hh.jahre ? this.props.hh.jahre : [])} hiddenRows={this.createHiddenRowKeys(row.unterEbenen, true)} expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen) ? expandRowAusgaben : undefined} rowClasses={this.rowClasses} />
                    :
                    null


            ),
            className: 'kslplus-tabellen-expanded',
            showExpandColumn: true,
            expandColumnPosition: 'left',
            expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
                return null;
            },
            expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
                if (expanded) {
                    return (
                        <div className="kslplus-expand-column">(-)</div>
                    );
                }
                return (
                    <div className="kslplus-expand-column">(+)</div>
                );
            },
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand


        };

        var num = this.props.selected;
        /*
        var numSelected = "";
        if (this.state.ebene == this.props.hh)
            numSelected = "";
        else if (this.state.ebene && this.state.ebene.unterabschnitt !== undefined && this.state.ebene.uaStellen !== undefined)
            numSelected = Utilities.Num2Str(this.state.ebene.unterabschnitt, this.state.ebene.uaStellen);

        var num = this.props.selected;
        
        if (numSelected !== num && this.props.hh.unterEbenen) {
            var ebene = this.findEbene(this.props.selected, this.props.hh.unterEbenen);
            if (ebene ) {                
                this.readFiltered(ebene);
                alert(numSelected + " - " + num);
            }
        }
        */

        var barColors = ['#264d99', '#264d99', '#264d99', '#dc3912', '#dc3912', '#dc3912'];
        const einnahmen = this.state.ebene !== undefined && this.state.ebene.filter && this.state.ebene.filter.containsEinnahmen;
        const ausgaben = this.state.ebene !== undefined && this.state.ebene.filter && this.state.ebene.filter.containsAusgaben;

        var agr: { data: any[], colors: any[] } = { data: [], colors: [] };
        var egr: { data: any[], colors: any[] } = { data: [], colors: [] };

        var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
        var graphOptAGR: { minG: number | undefined, maxG: number | undefined, min0: number | undefined, max0: number | undefined, min1: number | undefined, max1: number | undefined } = { minG: undefined, maxG: undefined, min0: undefined, max0: undefined, min1: undefined, max1: undefined };
        var graphOptEGR: { minG: number | undefined, maxG: number | undefined, min0: number | undefined, max0: number | undefined, min1: number | undefined, max1: number | undefined } = { minG: undefined, maxG: undefined, min0: undefined, max0: undefined, min1: undefined, max1: undefined };

        var jahreAnz = this.state.ebene && this.state.ebene.filter && this.state.ebene.filter.jahre && this.state.ebene.filter.jahre.length ? this.state.ebene.filter.jahre.length : 3;
        var jahre = (this.state.ebene && this.state.ebene.filter && this.state.ebene.filter.jahre) ? this.state.ebene.filter.jahre : [];

        
        if (this.props.plantyp == 3 || this.props.plantyp == 4)
        {
            if (this.state.ebene !== undefined && this.state.ebene.filter && this.state.ebene.filter.containsAusgaben) {
                agr = Utilities.createBarDataJR(this.state.ebene.filter, [4, 5, 6, 7, 8], true, this.props.jahr, min, max, this.props.plantyp == 4);
                graphOptAGR = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
            }
            if (this.state.ebene !== undefined && this.state.ebene.filter && this.state.ebene.filter.containsEinnahmen) {
                egr = Utilities.createBarDataJR(this.state.ebene.filter, [0, 1, 2], false, this.props.jahr, min, max, this.props.plantyp == 4);
                graphOptEGR = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
            }
                
        }
        var graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);

        max = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        min = [Number.MAX_VALUE, Number.MAX_VALUE];
        var grdata: any[] = [];
        if (this.state.ebene && (!this.state.ebene.unterEbenen || (this.state.ebene.filter && this.state.ebene.filter.unterEbenen)))
            grdata = this.props.plantyp == 3 ? Utilities.createGraphDataJR(this.state.ebene.filter, this.state.ebene.filter, null, null, this.props.jahr, min, max) : (this.props.plantyp == 4 ? Utilities.createGraphDataUH(this.state.ebene.filter, this.state.ebene.filter, null, null, this.props.jahr, min, max) : Utilities.createGraphData(this.state.ebene.filter, this.state.ebene.filter, null, null, this.props.jahr, min, max));

        var graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);

        var options = {
            backgroundColor: 'none',
            is3D: this.props.settings.tD3d,
            pieSliceText: this.props.settings.tdText,
            legend: { position: this.props.settings.tdLegend, labeledValueText: this.props.settings.tdText == "value" ? "percentage" : "value" },
            pieSliceTextStyle: { fontSize: this.props.settings.tdFontSize != -1 ? this.props.settings.tdFontSize : undefined },
            sliceVisibilityThreshold: 0.00001                
        };

        /*    
        if ( this.state.ebene == this.props.hh )
            num = "";
        else if ( !num.length && this.state.ebene && this.state.ebene.unterabschnitt !== undefined && this.state.ebene.uaStellen !== undefined)
            num = Utilities.Num2Str(this.state.ebene.unterabschnitt, this.state.ebene.uaStellen);         
        */
        var beschreibungen = this.createBeschreibungsItems(this.state.ebene?this.state.ebene.texte:undefined); 
        return (    
            
            <div>

                {!this.props.hideNav ?                    
                        <HaushaltsNavTree hh={this.props.hh} onSelChange={this.handleSelectHHEbene} useUAText={true} showNumber={true} selected={num} typ={EbenenTyp.Gliederung} auswahlText="Auswahl nach EP/AB/UAB od. Suche per Eingabe" />                    
                    : null}
            
                <div className={!this.props.hideNav ? "kslplus-hhcomp-content" : ""}>  
            
                    <div className="kslplus-content">
                        {beschreibungen}
                        
                        

                        {
                            this.state.ebene && (!this.state.ebene.unterEbenen || (this.state.ebene.filter && this.state.ebene.filter.unterEbenen)) ?
                            <div>
                                    {this.state.alleJahre ?
                                        <Row>
                                            <div></div>
                                            <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                                {
                                                    /*
                                                    this.state.ebene && this.state.ebene.unterEbenen ?
                                                    <BootstrapTable ref={(n: any) => this.node = n} keyField='unterasbchnitt' data={this.state.ebene.unterEbenen} columns={this.createColumns(this.state.ebene, this.state.ebene, null, null, null, false)}  pagination={paginationFactory(paginationoptions)} /> :
                                                    null*/
                                                    this.state.ebene.filter && this.state.ebene.filter.unterEbenen ?
                                                        <BootstrapTable hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, this.state.ebene.filter, null, null, null, false)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen, false)} expandRow={expandRowEinnahmen} rowClasses={this.rowClasses} />
                                                        : null

                                                }
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                                {
                                                    /*
                                                    this.state.ebene && this.state.ebene.unterEbenen ?
                                                    <BootstrapTable ref={(n: any) => this.node = n} keyField='unterasbchnitt' data={this.state.ebene.unterEbenen} columns={this.createColumns(this.state.ebene, this.state.ebene, null, null, null, false)}  pagination={paginationFactory(paginationoptions)} /> :
                                                    null*/
                                                    this.state.ebene.filter && this.state.ebene.filter.unterEbenen ?
                                                        <BootstrapTable hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, this.state.ebene.filter, null, null, null, false)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen, false)} expandRow={expandRowEinnahmen} rowClasses={this.rowClasses} />
                                                        : null

                                                }
                                            </Col>
                                        </Row>
                                    }
                                    {this.state.alleJahre ?
                                        <Row>
                                            <div></div>
                                            <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                                {       /*
                                                    this.state.ebene && this.state.ebene.unterEbenen ?
                                                    <BootstrapTable ref={(n: any) => this.node = n} keyField='unterasbchnitt' data={this.state.ebene.unterEbenen} columns={this.createColumns(this.state.ebene, this.state.ebene, null, this.state.ebene, null, true)}  pagination={paginationFactory(paginationoptions)} /> :
                                                    null*/
                                                    this.state.ebene.filter && this.state.ebene.filter.unterEbenen ?
                                                        <BootstrapTable hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, this.state.ebene.filter, null, this.state.ebene.filter, null, true)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen, true)} expandRow={expandRowAusgaben} rowClasses={this.rowClasses} />
                                                        : null

                                                }
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                                {       /*
                                                    this.state.ebene && this.state.ebene.unterEbenen ?
                                                    <BootstrapTable ref={(n: any) => this.node = n} keyField='unterasbchnitt' data={this.state.ebene.unterEbenen} columns={this.createColumns(this.state.ebene, this.state.ebene, null, this.state.ebene, null, true)}  pagination={paginationFactory(paginationoptions)} /> :
                                                    null*/
                                                    this.state.ebene.filter && this.state.ebene.filter.unterEbenen ?
                                                        <BootstrapTable hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, this.state.ebene.filter, null, this.state.ebene.filter, null, true)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen, true)} expandRow={expandRowAusgaben} rowClasses={this.rowClasses} />
                                                        : null

                                                }
                                            </Col>
                                        </Row>
                                    }
                                {this.props.plantyp == 3 ?
                                    <Row>
                                        <Col>                                
                                            <div className="kslplus-headline-third">{"Einnnahmen und Ausgaben des " +  (this.props.isvmhh ? "VMHH " : "VWHH ") + this.props.jahr.toString() + " - Abweichungen zwischen Ansatz und Ergebnis"}</div>                                                                                
                                        </Col>                                                
                                    </Row>:null
                                }                         
                                {this.props.plantyp == 3 ?       
                                    <Row>
                                            <Col>
                                                <div className="ksl-grafik-container">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createBarDataAEAJR(this.state.ebene.filter, null, null, barColors)}                                                    
                                                        options={{
                                                          
                                                              bar: { groupWidth: "50%" },
                                                              legend: { position: "none"},
                                                              chartArea: {width: '80%', left: '20%'},
                                                              hAxis: { gridlines: { count: 10 }}
                                                        
                                                        }}
                                                    
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </Col>
                                    </Row>
                                    : null
                                }

                                {this.props.plantyp == 4 ?
                                    <Row>
                                        <Col>
                                                <div className="kslplus-headline-third">{"Einnnahmen und Ausgaben des " + (this.props.isvmhh ? "VMHH " : "VWHH ") + this.props.jahr.toString() + " - Abweichungen zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell)"}</div>
                                        </Col>
                                    </Row> : null
                                }
                                {this.props.plantyp == 4 ?
                                    <Row>
                                        <Col>
                                            <div className="ksl-grafik-container">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataAEAUH(this.state.ebene.filter, null, null, barColors)}
                                                    options={{

                                                        bar: { groupWidth: "50%" },
                                                        legend: { position: "none" },
                                                        chartArea: { width: '80%', left: '20%' },
                                                        hAxis: { gridlines: { count: 10 } }

                                                    }}

                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    : null
                                }
                                    
                                {this.props.plantyp == 3 && !this.props.isvmhh  && einnahmen ?
                                    <Row>
                                        <Col >
                                            <div className="kslplus-headline-third">{"Abweichungen der Einnahmen zwischen Ansatz und Ergebnis nach Hauptgruppen"}</div>
                                            <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'                                                            
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createAenderungsBarDataJR(this.state.ebene.filter, [0,1,2], false )}
                                                    options={{                                                                                                      
                                                        legend: { position: "none"},
                                                        chartArea: {width: '70%', left:'30%' },
                                                        hAxis: { gridlines: { count: 10 }}
                                                    }}
                                                    formatters={[
                                                                {
                                                                    type: 'NumberFormat',
                                                                    column: 1,
                                                                    options: {
                                                                    suffix: ' EUR'
                                                                    },
                                                                }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                                </div>
                                        </Col>
                                    </Row>:null
                                }
                                {this.props.plantyp == 3 && !this.props.isvmhh  && ausgaben ?
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{"Abweichungen der Ausgaben zwischen Ansatz und Ergebnis nach Hauptgruppen"}</div>
                                            <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'                                                            
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createAenderungsBarDataJR(this.state.ebene.filter, [4,5,6,7,8], true )}
                                                    options={{                                                                                                       
                                                        legend: { position: "none"},
                                                        chartArea: {width: '70%', left:'30%' },
                                                        hAxis: { gridlines: { count: 10 }}
                                                    }}
                                                    formatters={[
                                                                {
                                                                    type: 'NumberFormat',
                                                                    column: 1,
                                                                    options: {
                                                                    suffix: ' EUR'
                                                                    },
                                                                }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                                </div>
                                        </Col>
                                    </Row>: null
                                    }


                                    {this.props.plantyp == 4 && !this.props.isvmhh && einnahmen ?
                                        <Row>
                                            <Col >
                                                <div className="kslplus-headline-third">{"Abweichungen der Einnahmen zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell) nach Hauptgruppen"}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createAenderungsBarDataUH(this.state.ebene.filter, [0, 1, 2], false)}
                                                        options={{
                                                            legend: { position: "none" },
                                                            chartArea: { width: '70%', left: '30%' },
                                                            hAxis: { gridlines: { count: 10 } }
                                                        }}
                                                        formatters={[
                                                            {
                                                                type: 'NumberFormat',
                                                                column: 1,
                                                                options: {
                                                                    suffix: ' EUR'
                                                                },
                                                            }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row> : null
                                    }
                                    {this.props.plantyp == 4 && !this.props.isvmhh && ausgaben ?
                                        <Row>
                                            <Col>
                                                <div className="kslplus-headline-third">{"Abweichungen der Ausgaben zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell) nach Hauptgruppen"}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createAenderungsBarDataUH(this.state.ebene.filter, [4, 5, 6, 7, 8], true)}
                                                        options={{
                                                            legend: { position: "none" },
                                                            chartArea: { width: '70%', left: '30%' },
                                                            hAxis: { gridlines: { count: 10 } }
                                                        }}
                                                        formatters={[
                                                            {
                                                                type: 'NumberFormat',
                                                                column: 1,
                                                                options: {
                                                                    suffix: ' EUR'
                                                                },
                                                            }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row> : null
                                    }
                                    
                                    
                                    
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{this.props.plantyp == 3 ? "Entwicklung der Ergebnisse der Einnahmen und Ausgaben des " + (this.props.isvmhh ? "VMHH" : "VWHH") : "Entwicklung der Einnahmen und Ausgaben des " + (this.props.isvmhh ? "VMHH" : "VWHH")}</div>                                                                                                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ksl-grafik-container">
                                        
                                            
                                        
                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="ComboChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={grdata}
                                                    options={{
                                                        colors: ['#264d99', '#dc3912', '#ADD8E6', '#FF7F7F'],
                                                        vAxes: { 0: { title: 'EUR', minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: '%', minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max1, min: graphOpt.min1 } } },
                                                        hAxis: { title: 'Jahr' },
                                                        seriesType: 'bars',
                                                        series: { 0: { targetAxisIndex: 0 }, 1: { targetAxisIndex: 0 }, 2: { type: 'line', targetAxisIndex: 1 }, 3: { type: 'line', targetAxisIndex: 1 } },
                                                    }}
                                                    formatters={Utilities.createFormatters(4, 2)}
                                                    rootProps={{ 'data-testid': '1' }}
                                                />
                                            </div>
                                        </Col>
                                        </Row> 
                                        {einnahmen && !this.props.isvmhh &&                                                                              
                                        <div>
                                            <Row>
                                                <Col>
                                                    <div className="kslplus-headline-third">{this.props.plantyp == 3 ?"Entwicklung der Ergebnisse der Einnahmen nach Hauptgruppen":"Entwicklung der Einnahmen des VWHH nach Hauptgruppen"}</div>                                                    
                                                </Col>
                                            </Row>

                                            {this.props.plantyp == 3 || this.props.plantyp == 4 && jahreAnz > 3 ?
                                                <div>
                                                    <Row>
                                                        {jahre.findIndex(j => j === this.props.jahr - 4) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 4).toString()}</div>
                                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -4, [0, 1, 2], false)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr - 3) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 3).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -3, [0, 1, 2], false)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr - 2) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 2).toString()}</div>
                                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -2, [0, 1, 2], false)}
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <Row>
                                                        {jahre.findIndex(j => j === this.props.jahr - 1) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 1).toString()}</div>
                                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -1, [0, 1, 2], false)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ergebnis ") + (this.props.jahr).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, 0, [0, 1, 2], false)}
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>  
                                                    <Row>                                            
                                                        <Col>
                                                            <div className="ksl-grafik-container">
                                                                <Chart
                                                                    width="100%"
                                                                    height={'300px'}                                                                    
                                                                    chartType="ComboChart"
                                                                    chartLanguage='de'                                                            
                                                                    loader={<div>Loading Chart</div>}
                                                                    data={egr.data}
                                                                    options={{    
                                                                        colors: egr.colors,                                                                                                            
                                                                        chartArea: {width: '70%', left:'10%' },
                                                                        vAxes: { 0: { title: 'EUR', minValue: graphOptEGR.minG, maxValue: graphOptEGR.maxG, viewWindow: { max: graphOptEGR.max0, min: graphOptEGR.min0 } } },
                                                                        hAxis: { title: 'Jahr' },
                                                                        seriesType: 'bars'
                                                                    }}
                                                                    formatters={Utilities.createFormatters(4, 2)}
                                                                    rootProps={{ 'data-testid': '2' }}
                                                                />
                                                                </div>
                                                        </Col>
                                                    </Row>  
                                                </div>
                                                :                  
                                                <div>
                                                    <Row>
                                                        {jahre.findIndex(j => j === this.props.jahr - 2) != -1 &&
                                                            <Col className={"ksl-container-piechart" + ((jahreAnz < 4) ? jahreAnz.toString() : "3")} >
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 2).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -2, [0, 1, 2], false)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr - 1) != -1 &&
                                                            <Col className={"ksl-container-piechart" + ((jahreAnz < 4) ? jahreAnz.toString() : "3")} >
                                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Ergebnis " : "Ansatz ") + (this.props.jahr - 1).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -1, [0, 1, 2], false)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr) != -1 &&
                                                            <Col className={"ksl-container-piechart" + ((jahreAnz < 4) ? jahreAnz.toString() : "3")} >
                                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ansatz ") + (this.props.jahr).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, 0, [0, 1, 2], false)}
                                                                </div>
                                                            </Col>
                                                        }

                                                    </Row>
                                                    {(this.props.plantyp == 3 || this.props.plantyp == 4) &&
                                                        <Row>
                                                            <Col>
                                                                <div className="ksl-grafik-container">
                                                                    <Chart
                                                                        width="100%"
                                                                        height={'300px'}
                                                                        chartType="ComboChart"
                                                                        chartLanguage='de'
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={egr.data}
                                                                        options={{
                                                                            colors: egr.colors,
                                                                            chartArea: { width: '70%', left: '10%' },
                                                                            vAxes: { 0: { title: 'EUR', minValue: graphOptEGR.minG, maxValue: graphOptEGR.maxG, viewWindow: { max: graphOptEGR.max0, min: graphOptEGR.min0 } } },
                                                                            hAxis: { title: 'Jahr' },
                                                                            seriesType: 'bars'
                                                                        }}
                                                                        formatters={Utilities.createFormatters(4, 2)}
                                                                        rootProps={{ 'data-testid': '2' }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                 </div>
                                            }
                                        </div>}
                                        
                                        {ausgaben && !this.props.isvmhh &&
                                        <div>
                                            <Row>
                                                <Col>                                
                                                    <div className="kslplus-headline-third">{this.props.plantyp == 3 ?"Entwicklung der Ergebnisse der Ausgaben nach Hauptgruppen":"Entwicklung der Ausgaben des VWHH nach Hauptgruppen"}</div>                                                                                                        
                                                </Col>                                            
                                            </Row>
                                            {this.props.plantyp == 3 || (this.props.plantyp == 4 && jahreAnz > 3) ?
                                                <div>
                                                    <Row>      
                                                        {jahre.findIndex(j => j === this.props.jahr - 4) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 4).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -4, [4, 5, 6, 7, 8], true)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr - 3) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 3).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -3, [4, 5, 6, 7, 8], true)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr - 2) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 2).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -2, [4, 5, 6, 7, 8], true)}
                                                                </div>
                                                            </Col>
                                                        }
                                                </Row>
                                                    <Row>
                                                        {jahre.findIndex(j => j === this.props.jahr - 1) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 1).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -1, [4, 5, 6, 7, 8], true)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr) != -1 &&
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ergebnis ") + (this.props.jahr).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, 0, [4, 5, 6, 7, 8], true)}
                                                                </div>
                                                            </Col>
                                                        }
                                                </Row>
                                                <Row>                                            
                                                    <Col>
                                                        <div className="ksl-grafik-container">
                                                            <Chart
                                                                width="100%"
                                                                height={'300px'}                                                                    
                                                                chartType="ComboChart"
                                                                chartLanguage='de'                                                            
                                                                loader={<div>Loading Chart</div>}
                                                                data={agr.data}
                                                                options={{ 
                                                                    colors: agr.colors,                                                                                                               
                                                                    chartArea: {width: '70%', left:'10%' },
                                                                    vAxes: { 0: { title: 'EUR', minValue: graphOptAGR.minG, maxValue: graphOptAGR.maxG, viewWindow: { max: graphOptAGR.max0, min: graphOptAGR.min0 } } },
                                                                    hAxis: { title: 'Jahr' },
                                                                    seriesType: 'bars'
                                                                }}
                                                                formatters={Utilities.createFormatters(4, 2)}
                                                                rootProps={{ 'data-testid': '2' }}
                                                            />
                                                            </div>
                                                    </Col>
                                                </Row> 
                                                </div>
                                                :          
                                                <div>
                                                    <Row>
                                                        {jahre.findIndex(j => j === this.props.jahr - 2) != -1 &&
                                                            <Col className={"ksl-container-piechart" + ((jahreAnz < 4) ? jahreAnz.toString() : "3")} >
                                                                <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 2).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -2, [4, 5, 6, 7, 8], true)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr - 1) != -1 &&
                                                            <Col className={"ksl-container-piechart" + ((jahreAnz < 4) ? jahreAnz.toString() : "3")} >
                                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Ergebnis " : "Ansatz ") + (this.props.jahr - 1).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, -1, [4, 5, 6, 7, 8], true)}
                                                                </div>
                                                            </Col>
                                                        }
                                                        {jahre.findIndex(j => j === this.props.jahr) != -1 &&
                                                            <Col className={"ksl-container-piechart" + ((jahreAnz < 4) ? jahreAnz.toString() : "3")} >
                                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ansatz ") + (this.props.jahr).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                                    {this.createChart(this.state.ebene.filter, 0, [4, 5, 6, 7, 8], true)}
                                                                </div>
                                                            </Col>
                                                        }
                                    
                                                    </Row> 
                                                    {(this.props.plantyp == 3 || this.props.plantyp == 4) &&
                                                        <Row>
                                                            <Col>
                                                                <div className="ksl-grafik-container">
                                                                    <Chart
                                                                        width="100%"
                                                                        height={'300px'}
                                                                        chartType="ComboChart"
                                                                        chartLanguage='de'
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={agr.data}
                                                                        options={{
                                                                            colors: agr.colors,
                                                                            chartArea: { width: '70%', left: '10%' },
                                                                            vAxes: { 0: { title: 'EUR', minValue: graphOptAGR.minG, maxValue: graphOptAGR.maxG, viewWindow: { max: graphOptAGR.max0, min: graphOptAGR.min0 } } },
                                                                            hAxis: { title: 'Jahr' },
                                                                            seriesType: 'bars'
                                                                        }}
                                                                        formatters={Utilities.createFormatters(4, 2)}
                                                                        rootProps={{ 'data-testid': '2' }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                            }
                                        </div>                                        
                                    }
                                                        
                                    {this.createGZItem()}
                                    {this.createKennzahlItems()}                                    
                                   
                                    
                            </div> :
                            <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                    </div>
                
            </div>
            </div>                      
        );
    }
}

