import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import Chart from "react-google-charts";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import { GRHaushalt } from './GRHaushaltComp';
import { ProduktHaushalt } from './ProduktHaushaltComp';
import { KontenHaushalt } from './KontenHaushaltComp';
import { EbenenHaushaltDoppisch } from './EbenenHaushaltDoppischComp';
import { Ermaechtigungen } from './ErmaechtigungenComp';

import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";
import { debug } from "console";



type State = {
    ready: boolean;    
    activeTabs: string[],  
    activeMainTab: string,
    selectedProduktEbene: string,
    selectedKontoEbene: string,    
    isVisible: boolean

}

type Props = {    
    kunde: number,
    gemeinde: number,
    jahr: number,
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    settings: KSLPlusAPI.SettingsModel,    
    isVisible: boolean,
    summenTyp:number
}



export class HaushaltsDetailiert extends Component<Props, State> {
    constructor(props: any) {
        super(props);



    }

    node: any;
    hhProdukt: KSLPlusAPI.HaushaltsHiearchieDoppik | null = null;
    hhKonto: KSLPlusAPI.HaushaltsHiearchieDoppik | null = null;
    //hhGL_Ausgaben: KSLPlusAPI.HaushaltsEbene | null;
    //hhGR: KSLPlusAPI.HaushaltsEbene | null = null;
    //hhGR_Ausgaben: KSLPlusAPI.HaushaltsEbene | null;
    //hhEbenen_Einnahmen: KSLPlusAPI.HaushaltsEbene[];
    hhEbenen: KSLPlusAPI.HaushaltsEbene[] = [];
    ermebene: KSLPlusAPI.ErmEbene | null = null;
    ermebeneEin: KSLPlusAPI.ErmEbene | null = null;
    
    

    state: State = {
        ready: true,        
        activeTabs: [],
        activeMainTab: "1",
        selectedProduktEbene: "",
        selectedKontoEbene: "",        
        isVisible: false
    }    

    static defaultProps = {
        summenTyp: 1        
    }

    readProduktHHData = () => {
        this.hhProdukt = null;
        this.hhKonto = null;
        this.setState({ ready: true });
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.summenTyp = this.props.summenTyp;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);


        client.getHaushaltsHierarchieByProdukt("", request).then((hh) => {
            this.hhProdukt = hh;
            this.setState({ ready: true });
        }).catch(Reason => {
        });

        // der zugehörige summentyp für die Konten ist jeweils summentyp + 2 für plantyp != 3 und summentyp - 4 für plantyp == 3
        if (this.props.plantyp === 3  ) 
            request.summenTyp = this.props.summenTyp + 17;
        else if (this.props.plantyp === 4 && this.props.summenTyp == 19) 
            request.summenTyp = this.props.summenTyp + 9;
        else if (this.props.plantyp === 4 && this.props.summenTyp == 20)
            request.summenTyp = 25;
        else
            request.summenTyp = this.props.summenTyp+2;
        
        client.getHaushaltsHierarchieByKonto("", request).then((hh) => {               
            this.hhKonto = hh;
            this.setState({ ready: true });
        }).catch(Reason => {
        });

        

    }


    readErmaechtigungen = (einnahmen: boolean) => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);

        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        client.getErmaechtigungen(this.props.plantyp, this.props.planstufe, !(this.props.summenTyp == 1 || this.props.summenTyp == 3 || this.props.summenTyp == 7 || this.props.summenTyp == 9), einnahmen, "", request).then(erm => {
            if (einnahmen) 
                this.ermebeneEin = erm
            else
                this.ermebene = erm
            this.setState({ ready: true })

        }).catch(Reason => {

        });

    }
    
    readEbenenData = (ebene: string) => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig && this.props.uhconfig.id ? this.props.uhconfig.id : 0;
        request.ebene = ebene;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
                
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31];
        client.getHaushaltsHierarchieByEbene("", request).then((hh) => {
            this.hhEbenen.push(hh);            
            this.setState({ ready: true });
        }).catch(Reason => {
        });
    }

    readEbenen = () => {
        this.hhEbenen = [];
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;
        switch (this.props.plantyp) {
            case 3:
                request.modul = 1;
                break;

            case 4:
                request.modul = 2;
                break;

            default:            
                request.modul = 0;
                break;
        }
        

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
        

        client.toplevelebenen("", request).then((ebenen) => {
            for (var i = 0; i < ebenen.length; i++) {
                var ebene = ebenen[i];
                if ( ebene.ebenenId )
                    this.readEbenenData(ebene.ebenenId);
            }
            this.setState({ ready: true });
        }).catch(Reason => {
        });
    }
    
    readData = () => {
        this.readProduktHHData();        
        this.readErmaechtigungen(false);
        this.readErmaechtigungen(true);
        this.readEbenen();        
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible)        
            this.setState({activeMainTab: "1"});        

        if (prevProps.uhconfig !== this.props.uhconfig)
            this.readData();
    }

    componentDidMount() {
        if (this.props.plantyp !== 4 || this.props.uhconfig != undefined)
            this.readData();
                
    }
    
    createEbeneItems = (ebenen: KSLPlusAPI.HaushaltsEbene[]) => {
        var navItems: JSX.Element[] = [];
        var contentItems: JSX.Element[] = [];


        //if ( !ebenen.length || this.props.plantyp == 4)
          //  return {navItems: navItems, contentItems: contentItems};

        var tabIdx = 0;
        for (var i = 0; i < ebenen.length; i++) {
            const tabId = (i+4).toString();
            var bezeichnung = "Betrachtung nach " + ebenen[i].bezeichnung;
            navItems.push(
                
                <NavItem id={"kslplus-tab-" + bezeichnung.toLowerCase().replace(/ /gi, "-") + (this.props.summenTyp == 1  || this.props.summenTyp == 3  ? "-ergebnishaushalt" : "-finanzhaushalt")} key={"mnav" + tabId+toString()}>
                    <NavLink active={this.state.activeMainTab === tabId}   onClick={() => this.setState({activeMainTab: tabId})} >
                        {bezeichnung}
                    </NavLink>
                </NavItem>  
                    
            );
            var tt = Utilities.createTTItem(this.props.settings, bezeichnung.toLowerCase().replace(/ /gi, "-") + (this.props.summenTyp == 1 || this.props.summenTyp == 3 ? "-ergebnishaushalt" : "-finanzhaushalt"));
            if (tt) {
                navItems.push(
                    tt
                );
            }
            contentItems.push(
                <TabPane tabId={tabId} key={"mtab" + tabId+toString()}>                    
                    <div className="kslplus-haushaltstab-wrapper" >
                        <EbenenHaushaltDoppisch hh={ebenen[i]} jahr={this.props.jahr} kunde={this.props.kunde} gemeinde={this.props.gemeinde} planstufe={this.props.planstufe} plantyp={this.props.plantyp} settings={this.props.settings} summenTyp={this.props.summenTyp} isVisible={this.props.isVisible && this.state.activeMainTab === tabId} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />                        
                    </div>
                    
                </TabPane> 
            );                        
        }
        

        return {navItems: navItems, contentItems: contentItems};
    }
    
    handleSelectProduktEbene = (ebenenNum: string) => {
        this.setState({ selectedProduktEbene: ebenenNum });        
    }

    handleSelectKontoEbene = (ebenenNum: string) => {
        this.setState({ selectedKontoEbene: ebenenNum });        
    }

    

    render() {
        if (!this.state.ready )
            return null;

        if (!this.props.isVisible)
            return null;

        var ebeneItems = this.createEbeneItems(this.hhEbenen);
        //var ebeneItems = { navItems: null, contentItems: null }
        return (
            <div className="kslplus-haushaltstab-wrapper-bottom">      
                <Nav tabs>                                    
                    <NavItem id={"kslplus-tab-betrachtung-nach-aufgaben-" + (this.props.summenTyp == 1  || this.props.summenTyp == 3  ? "ergebnishaushalt" : "finanzhaushalt")}>
                        <NavLink active={this.state.activeMainTab === "1"}   onClick={() => this.setState({activeMainTab: "1"})} >
                            Betrachtung nach Aufgaben
                        </NavLink>
                    </NavItem>
                    {Utilities.createTTItem(this.props.settings, "betrachtung-nach-aufgaben-" + (this.props.summenTyp == 1 || this.props.summenTyp == 3 ? "ergebnishaushalt" : "finanzhaushalt"))}
                    <NavItem id={"kslplus-tab-betrachtung-nach-" + (this.props.summenTyp == 1  || this.props.summenTyp == 3  ? "ertrags-aufwandsarten" : "einzahlungs-auszahlungsarten")}>
                        <NavLink active={this.state.activeMainTab === "2"}   onClick={() => this.setState({activeMainTab: "2"})} >
                            {this.props.summenTyp == 1 || this.props.summenTyp == 3 || this.props.summenTyp == 7 || this.props.summenTyp == 9 || this.props.summenTyp == 17 || this.props.summenTyp == 19 ? "Betrachtung nach Ertrags-/Aufwandsarten" : "Betrachtung nach Einzahlungs-/Auszahlungssarten"}                            
                        </NavLink>
                    </NavItem>
                    {Utilities.createTTItem(this.props.settings, "betrachtung-nach-" + (this.props.summenTyp == 1 || this.props.summenTyp == 3 ? "ertrags-aufwandsarten" : "einzahlungs-auszahlungsarten"))}
                    {this.ermebene ?
                        <NavItem>
                            <NavLink active={this.state.activeMainTab === "3"} onClick={() => this.setState({ activeMainTab: "3" })} >
                                {this.props.summenTyp == 1 || this.props.summenTyp == 3 || this.props.summenTyp == 7 || this.props.summenTyp == 9 || this.props.summenTyp == 9 || this.props.summenTyp == 17 || this.props.summenTyp == 19 ? "Übertragene Aufwendungen" : "Übertragene Auszahlungen"}
                            </NavLink>
                        </NavItem> : null
                    }
                    {this.ermebeneEin ?
                        <NavItem>
                            <NavLink active={this.state.activeMainTab === "3_1"} onClick={() => this.setState({ activeMainTab: "3_1" })} >
                                {this.props.summenTyp == 1 || this.props.summenTyp == 3 || this.props.summenTyp == 7 || this.props.summenTyp == 9 || this.props.summenTyp == 9 || this.props.summenTyp == 17 || this.props.summenTyp == 19 ? "Übertragene Erträge" : "Übertragene Einzahlungen"}
                            </NavLink>
                        </NavItem> : null
                    }
                    {ebeneItems.navItems}
                </Nav>     
                <TabContent activeTab={this.state.activeMainTab}>    
                    <TabPane tabId="1">   
                        {this.hhProdukt ? 
                                <div className="kslplus-haushaltstab-wrapper" >
                                <ProduktHaushalt hh={this.hhProdukt} jahr={this.props.jahr} kunde={this.props.kunde} gemeinde={this.props.gemeinde} planstufe={this.props.planstufe} plantyp={this.props.plantyp} settings={this.props.settings} onSelChange={this.handleSelectProduktEbene} selected={this.state.selectedProduktEbene} summenTyp={this.props.summenTyp} isVisible={this.props.isVisible && this.state.activeMainTab === "1"} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                                </div>
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
                    </TabPane>
                    <TabPane tabId="2">   
                        {this.hhKonto ?
                            <div className="kslplus-haushaltstab-wrapper" >
                                <KontenHaushalt hh={this.hhKonto} jahr={this.props.jahr} kunde={this.props.kunde} gemeinde={this.props.gemeinde} planstufe={this.props.planstufe} plantyp={this.props.plantyp} settings={this.props.settings} onSelChange={this.handleSelectKontoEbene} selected={this.state.selectedKontoEbene} summenTyp={this.props.summenTyp} isVisible={this.props.isVisible && this.state.activeMainTab === "2"} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                            </div>
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
                    </TabPane>
                    {this.ermebene ?
                        <TabPane tabId="3">
                            <Ermaechtigungen jahr={this.props.jahr} ermebene={this.ermebene} settings={this.props.settings} auszahlungen={!(this.props.summenTyp == 1 || this.props.summenTyp == 3 || this.props.summenTyp == 7 || this.props.summenTyp == 9)}/>
                        </TabPane> : null
                    }
                    {this.ermebeneEin ?
                        <TabPane tabId="3_1">
                            <Ermaechtigungen jahr={this.props.jahr} ermebene={this.ermebeneEin} settings={this.props.settings} auszahlungen={!(this.props.summenTyp == 1 || this.props.summenTyp == 3 || this.props.summenTyp == 7 || this.props.summenTyp == 9)} />
                        </TabPane> : null
                    }
                    {ebeneItems.contentItems}    
                </TabContent>                                
            </div>
        );
    }
}