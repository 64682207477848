import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, Badge, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledPopover, PopoverBody, Container
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import Chart from "react-google-charts";

import { Kennzahl } from './KennzahlComp';


import * as Utilities from "./Utilities"

import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";



type State = {
    ready: boolean,    
}

type Props = {     
    buchungsart: number;   
    top: KSLPlusAPI.TopEAResult;
    modul: number;    
    jahr: number;
    vm: boolean;
}



export class TopEAAnalyse extends Component<Props, State> {
    constructor(props: any) {
        super(props);
                
    }

    node: any;    
    container: any = null;

    

    
    state: State = {
        ready: false        
    }

    
    componentDidMount() {        
    }

    

    rowClasses = (row: KSLPlusAPI.TopEAResultEbene, rowIdx: number) => {
        if (rowIdx > 0)
            return "kslplus-abcanlysis-rowborder";

        return "kslplus-abcanlysis-row";
    }
    
    createNegColumns = (typ: number) => {
        var columns = [];

        var hhtext = this.props.vm ? "Vermögenshaushalt" : "Verwaltungshaushalt";

        var id = 1;
        columns.push({
            dataField: 'hhFmt',
            text: this.props.buchungsart === 2 ? (this.props.top.stellen == -1 ? "Produktsachkonto" : "Konto") : (this.props.top.stellen == -1 ? "Haushaltsstelle" : "Gruppierung"),
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'right' };
            },
            footer: 0,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                return null;
            }
        });

        
        columns.push({
            dataField: 'hhText',
            text: "Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '130px', textAlign: 'left' };
            },
            footer: 1,            
            footerAlign: 'left',
            footerFormatter: (column: any, columnIndex: any) => {                
                if ( typ == 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Aufwendungen Top " + this.props.top.anzahlHH : "Gesamt Ausgaben Top " + this.props.top.anzahlHH}</div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Aufwendungen Ergebnishaushalt" : ("Gesamt Ausgaben " + hhtext)}</div>
                        </div>
                    );
                }
                else if ( typ < 0 )
                {
                    return (
                        <div>                        
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Minderaufwendungen Top " + this.props.top.anzahlHH : "Gesamt Minderausgaben Top " + this.props.top.anzahlHH}</div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Minderaufwendungen Ergebnishaushalt" : ("Gesamt Minderausgaben " + hhtext)}</div>
                        </div>
                    );
                }
                else if ( typ > 0 )
                {
                    return (
                        <div>                        
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Mehraufwendungen Top " + this.props.top.anzahlHH : "Gesamt Mehrausgaben Top " + this.props.top.anzahlHH}</div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Mehraufwendungen Ergebnishaushalt" : ("Gesamt Mehrausgaben " + hhtext)}</div>
                        </div>
                    );
                }
            },
            formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {                                
                //var id = row.hhFmt ? row.hhFmt.replace(/\./g, "-") + "_" + typ : "";                
                var id = row.id ? row.id + "_" + typ : "";                
                               
                return (
                    <div className="kslplus-tabellen-bezeichnung" >{row.hhText}{row.hhBeschreibung && row.hhBeschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={"kslplus-info-text-" + id}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={"kslplus-info-text-" +id}><div className="kslplus-tooltip">{row.hhBeschreibung}</div></UncontrolledTooltip></div> : null}</div>

                );
            }
        });
        
        if (this.props.top.attributName) {
            columns.push({
                dataField: 'attribut',
                text: this.props.top.attributName,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '1px', textAlign: 'left' };
                },
                footer: 6           
            });
        }

        if ( typ != 0  )
        {               
            
            columns.push({
                dataField: 'gesamtSoll',
                text: this.props.buchungsart === 2 ? "fortgeschriebener Ansatz" : "Gesamtsoll",
                align: 'right',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '65px', textAlign: 'right' };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {                
                    //var ttId = "tt-gs-" + (row.hhFmt ? row.hhFmt.replace(/\./g, "-") : "");
                    var ttId = "tt-gs-" + (row.id ? row.id : "");
                    return (
                        <div>
                            <div id={ttId}>{Utilities.FormatCurrency(row.gesamtSoll, 2, 3, '.', ',')  + " EUR"}</div>
                            {row.rohDaten?
                                <UncontrolledPopover
                                    size="lg"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-change-popover">
                                    <PopoverBody className="kslplus-change-popover-body">
                                        <Container>                                          
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign"></div><div className="kslplus-label">Ansatz:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.ansatz, 2, 3, '.', ',') + " EUR"}</div></div>
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Nachtrag:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.nachtrag, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Sollveränderung:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.sollueber, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">ÜPL/APL:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.ueplApl, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">=&nbsp;</div><div className="kslplus-label">Gesamtsoll:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.gesamtSoll, 2, 3, '.', ',') + " EUR"}</div></div>                                                                                                                        

                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>  
                               : null
                            }
                            {row.rohDatenDoppisch?
                                <UncontrolledPopover
                                    size="lg"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-change-popover">
                                    <PopoverBody className="kslplus-change-popover-body">
                                        <Container>                                          
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign"></div><div className="kslplus-label">Ansatz:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.ansatz, 2, 3, '.', ',') + " EUR"}</div></div>
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Nachtrag:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.nachtrag, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Sollveränderung:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.sollueber, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">ÜPL/APL:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.ueplApl, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">=&nbsp;</div><div className="kslplus-label">Gesamtsoll:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.gesamtSoll, 2, 3, '.', ',') + " EUR"}</div></div>                                                                                                                        

                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>  
                               : null
                            }
                        </div>
                    );

                },
                footer: 2                           
            });
                          
            columns.push({
                dataField: 'aenderung',
                text: this.props.buchungsart === 2 ? "Ergebnis " + this.props.jahr.toString()  : "Verfügt",
                align: 'right',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '65px', textAlign: 'right' };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {         
                    //var ttId = "tt-ae-" + (row.hhFmt ? row.hhFmt.replace(/\./g, "-") : "");
                    var ttId = "tt-ae-" + (row.id ? row.id : "");
                    return (
                        <div>
                            <div id={ttId}>{Utilities.FormatCurrency(row.aenderung, 2, 3, '.', ',')  + " EUR"}</div>
                            {row.rohDaten?
                                <UncontrolledPopover
                                    size="lg"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-change-popover">
                                    <PopoverBody className="kslplus-change-popover-body">
                                        <Container>                                          
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign"></div><div className="kslplus-label">Verfügt:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.aosollLfd, 2, 3, '.', ',') + " EUR"}</div></div>                                                      
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">HH-Rest neu:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.hrNeuPj, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">=&nbsp;</div><div className="kslplus-label">Gesamt:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.aenderung, 2, 3, '.', ',') + " EUR"}</div></div>                                                                                                                        

                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>  
                               : null
                            }
                            {row.rohDatenDoppisch?
                                <UncontrolledPopover
                                    size="lg"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-change-popover">
                                    <PopoverBody className="kslplus-change-popover-body">
                                        <Container>                                          
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign"></div><div className="kslplus-label">Verfügt:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.aosollLfd, 2, 3, '.', ',') + " EUR"}</div></div>                                                      
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">HH-Rest neu:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.hrNeuPj, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">=&nbsp;</div><div className="kslplus-label">Gesamt:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.aenderung, 2, 3, '.', ',') + " EUR"}</div></div>                                                                                                                        

                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>  
                               : null
                            }
                        </div>
                    );

                },
                footer: 3                           
            });
        }

        var text = "";
        if ( typ == 0 )
            text = this.props.modul == 1 ? "Ergebnis " + this.props.jahr.toString() : "Betrag";
        else if ( typ < 0 )
            text = this.props.buchungsart === 2 ? "Minderaufwendung" : "Minderausgabe";
        else if ( typ > 0 )
            text = this.props.buchungsart === 2 ? "Mehraufwendung" : "Mehrausgabe";
        
        columns.push({
            dataField: 'result',
            text: text,
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '65px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {                
                return (
                    <div>{Utilities.FormatCurrency(row.result, 2, 3, '.', ',')  + " EUR"}</div>
                );

            },
            footer: typ == 0 ? 2 : 4,            
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if ( typ == 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.negResultAbs, 2, 3, '.', ',') + " EUR"}</div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.negResultVH, 2, 3, '.', ',') + " EUR"}</div>
                        </div>
                    );
                }
                else if ( typ < 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.minderAResultAbsJR, 2, 3, '.', ',') + " EUR"}</div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.minderAResultVHJR, 2, 3, '.', ',') + " EUR"}</div>
                        </div>
                    );
                }
                else if ( typ > 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.mehrAResultAbsJR, 2, 3, '.', ',') + " EUR"}</div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.mehrAResultVHJR, 2, 3, '.', ',') + " EUR"}</div>
                        </div>
                    );
                }
            }
        });

        
        if ( typ == 0 ) 
            text = this.props.buchungsart === 2 ? "Anteil an den Gesamtaufwendungen" : "Anteil an den Gesamtausgaben";
        else if ( typ < 0 )
            text = this.props.buchungsart === 2 ? "Anteil an den Gesamtminderaufwendungen" : "Anteil an den Gesamtminderausgaben";
        else if ( typ > 0 )
            text = this.props.buchungsart === 2 ? "Anteil an den Gesamtmehraufwendungen" : "Anteil an den Gesamtmehrausgaben";

        columns.push({
            dataField: 'resultRel',
            text: text,
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '60px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {
                return (
                    <div>{Utilities.FormatCurrency(row.resultRel, 2, 3, '.', ',') + " %"}</div>
                );

            },
            footer: typ == 0 ? 3 : 5,               
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var val;
                if ( typ == 0 )
                    val = this.props.top.negResultRel;
                else if ( typ < 0 )
                    val = this.props.top.minderAResultRelJR;
                else if ( typ > 0 )
                    val = this.props.top.mehrAResultRelJR;

                return (
                    <div>
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(val, 2, 3, '.', ',') + " %"}</div>
                        <div className="kslplus-tabellen-footer">100,00 %</div>
                    </div>
                );
            }
        });

        

        return columns;
    }

    createPosColumns = (typ: number) => {
        var columns = [];

        var hhtext = this.props.vm ? "Vermögenshaushalt" : "Verwaltungshaushalt";
        var id = 1;
        columns.push({
            dataField: 'hhFmt',            
            text: this.props.buchungsart === 2 ? (this.props.top.stellen == -1 ? "Produktsachkonto" : "Konto") : (this.props.top.stellen == -1 ? "Haushaltsstelle" : "Gruppierung"),
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'right' };
            },
            footer: 0,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                return null;
            }
        });
        
        columns.push({
            dataField: 'hhText',
            text: "Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '130px', textAlign: 'left' };
            },
            footer: 1,            
            footerAlign: 'left',
            footerFormatter: (column: any, columnIndex: any) => {                
                if ( typ == 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Erträge Top " + this.props.top.anzahlHH : "Gesamt Einnahmen Top " + this.props.top.anzahlHH}</div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Erträge Ergebnishaushalt" : ("Gesamt Einnahmen " + hhtext)}</div>
                        </div>
                    );
                }
                else if ( typ < 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Mindererträge Top " + this.props.top.anzahlHH : "Gesamt Mindereinnahmen Top " + this.props.top.anzahlHH}</div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Mindererträge Ergebnishaushalt" : ("Gesamt Mindereinnahmen " + hhtext)}</div>
                        </div>
                    );
                }
                else if ( typ > 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Mehrerträge Top " + this.props.top.anzahlHH : "Gesamt Mehreinnahmen Top " + this.props.top.anzahlHH}</div>
                            <div className="kslplus-tabellen-footer">{this.props.buchungsart === 2 ? "Gesamt Mehrerträge Ergebnishaushalt" : ("Gesamt Mehreinnahmen " + hhtext)}</div>
                        </div>
                    );
                }
            },
            formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {                
                //var id = row.hhFmt ? row.hhFmt.replace(/\./g, "-") + "_" + typ : "";
                var id = row.id ? row.id + "_" + typ : "";
                
                return (
                    <div className="kslplus-tabellen-bezeichnung" >{row.hhText}{row.hhBeschreibung && row.hhBeschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={"kslplus-info-text-" + id}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={"kslplus-info-text-" + id}><div className="kslplus-tooltip">{row.hhBeschreibung}</div></UncontrolledTooltip></div> : null}</div>

                );
            }
        });

        if (this.props.top.attributName) {
            columns.push({
                dataField: 'attribut',
                text: this.props.top.attributName,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '1px', textAlign: 'left' };
                },
                footer: 6           
            });
        }
        
        if ( typ != 0  )
        {                    
            columns.push({
                dataField: 'gesamtSoll',
                text: this.props.buchungsart === 2 ? "fortgeschriebener Ansatz" : "Gesamtsoll",
                align: 'right',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '65px', textAlign: 'right' };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {                
                    //var ttId = "tt-gs-" + (row.hhFmt ? row.hhFmt.replace(/\./g, "-") : "");
                    var ttId = "tt-gs-" + (row.id ? row.id : "");
                    return (
                        <div>
                            <div id={ttId}>{Utilities.FormatCurrency(row.gesamtSoll, 2, 3, '.', ',')  + " EUR"}</div>
                            {row.rohDaten?
                                <UncontrolledPopover
                                    size="lg"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-change-popover">
                                    <PopoverBody className="kslplus-change-popover-body">
                                        <Container>                                          
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign"></div><div className="kslplus-label">Ansatz:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.ansatz, 2, 3, '.', ',') + " EUR"}</div></div>
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Nachtrag:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.nachtrag, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Sollveränderung:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.sollueber, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">ÜPL/APL:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.ueplApl, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">=&nbsp;</div><div className="kslplus-label">Gesamtsoll:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.gesamtSoll, 2, 3, '.', ',') + " EUR"}</div></div>                                                                                                                        

                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>  
                               : null
                            }
                            {row.rohDatenDoppisch?
                                <UncontrolledPopover
                                    size="lg"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-change-popover">
                                    <PopoverBody className="kslplus-change-popover-body">
                                        <Container>                                          
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign"></div><div className="kslplus-label">Ansatz:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.ansatz, 2, 3, '.', ',') + " EUR"}</div></div>
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Nachtrag:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.nachtrag, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Sollveränderung:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.sollueber, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">ÜPL/APL:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.ueplApl, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">=&nbsp;</div><div className="kslplus-label">Gesamtsoll:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.gesamtSoll, 2, 3, '.', ',') + " EUR"}</div></div>                                                                                                                        

                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>  
                               : null
                            }
                            
                        </div>
                    );

                },
                footer: 2                           
            });
                         
            columns.push({
                dataField: 'aenderung',
                text: this.props.buchungsart === 2 ? "Ergebnis " + this.props.jahr.toString() : "Eingenommen",
                align: 'right',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '65px', textAlign: 'right' };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {                
                    //var ttId = "tt-ae-" + (row.hhFmt ? row.hhFmt.replace(/\./g, "-") : "");
                    var ttId = "tt-ae-" + (row.id ? row.id : "");
                    return (
                        <div>
                            <div id={ttId}>{Utilities.FormatCurrency(row.aenderung, 2, 3, '.', ',')  + " EUR"}</div>
                            {row.rohDaten?
                                <UncontrolledPopover
                                    size="lg"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-change-popover">
                                    <PopoverBody className="kslplus-change-popover-body">
                                        <Container>                                          
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign"></div><div className="kslplus-label">Eingenommen:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.aosollLfd, 2, 3, '.', ',') + " EUR"}</div></div>                                                      
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">HH-Rest neu:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDaten.hrNeuPj, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">=&nbsp;</div><div className="kslplus-label">Gesamt:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.aenderung, 2, 3, '.', ',') + " EUR"}</div></div>                                                                                                                        

                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>  
                               : null
                            }
                            {row.rohDatenDoppisch?
                                <UncontrolledPopover
                                    size="lg"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-change-popover">
                                    <PopoverBody className="kslplus-change-popover-body">
                                        <Container>                                          
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign"></div><div className="kslplus-label">Eingenommen:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.aosollLfd, 2, 3, '.', ',') + " EUR"}</div></div>                                                      
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">HH-Rest neu:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.rohDatenDoppisch.hrNeuPj, 2, 3, '.', ',') + " EUR"}</div></div>                                        
                                            <div className="kslplus-topeasanalyse-tt-child"><div className="kslplus-sign">=&nbsp;</div><div className="kslplus-label">Gesamt:</div><div className="kslplus-wert">{Utilities.FormatCurrency(row.aenderung, 2, 3, '.', ',') + " EUR"}</div></div>                                                                                                                        

                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>  
                               : null
                            }
                        </div>
                    );
                },
                footer: 3
            });
        }


        var text = "";
        if ( typ == 0 )
            text = this.props.modul == 1 ? "Ergebnis " + this.props.jahr.toString() : "Betrag";
        else if ( typ < 0 )
            text = this.props.buchungsart === 2 ? "Minderertrag" : "Mindereinnahme";
        else if ( typ > 0 )
            text = this.props.buchungsart === 2 ? "Mehrertrag" : "Mehreinnahme";

        columns.push({
            dataField: 'result',
            text: text,
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '65px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {                
                return (
                    <div>{Utilities.FormatCurrency(row.result, 2, 3, '.', ',')  + " EUR"}</div>
                );

            },
            footer: typ == 0 ? 2 : 4,  
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if ( typ == 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.posResultAbs, 2, 3, '.', ',') + " EUR"}</div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.posResultVH, 2, 3, '.', ',') + " EUR"}</div>
                        </div>
                    );
                }
                else if ( typ < 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.minderEResultAbsJR, 2, 3, '.', ',') + " EUR"}</div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.minderEResultVHJR, 2, 3, '.', ',') + " EUR"}</div>
                        </div>
                    );
                }
                else if ( typ > 0 )
                {
                    return (
                        <div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.mehrEResultAbsJR, 2, 3, '.', ',') + " EUR"}</div>
                            <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.top.mehrEResultVHJR, 2, 3, '.', ',') + " EUR"}</div>
                        </div>
                    );
                }
            }
        });

        
        
        if ( typ == 0 ) 
            text = this.props.buchungsart === 2 ? "Anteil an den Gesamterträgen" : "Anteil an den Gesamteinnahmen";
        else if ( typ < 0 )
            text = this.props.buchungsart === 2 ? "Anteil an den Gesamtmindererträgen" : "Anteil an den Gesamtmindereinnahmen";
        else if ( typ > 0 )
            text = this.props.buchungsart === 2 ? "Anteil an den Gesamtmehrerträgen" : "Anteil an den Gesamtmehreinnahmen";

        columns.push({
            dataField: 'resultRel',
            text: text,
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '60px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.TopEAResultEbene) => {
                return (
                    <div>{Utilities.FormatCurrency(row.resultRel, 2, 3, '.', ',') + " %"}</div>
                );

            },
            footer: typ == 0 ? 3 : 5,  
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var val;
                if ( typ == 0 )
                    val = this.props.top.posResultRel;
                else if ( typ < 0 )
                    val = this.props.top.minderEResultRelJR;
                else if ( typ > 0 )
                    val = this.props.top.mehrEResultRelJR;
                return (
                    <div>
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(val, 2, 3, '.', ',') + " %"}</div>
                        <div className="kslplus-tabellen-footer">100,00 %</div>
                    </div>
                );
            }
        });

                

        return columns;
    }
    
    render() {                 
        return (
            
            <div className="kslplus-abcanlysis">
                {this.props.top.posResults && this.props.top.posResults.length > 0 &&
                    <div>
                        <Row>
                            <Col className="kslplus-beschreibung-content">
                                <div className="content" dangerouslySetInnerHTML={{ __html: this.props.top.textOben ? this.props.top.textOben : "" }}></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapTable hover={false} bordered={false} keyField='hhFmt' data={this.props.top.posResults} columns={this.createPosColumns(0)} rowStyle={{ backgroundColor: this.props.top.farbeE ? this.props.top.farbeE : 'lightgreen' }} />
                            </Col>
                        </Row>
                    </div>
                }
                {this.props.modul == 1 && this.props.top.mehrEResultsJR && this.props.top.mehrEResultsJR.length > 0 &&
                    <div>
                        <Row>
                            <Col className="kslplus-beschreibung-content">
                                <div className="content" dangerouslySetInnerHTML={{ __html: this.props.top.textObenAE ? this.props.top.textObenAE : "" }}></div>
                            </Col>                                        
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapTable hover={false} bordered={false} keyField='hhFmt' data={this.props.top.mehrEResultsJR} columns={this.createPosColumns(1)} rowStyle={{backgroundColor: this.props.top.farbeMehrE ? this.props.top.farbeMehrE : 'lightgreen'}} />                        
                            </Col>
                        </Row>
                    </div>
                 }
                {this.props.modul == 1 && this.props.top.minderEResultsJR && this.props.top.minderEResultsJR.length > 0 &&
                    <div>
                        <Row>
                            <Col className="kslplus-beschreibung-content">
                                <div className="content" dangerouslySetInnerHTML={{ __html: this.props.top.textMitteAE? this.props.top.textMitteAE : "" }}></div>
                            </Col>                                        
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapTable hover={false} bordered={false} keyField='hhFmt' data={this.props.top.minderEResultsJR} columns={this.createPosColumns(-1)} rowStyle={{backgroundColor: this.props.top.farbeMinderE ? this.props.top.farbeMinderE : 'lightgreen'}} />                        
                            </Col>
                        </Row>
                    </div>
                }
                {this.props.top.negResults && this.props.top.negResults.length > 0 &&
                    <div>
                        <Row>
                            <Col className="kslplus-beschreibung-content">
                                <div className="content" dangerouslySetInnerHTML={{ __html: this.props.top.textMitte ? this.props.top.textMitte : "" }}></div>
                            </Col>
                        </Row>
                        <Col>
                            <BootstrapTable hover={false} bordered={false} keyField='hhFmt' data={this.props.top.negResults} columns={this.createNegColumns(0)} rowStyle={{ backgroundColor: this.props.top.farbeA ? this.props.top.farbeA : '#ffb09c' }} />
                        </Col>
                    </div>
                }
                {this.props.modul == 1 && this.props.top.mehrAResultsJR && this.props.top.mehrAResultsJR.length > 0 &&
                    <div>
                        <Row>
                            <Col className="kslplus-beschreibung-content">
                                <div className="content" dangerouslySetInnerHTML={{ __html: this.props.top.textObenAA ? this.props.top.textObenAA : "" }}></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapTable hover={false} bordered={false} keyField='hhFmt' data={this.props.top.mehrAResultsJR} columns={this.createNegColumns(1)} rowStyle={{ backgroundColor: this.props.top.farbeMehrA ? this.props.top.farbeMehrA : 'lightgreen' }} />
                            </Col>
                        </Row>
                    </div>
                }
                {this.props.modul == 1 && this.props.top.minderAResultsJR && this.props.top.minderAResultsJR.length > 0 &&
                    <div>
                        <Row>
                            <Col className="kslplus-beschreibung-content">
                                <div className="content" dangerouslySetInnerHTML={{ __html: this.props.top.textMitteAA? this.props.top.textMitteAA : "" }}></div>
                            </Col>                                        
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapTable hover={false} bordered={false} keyField='hhFmt' data={this.props.top.minderAResultsJR} columns={this.createNegColumns(-1)} rowStyle={{backgroundColor: this.props.top.farbeMinderA ? this.props.top.farbeMinderA : 'lightgreen'}} />                        
                            </Col>
                        </Row>
                    </div>
                }

                
            </div>
        );
    }
}