import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"
import { HHSummiertDoppisch } from './HHSummiertDoppischComp';

import Chart from "react-google-charts";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as Utilities from "./Utilities"

import { Kennzahlen } from './KennzahlenComp';
import { InvestitionenDoppisch } from './InvestitionenDoppischComp';
import { ABCAnalyse } from './ABCAnalyseComp';
import { TopEAAnalyse } from './TopEAAnalyseComp';
import { OffenePosten } from './OffenePostenComp';
import { Ermaechtigungen } from './ErmaechtigungenComp';

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import { VBTab } from './VBTabComp';

type State = {
    ready: boolean;        
    activeMainTab: string,
    activeKeTab: string,
    dropDownOpen: boolean,   
    isVisible: boolean 
}

type Props = {    
    kunde: number,
    gemeinde: number,
    jahr: number,
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    bilanz: boolean,
    settings: KSLPlusAPI.SettingsModel,
    isVisible: boolean
}




export class WeitereFinanzdatenDoppisch extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
                
    }

    node: any;
    
    dataSets: KSLPlusAPI.Datensaetze[] = [];    
    vbtabs: KSLPlusAPI.Vbtab[] = [];
    abc: KSLPlusAPI.ABCResult | null= null;
    top: KSLPlusAPI.TopEAResult | null = null;
    topEAs: KSLPlusAPI.TopEAResult[] = [];
    ermebene: KSLPlusAPI.ErmEbene | null = null;    
    kennzahlen: KSLPlusAPI.Kennzahlen[] = [];
    kennzahlenBilanz: KSLPlusAPI.Kennzahlen[] = [];       
    invests: KSLPlusAPI.InvestitionsModelDoppik[] = [];
    investSettings: KSLPlusAPI.InvestSetting|null = null;
    defTab: string = "";

    state: State = {
        ready: true,        
        activeMainTab: "",
        activeKeTab: "",
        dropDownOpen: false,     
        isVisible: false   
    }        

    readDataSets = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
       
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.datensaetze(this.props.kunde, this.props.gemeinde, this.props.jahr, -1, this.props.plantyp === 3 ? 1 : 0, "").then((sets) => {
            this.dataSets = sets;          
            this.readMainTabs();  
            this.setState({ ready: true });            
        }).catch(Reason => {
        });
    }

    readMainTabs = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.tabs(this.props.kunde, this.props.gemeinde, this.props.jahr, this.props.bilanz ? 6 : 5, this.props.plantyp == 3 ? 1 : (this.props.plantyp == 4 ? 2 : 0), "").then((tabs) => {            
            this.vbtabs = tabs;            
            if (this.state.activeMainTab === "" && tabs.length > 0) {
                this.state.activeMainTab = "tab0";
                this.defTab = "tab0";
            }
            //if (!this.props.bilanz && this.props.plantyp === 3)
              //  this.readHaushaltsReste();
            this.setState({ ready: true });
        }).catch(Reason => {
        });
    }

    readInvestitionen = () => {        
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;
        request.modul = (this.props.plantyp == 3 ? 1 : (this.props.plantyp == 4 ? 2 : 0));
        

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var clientDoppik = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);


        clientDoppik.getInvestitionen(this.props.plantyp, this.props.planstufe, this.props.uhconfig && this.props.uhconfig.id ? this.props.uhconfig.id : 0, "", request).then((invests) => {
            client.getInvestSettings(this.props.kunde, this.props.gemeinde, this.props.jahr, "").then(settings => {                                
                this.investSettings = settings;                
                this.invests = invests;                
                if ( this.state.activeMainTab === "" && invests.length > 0 )
                {
                    this.state.activeMainTab = "4";        
                    this.defTab="4";
                }
                this.setState({ ready: true });                
                //this.readDataSets();            
            }).catch(Reason => {                
                //this.readDataSets();            
            });
        }).catch(Reason => {
            this.readDataSets();            
        });
        
    }

    readKennzahlen = () => {
        this.kennzahlen = [];
        this.setState({ ready: true });
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;
        request.modul = (this.props.plantyp == 3 ? 1 : (this.props.plantyp == 4 ? 2 : 0));

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdAdmin_Client(api);


        client.getAllgemeineKennzahlen("", request).then((kennzahlen) => {
            this.kennzahlen = kennzahlen;   
            if ( this.props.plantyp === 3 ) 
            {
                if ( this.props.bilanz )
                    this.kennzahlen = kennzahlen.filter(k => k.bilanz);
                else
                    this.kennzahlen = kennzahlen.filter(k => !k.bilanz);
                
                if ( this.kennzahlen.length > 0 )
                    this.state.activeKeTab = "1";
                //else if ( this.kennzahlenBilanz.length > 0 )
                  //  this.state.activeKeTab = "2";
            }
            if (this.state.activeMainTab === "" && this.kennzahlen.length > 0 )
            {
                this.state.activeMainTab = "3";
                this.defTab="3";
            }                                            
            this.setState({ ready: true });
            if ((this.props.plantyp != 3 && this.props.plantyp != 4) || this.props.bilanz )
                this.readInvestitionen();
            this.readDataSets();
        }).catch(Reason => {
            if ((this.props.plantyp != 3 && this.props.plantyp != 4) || this.props.bilanz )
                this.readInvestitionen();            
            this.readDataSets();
        });

    }

    readABCAnalyse = () => {
        this.abc = null;
        this.setState({ ready: true });
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);

        client.aBCAnalysis(this.props.kunde, this.props.gemeinde, this.props.jahr, this.props.plantyp, this.props.planstufe, this.props.uhconfig && this.props.uhconfig.id ? this.props.uhconfig.id : 0, "").then(abc => {
            this.abc = abc;            
            if ( this.state.activeMainTab === "" )
            {
                this.state.activeMainTab = "2";
                this.defTab="2";
            }
            this.setState({ ready: true });
            //this.readDataSets();
            this.readKennzahlen();
        }).catch(Reason => {
            this.readDataSets();
            this.readKennzahlen();
        });
    }

    readTopAnalyse = () => {       
        this.top = null;
        this.setState({ ready: true });
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);

        client.topEAAnalysis(this.props.kunde, this.props.gemeinde, this.props.jahr, this.props.plantyp, this.props.planstufe, this.props.uhconfig && this.props.uhconfig.id ? this.props.uhconfig.id : 0, "").then(top => {                       
            this.top = top;
            if ( this.state.activeMainTab === "" )
            {
                this.state.activeMainTab = "1";
                this.defTab="1";
            }
            this.setState({ ready: true })
            this.readABCAnalyse();
        }).catch(Reason => {            
            this.readABCAnalyse();                
        });        
        
    }

    readTopAnalyses = (vm: boolean = false) => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);


        client.topEAAnalyses(this.props.kunde, this.props.gemeinde, this.props.jahr, this.props.plantyp, this.props.planstufe, this.props.verwaltungsansicht, this.props.uhconfig && this.props.uhconfig.id ? this.props.uhconfig.id : 0, "").then(top => {
            this.topEAs = top;            
            if (top.length > 0 && this.state.activeMainTab === "") {
                this.state.activeMainTab = "1_0";
                this.defTab = "1_0";
            }
            this.setState({ ready: true })
            this.readABCAnalyse();
        }).catch(Reason => {           
            this.readABCAnalyse();
        });
    }

    
    /*
    readKassenReste = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);

        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        client.getKassenReste(this.props.plantyp, this.props.planstufe, "", request).then(kr => {
            this.krebene = kr;
            if (this.state.activeMainTab === "") {
                this.state.activeMainTab = "5";
                this.defTab = "5";
            }
            this.setState({ ready: true })
            this.readHaushaltsReste();
        }).catch(Reason => {
            this.readHaushaltsReste();
        });

    }
    */

    readHaushaltsReste = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);

        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        client.getErmaechtigungen(this.props.plantyp, this.props.planstufe, false, false, "", request).then(erm => {
            this.ermebene = erm
            if (this.state.activeMainTab === "") {
                this.state.activeMainTab = "6";
                this.defTab = "6";
            }
            this.setState({ ready: true })

        }).catch(Reason => {

        });

    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible && this.defTab.length > 0 )        
        {
            this.setState({activeMainTab: this.defTab});        
            if ( this.kennzahlen.length > 0 )
                this.setState({activeKeTab: "1"});        
            //else if ( this.kennzahlenBilanz.length > 0 )
              //  this.setState({activeKeTab: "2"});        
        }

        if (!this.props.bilanz && prevProps.uhconfig !== this.props.uhconfig) {            
            this.readTopAnalyses();
        }
    }

    componentDidMount() {
        if (this.props.bilanz)
            this.readKennzahlen();
        else if (this.props.plantyp !== 4 || this.props.uhconfig != undefined)            
            this.readTopAnalyses();
        

    }

                      
    createTabItems = (tabs: KSLPlusAPI.Vbtab[]) => {
        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];   

        for (var i = 0; i < tabs.length; i++) {
            const tabId = "tab" + i.toString();
            const tab = tabs[i];
            mainNavItems.push(
                <NavItem key={"kenav" + tabId}>
                    <NavLink active={this.state.activeMainTab === tabId} onClick={() => this.setState({ activeMainTab: tabId })} >
                        <div className="kslplus-reiter-text-hauptzeile">{tab.bezeichnung}</div>
                    </NavLink>
                </NavItem>
            );
            mainContentItems.push(
                <TabPane tabId={tabId} key={"ketab" + tabId}>  
                    <VBTab modul={this.props.plantyp == 3 ? 1 : 0} tab={tabs[i]} datasets={this.dataSets} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} settings={this.props.settings} className="kslplus-haushaltstab-wrapper-very-bottom" isVisible={this.state.activeMainTab === tabId}/>                  
                </TabPane>
            );
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };        
    }

    createTopEAItems = () => {
        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];

        for (var i = 0; i < this.topEAs.length; i++) {
            const tabId = "1_" + i.toString();
            const top = this.topEAs[i];
            mainNavItems.push(
                <NavItem key={"kenav" + tabId}>
                    <NavLink active={this.state.activeMainTab === tabId} onClick={() => this.setState({ activeMainTab: tabId })} >
                        <div className="kslplus-reiter-text-hauptzeile">{top.bezeichnung}</div>
                    </NavLink>
                </NavItem>
            );
            mainContentItems.push(
                <TabPane tabId={tabId} key={"ketab" + tabId}>
                    <TopEAAnalyse top={top} buchungsart={2} modul={this.props.plantyp == 3 || this.props.plantyp == 4 ? 1 : 0} jahr={this.props.jahr} vm={false} />
                </TabPane>
            );
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };
    }

    render() {
        if (!this.state.ready )
            return null;

        if ( this.state.activeMainTab == "" )
        {
            if (this.topEAs.length > 0)
                this.state.activeMainTab = "1_0";
            else if ( this.abc )
                this.state.activeMainTab = "2";            
            else if ( this.kennzahlen.length > 0 )
                this.state.activeMainTab = "3";            
            else if ( this.invests.length > 0 )
                this.state.activeMainTab = "4";            
            else if (this.ermebene !== null)
                this.state.activeMainTab = "6";            
        }

        if (!this.props.isVisible)
            return null;
        
        var tabItems = this.createTabItems(this.vbtabs);
        var topItems = this.createTopEAItems();
        
        
        return (
            <div className="kslplus-haushaltstab-wrapper-bottom">  
                <Nav tabs>                   

                    {topItems.navItems}
                    {this.top ? 
                        <NavItem>
                            <NavLink active={this.state.activeMainTab === "1"} onClick={() => this.setState({ activeMainTab: "1" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{"Top " + this.top.anzahlHH + " der Erträge/Aufwendungen"}</div>
                            </NavLink>
                        </NavItem> : null
                    }   

                    {this.abc ?
                        <NavItem>
                            <NavLink active={this.state.activeMainTab === "2"} onClick={() => this.setState({ activeMainTab: "2" })} >                                
                                <div className="kslplus-reiter-text-hauptzeile">ABC-Analyse</div>                                
                            </NavLink>
                        </NavItem> : null
                    }

                    {this.kennzahlen.length ?
                        <NavItem>
                            <NavLink active={this.state.activeMainTab === "3"} onClick={() => this.setState({ activeMainTab: "3" })} >        
                                <div className="kslplus-reiter-text-hauptzeile">Kennzahlen</div>
                            </NavLink>
                        </NavItem> : null
                    }

                    {this.invests.length > 0 ?                        
                        <NavItem id="kslplus-tab-investitionen">
                            <NavLink active={this.state.activeMainTab === "4"} onClick={() => this.setState({ activeMainTab: "4" })} >
                                <div className="kslplus-reiter-text-hauptzeile">Investitionen</div>                                
                            </NavLink>
                        </NavItem> : null                    
                    }
                    {this.invests.length > 0 ?
                        Utilities.createTTItem(this.props.settings, "investitionen") : null
                    }

                    

                    {this.ermebene ?
                        <NavItem>
                            <NavLink active={this.state.activeMainTab === "6"} onClick={() => this.setState({ activeMainTab: "6" })} >
                                <div className="kslplus-reiter-text-hauptzeile">Ermächtigungen</div>
                            </NavLink>
                        </NavItem> : null
                    }
                                                                                              
                    {tabItems.navItems}                    
                </Nav>
                
                <TabContent activeTab={this.state.activeMainTab}>  

                    {topItems.contentItems}
                    {this.top ?
                        <TabPane tabId="1">
                            <TopEAAnalyse top={this.top} buchungsart={2} modul={this.props.plantyp == 3 || this.props.plantyp == 4 ? 1 : 0} jahr={this.props.jahr} vm={false} />
                        </TabPane> :  null
                    }                  

                    {this.abc ?
                        <TabPane tabId="2">
                            <ABCAnalyse kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} abc={this.abc} buchungsart={2} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                        </TabPane> : null
                    }
                                       
                    {this.kennzahlen.length ?
                        <TabPane tabId="3">    
                            <Kennzahlen kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} kennzahlen={this.kennzahlen} isVisible={this.state.activeMainTab === "3"} bilanz={this.props.bilanz} uhconfig={this.props.uhconfig} />
                        </TabPane> : null
                    }
                    

                    {(this.invests.length > 0 &&  this.investSettings) ?
                        <TabPane tabId="4">   
                            <InvestitionenDoppisch kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} invests={this.invests} investSettings={this.investSettings} />
                        </TabPane> : null
                    }

                    
                    

                    {this.ermebene ?
                        <TabPane tabId="6">
                            <Ermaechtigungen jahr={this.props.jahr} ermebene={this.ermebene} settings={this.props.settings} auszahlungen={false}/>
                        </TabPane> : null
                    }

                    {tabItems.contentItems}
                </TabContent>
                
                               
            </div>
        );
    }
}