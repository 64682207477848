import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, Badge
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Chart } from "react-google-charts";

import { GoogleChartWrapperChartType } from "react-google-charts/dist/types";



import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import Collapse from "@kunukn/react-collapse";

type State = {
    ready: boolean,
    beschreibungActive: boolean    
}

type Props = {    
    kennzahl: KSLPlusAPI.Kennzahlen,
    jahr: number,
    kunde: number,
    gemeinde: number,    
    plantyp: number,
    planstufe: number,   
    produkt: string,
    settings: KSLPlusAPI.SettingsModel    
}



export class Kennzahl extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.zeilen=  [];
    }

    static defaultProps = {
        produkt: ""    
    }

    node: any;
    zeilen: KSLPlusAPI.KennzahlZeilenErgebnis[];
    jahre: number[] | undefined;
    container: any = null;

    createDataColumn = (field: string, jahr: number) => {
        

        return {
            dataField: field,
            text: jahr.toString(),
            headerAlign: 'center',
            align: 'right',
            formatter: (cellContent: string, row: KSLPlusAPI.KennzahlZeilenErgebnis) => {
                var ez = row as { [key: string]: any };
                if (!row.zeile || (!row.zeile.inkludierteGruppen || row.zeile.inkludierteGruppen.length == 0) && (!row.zeile.subtraktionsGruppen || row.zeile.subtraktionsGruppen.length == 0) && (!row.zeile.berechnung || row.zeile.berechnung.length == 0) && (!row.zeile.ds || row.zeile.ds.length == 0))
                    return null;

                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(ez[field], 2, 3, '.', ',')} </div>
                );

            },
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '20%' };
            },
            style: (colum: any, colIndex: any) => {
                return { width: '20%' };
            }
        };
    }

    rowClasses = (row: KSLPlusAPI.KennzahlZeilenErgebnis, rowIdx: number) => {
        if (row.istErgebnisZeile)
            return "kslplus-kennzahl-ergebnis-zeile";

        return ".kslplus-kennzahl-zeile";
    }

    createColumns = () => {
        var columns = [];


        var id = 1;
        columns.push({
            dataField: 'bezeichnung',
            text: "Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KennzahlZeilenErgebnis) => {
                /*
                return (
                    <div>{row.bezeichnung + " (" + row.einheit ? row.einheit : "EUR" + ")"}</div>
                );*/
                if ( !row.bezeichnung || row.bezeichnung.length == 0 )
                    return null;

                if ( !row.zeile || (!row.zeile.inkludierteGruppen || row.zeile.inkludierteGruppen.length == 0) && (!row.zeile.subtraktionsGruppen || row.zeile.subtraktionsGruppen.length == 0) && (!row.zeile.berechnung || row.zeile.berechnung.length == 0))
                    return (<div>{row.bezeichnung}</div>);

                return (                                            
                    <div>{row.bezeichnung + " (" + (row.einheit ? row.einheit : "EUR") + ")"}</div>
                );

            },
        });

        if (this.zeilen && this.zeilen.filter(z => z.zeile && z.zeile.gliederung && z.zeile.gliederung.length > 0).length > 0) {            
            columns.push({
                dataField: 'zeile.gliederung',
                text: this.props.settings.buchungsart === 2 ? "Produkte" : "Gliederung",
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '250px', textAlign: 'left' };
                }

            });
        }
        
        columns.push({
            dataField: 'gruppen',
            text: this.props.settings.buchungsart === 2 ? "Konten" : "Gruppierungen",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KennzahlZeilenErgebnis) => {                
                var text = "";
                if ( row.zeile )
                {   
                    if ( row.zeile.inkludierteGruppen )
                        text = row.zeile.inkludierteGruppen;
                    if ( row.zeile.exkludierteGruppen )
                    {   
                        text += ",!";
                        text += row.zeile.exkludierteGruppen;
                    }
                    if ( row.zeile.subtraktionsGruppen )
                    {   
                        text += " abzgl. ";
                        text += row.zeile.subtraktionsGruppen;
                    }
                }
                
                return (
                    <div>{text}</div>
                );

            }
        });

        

        id++;
        if (!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 2) >= 0)
        {
            columns.push(this.createDataColumn("ergebnisVvJ", this.props.jahr - 2));
            id++;
        }
        if (!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 1) >= 0)
        {
            columns.push(this.createDataColumn("ergebnisVJ", this.props.jahr - 1));
            id++;
        }
        if (!this.jahre || this.jahre.findIndex(d => d == this.props.jahr) >= 0)
        {
            columns.push(this.createDataColumn("ergebnisPJ", this.props.jahr));
            id++;
        }
        if (!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 1) >= 0)
        {
            columns.push(this.createDataColumn("ergebnisFp1", this.props.jahr + 1));
            id++;
        }
        if (!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 2) >= 0)
        {
            columns.push(this.createDataColumn("ergebnisFp2", this.props.jahr + 2));
            id++;
        }
        if (!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 3) >= 0)
        {
            columns.push(this.createDataColumn("ergebnisFp3", this.props.jahr + 3));
            id++;
        }

        return columns;
    }

    
    state: State = {
        ready: false,
        beschreibungActive: false
    }

    redrawCharts = () => {                
    }
  
    componentDidMount() {
        window.addEventListener('resize', this.redrawCharts, false);
        
        this.readKennzahl();
    }

    
    readKennzahl = () => {
        var request = new KSLPlusAPI.KennzahlRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.kennzahl = this.props.kennzahl.id;
        request.produkt = this.props.produkt;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        if (this.props.settings.buchungsart === 2) {
            var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);

            client.getKennzahlErgebnisse("", request).then((res) => {
                this.zeilen = res.zeilen ? res.zeilen : [];
                this.jahre = res.jahre;                
                this.setState({ ready: true });
            }).catch(Reason => {                
            });
        }
        else {
            var client1 = new KSLPlusAPI.DigitalHoushold_Client(api);

            client1.getKennzahlErgebnisse("", request).then((res) => {                
                this.zeilen = res.zeilen ? res.zeilen : [];
                this.jahre = res.jahre;                
                this.setState({ ready: true });
            }).catch(Reason => {                
            });
        }

        
    }

    createGraphData = (zeile: KSLPlusAPI.KennzahlZeilenErgebnis, min: any[], max: any[]) => {
        var grdata: any[] = [];
        //grdata.push([headerX, headerY]);

        

        var x: number[] = [];
        
        var header = ["Jahr"];
        var bez = (zeile.bezeichnung ? zeile.bezeichnung : "") + " (" + (zeile.einheit ? zeile.einheit : "") + ")";        
        header.push(bez);

        if (zeile.darstellung == 4 || zeile.darstellung == 5)
            header.push("Veränderung (%)" );
               
        grdata.push(header);

        var vorWert: number|undefined = undefined;
        
        
        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && zeile.ergebnisVvJ !== undefined)
        {   
            var row: any[] = [(this.props.jahr-2).toString()];
            row.push(zeile.ergebnisVvJ);
            if (zeile.darstellung == 4 || zeile.darstellung == 5)
                row.push(100);
            vorWert = zeile.ergebnisVvJ;
            grdata.push(row);
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && zeile.ergebnisVJ !== undefined )
        {   
            var row: any[] = [(this.props.jahr-1).toString()];
            row.push(zeile.ergebnisVJ);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisVJ / vorWert * 100);
            }
            vorWert = zeile.ergebnisVJ;
            grdata.push(row);      
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr) >= 0) && zeile.ergebnisPJ !== undefined )
        {   
            var row: any[] = [this.props.jahr.toString()];
            row.push(zeile.ergebnisPJ);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisPJ / vorWert * 100);
            }
            vorWert = zeile.ergebnisPJ;
            grdata.push(row);   
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 1) >= 0) && zeile.ergebnisFp1 !== undefined )
        {   
            var row: any[] = [(this.props.jahr+1).toString()];
            row.push(zeile.ergebnisFp1);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisFp1 / vorWert * 100);
            }
            vorWert = zeile.ergebnisFp1;
            grdata.push(row);    
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 2) >= 0) && zeile.ergebnisFp2 !== undefined )
        {   
            var row: any[] = [(this.props.jahr+2).toString()];
            row.push(zeile.ergebnisFp2);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisFp2 / vorWert * 100);
            }
            vorWert = zeile.ergebnisFp2;
            grdata.push(row);        
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 3) >= 0) && zeile.ergebnisFp3 !== undefined )
        {   
            var row: any[] = [(this.props.jahr+3).toString()];
            row.push(zeile.ergebnisFp3);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisFp3 / vorWert * 100);
            }
            vorWert = zeile.ergebnisFp3;
            grdata.push(row);
        }

        for (var i = 1; i < grdata.length; i++) {
            for (var j = 1; j < grdata[i].length; j=j+2) {
                if (grdata[i][j] < min[0])
                    min[0] = grdata[i][j];
                if (grdata[i][j] > max[0])
                    max[0] = grdata[i][j];
                if (grdata[i][j+1] < min[1])
                    min[1] = grdata[i][j+1];
                if (grdata[i][j+1] > max[1])
                    max[1] = grdata[i][j+1];
            }

        }
                        
        return grdata;
    }

    createZusatzGraphData = (zeile: KSLPlusAPI.KennzahlZeilenErgebnis, min: any[], max: any[]) => {
        var grdata: any[] = [];
        //grdata.push([headerX, headerY]);

        

        var x: number[] = [];
        
        var header = ["Jahr"];
        var bez = (zeile.bezeichnungZusatzangabe ? zeile.bezeichnungZusatzangabe : zeile.bezeichnung);
        header.push(bez + " (" + (zeile.einheit ? zeile.einheit : "") + ")");
        

        if (zeile.darstellung == 4 || zeile.darstellung == 5)
            header.push("Veränderung (%)");
               
        grdata.push(header);

        var vorWert: number|undefined = undefined;
        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && zeile.ergebnisVvJ_Z !== undefined )
        {   
            var row: any[] = [(this.props.jahr-2).toString()];
            row.push(zeile.ergebnisVvJ_Z);
            if (zeile.darstellung == 4 || zeile.darstellung == 5)
                row.push(100);
            vorWert = zeile.ergebnisVvJ_Z;
            grdata.push(row);
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && zeile.ergebnisVJ_Z !== undefined )
        {   
            var row: any[] = [(this.props.jahr-1).toString()];
            row.push(zeile.ergebnisVJ_Z);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisVJ_Z / vorWert * 100);
            }
            vorWert = zeile.ergebnisVJ_Z;
            grdata.push(row);      
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr) >= 0) && zeile.ergebnisPJ_Z !== undefined )
        {   
            var row: any[] = [this.props.jahr.toString()];
            row.push(zeile.ergebnisPJ_Z);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisPJ_Z / vorWert * 100);
            }
            vorWert = zeile.ergebnisPJ_Z;
            grdata.push(row);   
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 1) >= 0) && zeile.ergebnisFp1_Z !== undefined )
        {   
            var row: any[] = [(this.props.jahr+1).toString()];
            row.push(zeile.ergebnisFp1_Z);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisFp1_Z / vorWert * 100);
            }
            vorWert = zeile.ergebnisFp1_Z;
            grdata.push(row);    
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 2) >= 0) && zeile.ergebnisFp2_Z !== undefined )
        {   
            var row: any[] = [(this.props.jahr+2).toString()];
            row.push(zeile.ergebnisFp2_Z);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisFp2_Z / vorWert * 100);
            }
            vorWert = zeile.ergebnisFp2_Z;
            grdata.push(row);        
        }

        if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 3) >= 0) && zeile.ergebnisFp3_Z !== undefined )
        {   
            var row: any[] = [(this.props.jahr+3).toString()];
            row.push(zeile.ergebnisFp3_Z);
            if (zeile.darstellung == 4 || zeile.darstellung == 5) {
                if (vorWert === undefined)
                    row.push(100);
                else
                    row.push(zeile.ergebnisFp3_Z / vorWert * 100);
            }
            vorWert = zeile.ergebnisFp3_Z;
            grdata.push(row);
        }

        for (var i = 1; i < grdata.length; i++) {
            for (var j = 1; j < grdata[i].length; j = j + 2) {
                if (grdata[i][j] < min[0])
                    min[0] = grdata[i][j];
                if (grdata[i][j] > max[0])
                    max[0] = grdata[i][j];
                if (grdata[i][j + 1] < min[1])
                    min[1] = grdata[i][j + 1];
                if (grdata[i][j + 1] > max[1])
                    max[1] = grdata[i][j + 1];
            }

        }
                        
        return grdata;
    }

    createSammelGraphData = (zusatz: boolean, min: any[], max: any[] ) => {
        var grdata: any[] = [];
        var axisItems: any[] = [];
        //grdata.push([headerX, headerY]);

        

        var x: number[] = [];        
        var header = ["Jahr"];

        const darstellung = this.props.kennzahl.darstellung;
        
        for ( var i = 0; i < this.zeilen.length; i++ )
        {
            const zeile = this.zeilen[i];

            if ( !zusatz && (zeile.darstellung === undefined || zeile.darstellung >= 1) )
                continue;

            if ( zusatz && (zeile.darstellungZusatzangabe === undefined || zeile.darstellungZusatzangabe >= 1) )
                continue;
                        
            var bez = zeile.bezeichnung;
            if ( zusatz )
                bez = (zeile.bezeichnungZusatzangabe ? zeile.bezeichnungZusatzangabe : zeile.bezeichnung);

            header.push(bez + " (" + (zeile.einheit ? zeile.einheit : "") + ")");

            if (this.props.kennzahl.darstellung == 4 || this.props.kennzahl.darstellung == 5)
                header.push("Veränderung " + bez  + " (%)");
        }
               
        grdata.push(header);

        var row1: any[] = [(this.props.jahr-2).toString()];
        var row2: any[] = [(this.props.jahr-1).toString()];
        var row3: any[] = [this.props.jahr.toString()];
        var row4: any[] = [(this.props.jahr+1).toString()];
        var row5: any[] = [(this.props.jahr+2).toString()];
        var row6: any[] = [(this.props.jahr+3).toString()];
        for ( var i = 0; i < this.zeilen.length; i++ )
        {
            const zeile = this.zeilen[i];

            
                
            var vorWert: number|undefined = undefined;
            
            if ( zusatz )
            {
                if ( zeile.darstellungZusatzangabe === undefined || zeile.darstellungZusatzangabe >= 1 )
                    continue;

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && zeile.ergebnisVvJ_Z !== undefined )
                {                   
                    row1.push(zeile.ergebnisVvJ_Z);
                    if (darstellung == 4 || darstellung == 5)
                        row1.push(100);
                    vorWert = zeile.ergebnisVvJ_Z;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && zeile.ergebnisVJ_Z !== undefined && zeile.ergebnisVJ_Z !== null)
                {                   
                    row2.push(zeile.ergebnisVJ_Z);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row2.push(100);
                        else
                            row2.push(zeile.ergebnisVJ_Z / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisVJ_Z;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr ) >= 0) && zeile.ergebnisPJ_Z !== undefined && zeile.ergebnisPJ_Z !== null )
                {                   
                    row3.push(zeile.ergebnisPJ_Z);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row3.push(100);
                        else
                            row3.push(zeile.ergebnisPJ_Z / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisPJ_Z;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 1) >= 0) && zeile.ergebnisFp1_Z !== undefined && zeile.ergebnisFp1_Z !== null )
                {                   
                    row4.push(zeile.ergebnisFp1_Z);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row4.push(100);
                        else
                            row4.push(zeile.ergebnisFp1_Z / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisFp1_Z;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 2) >= 0) && zeile.ergebnisFp2_Z !== undefined && zeile.ergebnisFp2_Z !== null )
                {                   
                    row5.push(zeile.ergebnisFp2_Z);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row5.push(100);
                        else
                            row5.push(zeile.ergebnisFp2_Z / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisFp2_Z;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 3) >= 0) && zeile.ergebnisFp3_Z !== undefined && zeile.ergebnisFp3_Z !== null)
                {                   
                    row6.push(zeile.ergebnisFp3_Z);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row6.push(100);
                        else
                            row6.push(zeile.ergebnisFp3_Z / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisFp3_Z;                
                }
            }
            else
            {
                if ( zeile.darstellung === undefined || zeile.darstellung >= 1 )
                    continue;

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && zeile.ergebnisVvJ !== undefined )
                {                   
                    row1.push(zeile.ergebnisVvJ);
                    if (darstellung == 4 || darstellung == 5)
                        row1.push(100);
                    vorWert = zeile.ergebnisVvJ;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && zeile.ergebnisVJ !== undefined )
                {                   
                    row2.push(zeile.ergebnisVJ);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row2.push(100);
                        else
                            row2.push(zeile.ergebnisVJ / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisVJ;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr ) >= 0) && zeile.ergebnisPJ !== undefined )
                {                   
                    row3.push(zeile.ergebnisPJ);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row3.push(100);
                        else
                            row3.push(zeile.ergebnisPJ / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisPJ;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 1) >= 0) && zeile.ergebnisFp1 !== undefined )
                {                   
                    row4.push(zeile.ergebnisFp1);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row4.push(100);
                        else
                            row4.push(zeile.ergebnisFp1 / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisFp1;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 2) >= 0) && zeile.ergebnisFp2 !== undefined )
                {                   
                    row5.push(zeile.ergebnisFp2);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row5.push(100);
                        else
                            row5.push(zeile.ergebnisFp2 / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisFp2;                
                }

                if ((!this.jahre || this.jahre.findIndex(d => d == this.props.jahr + 3) >= 0) && zeile.ergebnisFp3 !== undefined )
                {                   
                    row6.push(zeile.ergebnisFp3);
                    if (darstellung == 4 || darstellung == 5) {
                        if (vorWert === undefined)
                            row6.push(100);
                        else
                            row6.push(zeile.ergebnisFp3 / vorWert * 100);
                    }
                    vorWert = zeile.ergebnisFp3;                
                }
            }
        }

        if ( row1.length > 1 )
            grdata.push(row1); 

        if ( row2.length > 1 )
            grdata.push(row2);  

        if ( row3.length > 1 )
            grdata.push(row3); 

        if ( row4.length > 1 )
            grdata.push(row4); 

        if ( row5.length > 1 )
            grdata.push(row5);

        if ( row6.length > 1 )
            grdata.push(row6);  

        for (var i = 1; i < grdata.length; i++) {
            for (var j = 1; j < grdata[i].length; j = j + 2) {
                if (grdata[i][j] < min[0])
                    min[0] = grdata[i][j];
                if (grdata[i][j] > max[0])
                    max[0] = grdata[i][j];
                if (grdata[i][j + 1] < min[1])
                    min[1] = grdata[i][j + 1];
                if (grdata[i][j + 1] > max[1])
                    max[1] = grdata[i][j + 1];
            }

        }

        return grdata;
    }

    createGraphItems = () => {
        var items = [];

        for ( var i = 0; i < this.zeilen.length; i++ )
        {
            const zeile = this.zeilen[i];
            if ( zeile === undefined || zeile.darstellung === undefined || zeile.darstellung < 2 )
                continue;

            var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
            var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
            var data = this.createGraphData(zeile, min, max);
            var graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
            

            items.push(
                    <div key={"gritem" + i.toString()}>
                        <Row>
                            <Col>
                                <div className="kslplus-headline-third">Entwicklung {zeile.bezeichnung}</div>
                            </Col>
                        </Row>
                        <Row>
                        <Col>
                                    
                            <div className="ksl-grafik-container">
                            <Chart
                                width="100%"
                                height={'300px'}
                                chartType= "ComboChart"
                                chartLanguage='de'
                                loader={<div>Loading Chart</div>}
                                data={data}                                
                                options={{                                      
                                    colors: [ '#264d99', '#ADD8E6', '#dc8e12', '#f8b344' ],
                                    vAxes: { 0: { title: zeile.bezeichnung, minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: "Veränderung", minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max1, min: graphOpt.min1 } } },                                                                    
                                    hAxis: { title: 'Jahr', format: "####" },                                                         
                                    series: { 0: { type: zeile.darstellung == 3 || zeile.darstellung == 5 ? "bars" : "line", targetAxisIndex: 0 }, 1: { type: "line", targetAxisIndex: 1 } }
                                    }}                            
                                formatters={Utilities.createFormatters((zeile.darstellung == 2 || zeile.darstellung == 3)?1:2 , 2)}

                                rootProps={{ 'data-testid': '2' }}
                            />
                        </div>
                     </Col>
                    </Row>
                </div>
            );
        }


        return items;
    }

    createZusatzGraphItems = () => {
        var items = [];

        for ( var i = 0; i < this.zeilen.length; i++ )
        {
            const zeile = this.zeilen[i];
            if ( zeile === undefined || zeile.darstellungZusatzangabe === undefined || zeile.darstellungZusatzangabe < 2 )
                continue;
            
            var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
            var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
            var data = this.createZusatzGraphData(zeile, min, max);
            var graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);

            items.push(
                    <div key={"zgritem" + i.toString()}>
                        <Row>
                            <Col>
                                <div className="kslplus-headline-third">{"Entwicklung " + (zeile.bezeichnungZusatzangabe ? zeile.bezeichnungZusatzangabe : zeile.bezeichnung)}</div>
                            </Col>
                        </Row>
                        <Row>
                        <Col>
                                    
                            <div className="ksl-grafik-container">
                            <Chart
                                width="100%"
                                height={'300px'}
                                chartType= "ComboChart"
                                chartLanguage='de'
                                loader={<div>Loading Chart</div>}
                                data={data}
                                options={{    
                                    colors: [ '#264d99', '#ADD8E6', '#dc8e12', '#f8b344' ],
                                    vAxes: { 0: { title: zeile.bezeichnung, minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: "Veränderung", minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max1, min: graphOpt.min1 } } },                                
                                    hAxis: { title: 'Jahr', format: "####" },  
                                    seriesType: 'bars',                                                       
                                    series: { 0: { type: zeile.darstellungZusatzangabe == 3 || zeile.darstellungZusatzangabe == 5 ? "bars" : "line", targetAxisIndex: 0 }, 1: { type: "line", targetAxisIndex: 1 } }
                                    }} 
                                formatters={Utilities.createFormatters((zeile.darstellungZusatzangabe == 2 || zeile.darstellungZusatzangabe == 3)?1:2 , 2)}                        
                            

                                rootProps={{ 'data-testid': '7' }}
                            />
                        </div>
                     </Col>
                    </Row>
                </div>
            );
        }


        return items;
    }



    createSammelGraphItems = (zusatz: boolean) => {
        var items : any[] = [];
                                        
        if ( !zusatz && this.props.kennzahl.darstellung !== undefined && this.props.kennzahl.darstellung > 1 )
        {                   
            var series : any = {   };
            var idx = 0;         
            

            var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
            var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
            var data = this.createSammelGraphData(false, min, max);
            var graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);

            for (var i = 0; i < this.zeilen.length; i++) {
                const zeile = this.zeilen[i];
                if (zeile.darstellung === undefined || zeile.darstellung >= 1)
                    continue;

                series[idx] = { type: this.props.kennzahl.darstellung == 3 || this.props.kennzahl.darstellung == 5 ? "bars" : "line", targetAxisIndex: 0 };
                idx++;
                if (this.props.kennzahl.darstellung == 4 || this.props.kennzahl.darstellung == 5) {
                    series[idx] = { type: "line", targetAxisIndex: 1 };
                    idx++;

                }
            }

            items.push(
                    <div key="sgritem1">
                        <Row>
                            <Col>
                                <div className="kslplus-headline-third">{this.props.kennzahl.bezeichnungDarstellung}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    
                                <div className="ksl-grafik-container">
                                    <Chart
                                        width="100%"
                                        height={'300px'}
                                        chartType= "ComboChart"
                                        chartLanguage='de'
                                        loader={<div>Loading Chart</div>}
                                        data={data}
                                        options={{  
                                            colors: [ '#264d99', '#ADD8E6', '#dc8e12', '#f8b344' ],
                                            vAxes: { 0: { title: "Wert", minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: "Veränderung", minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max1, min: graphOpt.min1 } } },                                
                                            hAxis: { title: 'Jahr', format: "####" },                                                         
                                            series: series
                                            }}                            
                                        formatters={Utilities.createFormatters(idx , 2)}

                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
        }

        if ( zusatz && this.props.kennzahl.darstellungZusatzangaben !== undefined && this.props.kennzahl.darstellungZusatzangaben > 1 )
        {                  
            var series : any = {   };
            var idx = 0;                  

            var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
            var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
            var data = this.createSammelGraphData(true, min, max);
            var graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);

            for ( var i = 0; i < this.zeilen.length; i++ )
            {                
                const zeile = this.zeilen[i];
                if ( zeile.darstellungZusatzangabe === undefined || zeile.darstellungZusatzangabe >= 1 )
                    continue;
            
                series[idx] = { type: this.props.kennzahl.darstellungZusatzangaben == 3 || this.props.kennzahl.darstellungZusatzangaben == 5 ? "bars" : "line", targetAxisIndex: 0 };                
                idx++;
                
                if (this.props.kennzahl.darstellungZusatzangaben == 4 || this.props.kennzahl.darstellungZusatzangaben == 5)                
                {                    
                    series[idx] = { type: "line", targetAxisIndex: 1 };
                    idx++;
                    
                }
                
                                            
            }

            items.push( 
                        <div key="sgritem2">
                            <Row>
                                <Col>
                                    <div className="kslplus-headline-third">{this.props.kennzahl.bezeichnungDarstellungZusatzangaben}</div>
                                </Col>
                            </Row>
                                <Row>
                                    <Col>
                                    
                                        <div className="ksl-grafik-container">
                                            <Chart
                                                width="100%"
                                                height={'300px'}
                                                chartType= "ComboChart"
                                                chartLanguage='de'
                                                loader={<div>Loading Chart</div>}
                                                data={data}
                                                options={{     
                                                    colors: [ '#264d99', '#ADD8E6', '#dc8e12', '#f8b344' ],
                                                    vAxes: { 0: { title: "Wert", minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: "Veränderung", minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } } },                                
                                                    hAxis: { title: 'Jahr', format: "####" },                                                         
                                                    series: series
                                                    }}                            
                                                formatters={Utilities.createFormatters(idx , 2)}

                                                rootProps={{ 'data-testid': '3' }}
                                            />
                                        </div>
                                </Col>
                            </Row>
                        </div>
                );
        }


        return items;
    }

    

    
    
    render() {         
        
        var sammelgraphItems = this.createSammelGraphItems(false);  
        var zusatzsammelgraphItems = this.createSammelGraphItems(true);         
        var graphItems = this.createGraphItems();  
        var zusatzgraphItems = this.createZusatzGraphItems();  
        
        return (
            
                <div>                                        
                    <div className="kslplus-content">                                                                          
                    {
                        this.zeilen.length > 0 &&
                        <div>
                            {this.props.kennzahl.beschreibung &&
                                <div>
                                   <button className={"kslplus-beschreibung-toggle" + (this.state.beschreibungActive ?" active":"")} onClick={() => this.setState({beschreibungActive: !this.state.beschreibungActive})} >
                                      <span className="kslplus-beschreibung-text">Beschreibung</span>
                                      <div className="rotate90">
                                        <svg
                                          className={"kslplus-beschreibung-icon" + (this.state.beschreibungActive?" expanded":"")}
                                          viewBox="6 0 12 24"
                                        >
                                          <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                                        </svg>
                                      </div>
                            
                                    </button>
                                    <Collapse isOpen={this.state.beschreibungActive} className={"kslplus-beschreibung-collapse-gradient " + + (this.state.beschreibungActive?" active":"")}>
                                        <Row>   
                                            <Col className="kslplus-beschreibung-content">
                                                {this.props.kennzahl.beschreibung != null ? this.props.kennzahl.beschreibung: ""}                                                   
                                            </Col>
                                        </Row>
                                    </Collapse>                                    
                                </div>
                            }
                            <Row>
                                <Col>
                                    <BootstrapTable hover={true} bordered={true} keyField='id' data={this.zeilen} columns={this.createColumns()}  rowClasses={this.rowClasses}/>

                                </Col>
                            </Row>                            
                            {sammelgraphItems}
                            {graphItems}
                            {zusatzgraphItems}
                            {zusatzsammelgraphItems}                            
                        </div>
                    }
                    </div>
                </div>
        );
    }
}