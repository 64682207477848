import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container
} from "reactstrap";

import Collapse from "@kunukn/react-collapse";


import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import Chart from "react-google-charts";

import TrackVisibility from 'react-on-screen';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import Drawer from '@material-ui/core/Drawer';


import { HHSummiertDoppisch } from './HHSummiertDoppischComp';

import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

const paginationoptions = {
    sizePerPageList: [{
        text: '10', value: 10
    },{
        text: '15', value: 15
    }, {
        text: '20', value: 20
    }] // A numeric array is also available. the purpose of above example is custom the text
};

type State = {
    ready: boolean,
    ebene: KSLPlusAPI.HaushaltSummiert | undefined,    
}

type Props = {    
    produkt: string;
    jahr: number,
    kunde: number,
    gemeinde: number,    
    plantyp: number,
    planstufe: number,  
    verwaltungsansicht: boolean,
    settings: KSLPlusAPI.SettingsModel,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,        
    summenTyp: number,
    isVisible: boolean
}


export class ProduktSpezHaushalt extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        
    }

    static defaultProps = {
        summenTyp: 1        
    }

    node: any;    
    container: any = null;

    state: State = {
        ready: false,        
        ebene: undefined        
    }

    
    componentDidUpdate(prevProps: Props) {

        if (prevProps.uhconfig !== this.props.uhconfig || prevProps.produkt != this.props.produkt)
            this.readSummenHH(this.props.produkt);
    }
  
    componentDidMount() {        
        this.readSummenHH(this.props.produkt);
    }

    

    
    readSummenHH = (produkt: string, tries: number = 0) => {
        
        
        
        var request = new KSLPlusAPI.HaushaltSummenRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.summenTyp = this.props.summenTyp;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        
        request.produkt = produkt;
          
        client.getHaushaltsSummeByProdukt("", request).then((hh) => {            
            this.setState({ ebene: hh });            
            //this.setState({ ready: true });
        }).catch(Reason => {
            if (tries < 3)
                setTimeout(() => { this.readSummenHH(produkt, tries++) }, 2000);
            else
                alert("Fehler beim Lesen des Haushalts für Betrachtung nach Produkten!");
        });
        
    }

    

    

    render() {        

        
        
        
        return (    
            
            
            <div className="kslplus-content">                                  
                {this.state.ebene ?
                    <HHSummiertDoppisch hh={this.state.ebene} plantyp={this.props.plantyp} planstufe={this.props.planstufe} jahr={this.props.jahr} isVisible={this.props.isVisible} settings={this.props.settings} summenTyp={this.props.summenTyp} uhconfig={this.props.uhconfig} callerID={this.props.produkt} />
                        : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                    }
            </div>            
        );
    }
}

