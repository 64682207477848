import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import * as Utilities from "./Utilities"

import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";
import { create } from "domain";

import * as Tree from 'react-dropdown-tree-select'
import DropdownTreeSelect from 'react-dropdown-tree-select'


import './NavTree.css'



export enum EbenenTyp     {
    Gliederung = 1,
    Gruppierung,
    FreieEbenen 
}

type State = {
    ready: boolean,
    expanded: string[],
    selected: KSLPlusAPI.HaushaltsEbene | undefined,
    suchtext: string
}

type Props = {    
    hh: KSLPlusAPI.HaushaltsEbene,
    //hh2: KSLPlusAPI.HaushaltsEbene
    onSelChange: (ebene: KSLPlusAPI.HaushaltsEbene, suchtext: string) => void,
    useUAText: boolean,
    showNumber: boolean,
    selected: string|undefined,
    typ: EbenenTyp,
    auswahlText: string
}



export class HaushaltsNavTree extends Component<Props, State> {
    constructor(props: any) {
        super(props);
                
    }

    node: any;
    

    state: State = {
        ready: false,
        expanded: ["1"],
        selected: undefined,
        suchtext: ""
    }

    searchPredicate = (node: any, searchTerm: string): boolean => {
        this.state.suchtext = searchTerm;
        var res = node.label.toLowerCase().indexOf(searchTerm) >= 0;

        if ( res )
            return true;

        
        if ( node.customData  )
        {   
            var ebene = (node.customData as KSLPlusAPI.HaushaltsEbene);
            if ( ebene )
            {          
                if ( ebene.beschreibung && ebene.beschreibung.toLowerCase().indexOf(searchTerm) >= 0 )
                    return true;

                /*
                // durch Haushaltsstellen, wenn wir bereits auf der Buchungsebene sind
                if ( !node.children && ebene.unterEbenen )
                {                                                    
                    ebene.unterEbenen.every( ue => {                        
                        if ( ue.beschreibung && ue.beschreibung.toLowerCase().indexOf(searchTerm) >= 0 )
                        {                            
                            res = true;
                            return false;
                        }
                    });
                }
                */
            }            
        }

        return res;
    }

  
    componentDidMount() {
        
    }

    /*
    createDocLinks = () => {
        var items = Object.keys(this.dokumente).map((key, index) => {
            const item = Number(key);
            const dokument = this.dokumente[key as any];

            return (
                <Row><Col>

                    <a className="kslplus-dokument-link" href={dokument.url} target="_blank">{dokument.bezeichnung}</a>
                    </Col></Row>
            );
        });        
        return items;
    };
    */
    createTreeData = (ebenen: KSLPlusAPI.HaushaltsEbene[], parent: KSLPlusAPI.HaushaltsEbene|undefined, tagLabel: string) => {
        var data: Tree.TreeNode[] = [];
       
        ebenen.forEach( ebene => {
            var bezeichnung = this.props.useUAText ? ebene.uaText : ebene.ugText;
            var label = "";
            
            
            if ( ebene.isHHS )
                return;
           

            //if (!ebene.ergebnisVvJ_soll && !ebene.ansatzVJ_soll && !ebene.ansatzPj_soll && !ebene.ansatzPj_soll && !ebene.fp2_soll && !ebene.fp3_soll && !ebene.ergebnisVvJ_haben && !ebene.ansatzVJ_haben && !ebene.ansatzPj_haben && !ebene.ansatzPj_haben && !ebene.fp2_haben && !ebene.fp3_haben ) 
              //  return;
            if ( !ebene.containsData )
                return;

            var num = "";
            
            if ( this.props.typ == EbenenTyp.FreieEbenen )
            {   
                num = ebene.ebenenNr !== undefined ? Utilities.Num2Str(ebene.ebenenNr, ebene.ebenenStellen ? ebene.ebenenStellen : 0) : "";
            }
            else
            {
                num = this.props.useUAText ? ebene.unterabschnitt !== undefined && ebene.uaStellen !== undefined ? Utilities.Num2Str(ebene.unterabschnitt, ebene.uaStellen) : "" : ebene.untergruppe !== undefined && ebene.ugStellen !== undefined ? Utilities.Num2Str(ebene.untergruppe, ebene.ugStellen) : "";                    
            }
            if ( this.props.showNumber )               
                label = num.length ? num + " - " : "";  
            

            label = label + (bezeichnung != null && bezeichnung.length > 0 ? bezeichnung : ebene.bezeichnung);
            
            var item :Tree.TreeNode = {label: label, value: label, ebene: ebene, parent: parent, tagLabel: tagLabel + label, customData: ebene}
            
            if ( num === this.props.selected )
            {                   
                item.checked = true;     
                item.expanded = true;           
            }
            
                                                          
            
            // für freie ebenen keine selektion der Elternknoten zulassen
            //if ( this.props.typ == EbenenTyp.FreieEbenen )
                //  item.disabled = true;
            if (ebene.unterEbenen) {
                item.children = this.createTreeData(ebene.unterEbenen, ebene, item.tagLabel + " > ");
                for (var i = 0; i < item.children.length; i++) {
                    if (item.children[i].expanded) {
                        item.expanded = true;
                        break;
                    }
                }
            }
            
            data.push(item);
            
        });
        return data;
    }

    

  
    handleTreeClick = (data: Tree.TreeNode, selected: Tree.TreeNode[]) => {                
        if ( selected.length > 0  )       
        {
            this.state.selected = data.ebene;
            this.props.onSelChange(selected[0].ebene, this.state.suchtext);
            this.state.suchtext = "";
        }
        else if ( data.parent )
        {
            this.state.selected = data.ebene;
            this.props.onSelChange(data.parent, this.state.suchtext);
            this.state.suchtext = "";
        }
    }
/*
    handleToggle = (event : any, nodeIds : string[]) => {
        this.setState({ expanded: nodeIds });
    }

    handleSelect = (event: any, nodeIds: string[]) => {
        this.setState({ selected: nodeIds });
    }
*/
    render() {
        if (!this.props.hh.unterEbenen)
            return null;
        
        var treedata : Tree.TreeNode[]; 
        if ( !this.props.hh.verbergen )
        {
            var main: Tree.TreeNode = {label: "Gesamthaushalt", value: "Gesamthaushalt", ebene: this.props.hh, parent: undefined, expanded: true}; 
            
            var num = "";
            
            if ( this.props.typ == EbenenTyp.FreieEbenen )
            {   
                num = this.props.hh.ebenenNr !== undefined ? Utilities.Num2Str(this.props.hh.ebenenNr, this.props.hh.ebenenStellen ? this.props.hh.ebenenStellen : 0) : "";
            }
            else
            {
                num = this.props.useUAText ? this.props.hh.unterabschnitt !== undefined && this.props.hh.uaStellen !== undefined ? Utilities.Num2Str(this.props.hh.unterabschnitt, this.props.hh.uaStellen) : "" : this.props.hh.untergruppe !== undefined && this.props.hh.ugStellen !== undefined ? Utilities.Num2Str(this.props.hh.untergruppe, this.props.hh.ugStellen) : "";                    
            }
        
            if ( this.props.selected === "" )
            {
                //main.isDefaultValue = true;
                main.checked = true;            
            
            }

            main.children = this.createTreeData(this.props.hh.unterEbenen, this.props.hh, "");
            treedata = [main];
        }
        else
            treedata = this.createTreeData(this.props.hh.unterEbenen, undefined, "");

        return (
            <DropdownTreeSelect className="kslplus-haushalts-treeselect" data={treedata} mode="radioSelect" texts={{placeholder: this.props.auswahlText, noMatches: "Kein Treffer" }} searchPredicate={this.searchPredicate} onChange={this.handleTreeClick}/>            
          );
    }
}