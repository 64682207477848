import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import Chart from "react-google-charts";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as Utilities from "./Utilities"

import { HaushaltsDetailiert } from './HaushaltsDetailiertComp';
//import { VMHaushalt } from './VMHaushaltsComp';
import { WeitereFinanzdatenDoppisch } from './WeitereFinanzdatenDoppischComp';
import { HHSummiertDoppisch } from './HHSummiertDoppischComp';
import { InvestitionenDoppisch } from './InvestitionenDoppischComp';

import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";
import { env } from '../env';

type State = {
    ready: boolean;        
    activeMainTab: string,
    selectedGLEbene: string;    
    isVisible: boolean;
}

type Props = {    
    kunde: number,
    gemeinde: number,
    jahr: number,
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    settings: KSLPlusAPI.SettingsModel,
    isVisible: boolean
}



export class FinanzdatenDoppisch extends Component<Props, State> {
    constructor(props: any) {
        super(props);
    
        this.aktiva = null;
        this.passiva = null;

        this.investA = null;
        this.investP = null;
    }

    node: any;
    aktiva: KSLPlusAPI.HaushaltSummiert | null;    
    passiva: KSLPlusAPI.HaushaltSummiert | null;
    investA: KSLPlusAPI.HaushaltSummiert | null;
    investP: KSLPlusAPI.HaushaltSummiert | null;
    opForderungen: KSLPlusAPI.HaushaltSummiert | null = null;
    opVerbindlichkeiten: KSLPlusAPI.HaushaltSummiert | null = null;
    invests: KSLPlusAPI.InvestitionsModelDoppik[] = [];
    investSettings: KSLPlusAPI.InvestSetting | null = null;
    
    state: State = {
        ready: true,        
        activeMainTab: "1",
        selectedGLEbene: "",
        isVisible: false
    }    

    handleSelectHHEbene = (ebenenNum: string) => {                
        this.setState({selectedGLEbene: ebenenNum} );        
    } 

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible)        
            this.setState({ activeMainTab: "1" });        

        if (prevProps.uhconfig !== this.props.uhconfig && this.props.plantyp === 3)
            this.readAktivaData();

        if (prevProps.uhconfig !== this.props.uhconfig && this.props.plantyp === 4)
            this.readInvestitionen();

    }

    readInvestitionen = () => {
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;
        request.modul = (this.props.plantyp == 3 ? 1 : (this.props.plantyp == 4 ? 2 : 0));


        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var clientDoppik = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);


        clientDoppik.getInvestitionen(this.props.plantyp, this.props.planstufe, this.props.uhconfig && this.props.uhconfig.id ? this.props.uhconfig.id : 0, "", request).then((invests) => {
            client.getInvestSettings(this.props.kunde, this.props.gemeinde, this.props.jahr, "").then(settings => {
                this.investSettings = settings;
                this.invests = invests;
                this.readInvestAData();
                this.setState({ ready: true });
                //this.readDataSets();            
            }).catch(Reason => {
                //this.readDataSets();            
            });
        }).catch(Reason => {
            this.readInvestAData();
        });

    }

    readInvestAData = (tries: number = 0) => {
        if (this.props.plantyp === 4 && this.props.uhconfig && !this.props.uhconfig.bilanzKonten)
            return;

        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.nurOberebenen = true;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;   
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);


        client.getInvestADetailliert("", request).then((aktiva) => {
            this.investA = aktiva;
            if (!this.investA.zeilen)
                return;

            //this.readHH2Data();
            this.readInvestPData();
        }).catch(Reason => {
            if (tries < 3) {
                setTimeout(() => { this.readInvestAData(++tries) }, 2000);                
            }
            else
                alert("Fehler beim Lesen der aktiven Investitionsdetails!");
        });

    }

    readInvestPData = (tries: number = 0) => {
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.nurOberebenen = true;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;   
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);


        client.getInvestPDetailliert("", request).then((passiva) => {
            this.investP = passiva;            
            if (!this.investP.zeilen)
                return;

            //this.readHH2Data();
            
        }).catch(Reason => {
            if (tries < 3) {
                setTimeout(() => { this.readInvestPData(++tries) }, 2000);
            }
            else
                alert("Fehler beim Lesen der passiven Investionsdetails!");
        });

    }


    readAktivaData = (tries: number = 0) => {
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;
        
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        
        client.getAktivaDetailliert("", request).then((aktiva) => {
            this.aktiva = aktiva;            
            if (!this.aktiva.zeilen)
                return;
            
            //this.readHH2Data();
            this.readPassivaData();                        
        }).catch(Reason => {            
            if (tries < 3) {                
                setTimeout(() => { this.readAktivaData(++tries) }, 2000);
            }
            else
                alert("Fehler beim Lesen der Aktiva!");
        });

    }

    readPassivaData = (tries: number = 0) => {
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;
        
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        
        client.getPassivaDetailliert("", request).then((passiva) => {
            this.passiva = passiva;
            this.readOpForderungenData();
            if (!this.passiva.zeilen)
                return;
                                               
        }).catch(Reason => {            
            if (tries < 3) {                            
                setTimeout(() => { this.readPassivaData(++tries) }, 2000);
            }
            else {
                this.readOpForderungenData();
                alert("Fehler beim Lesen der Passiva!");
            }
            
        });

    }

    readOpForderungenData = (tries: number = 0) => {
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);


        client.getOffenePostenForderungen("", request).then((op) => {
            this.opForderungen = op;

            if (!this.opForderungen.zeilen)
                return;

            this.readOpVerbindlichkeitenData();
            this.setState({ ready: true });

        }).catch(Reason => {            
        });

    }

    readOpVerbindlichkeitenData = (tries: number = 0) => {
        var request = new KSLPlusAPI.HaushaltRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);


        client.getOffenePostenVerbindlichkeiten("", request).then((op) => {
            this.opVerbindlichkeiten = op;            
            if (!this.opVerbindlichkeiten.zeilen)
                return;
            this.setState({ ready: true });

        }).catch(Reason => {
            
        });

    }

    componentDidMount() {
        if ( this.props.plantyp == 3 )
            this.readAktivaData();
        if (this.props.plantyp == 4)
            this.readInvestitionen();
        
    }
                         
    render() {

        var opText = "Offene Posten";
        
        if (this.props.settings.tabTexte) {
            
            var text = this.props.settings.tabTexte.find(t => t.bezeichnung === "offene-postenforderungen-und-verbindlichkeiten");
            if (text && text.text && text.text.length > 0)
                opText = text.text;
        }
                        

        return (
            <div className="kslplus-haushaltstab-wrapper-middle">  
                <Nav tabs>         
                    {(this.props.plantyp !== 4 || (this.props.uhconfig && this.props.uhconfig.ergKonten)) &&                        
                        <NavItem id="kslplus-tab-ergebnishaushalt-detailliert">
                            <NavLink active={this.state.activeMainTab === "1"} onClick={() => this.setState({ activeMainTab: "1" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{this.props.plantyp === 3 || this.props.plantyp === 4 ? "Ergebnisrechnung detailliert" : "Ergebnishaushalt detailliert"}</div>
                            </NavLink>
                        </NavItem>                            
                    }
                    {(this.props.plantyp !== 4 || (this.props.uhconfig && this.props.uhconfig.ergKonten)) &&
                        Utilities.createTTItem(this.props.settings, "ergebnishaushalt-detailliert")
                    }
                    {this.props.plantyp === 3 &&                        
                        <NavItem id="kslplus-tab-bilanz-detailliert">
                            <NavLink active={this.state.activeMainTab === "4"} onClick={() => this.setState({ activeMainTab: "4" })} >
                                <div className="kslplus-reiter-text-hauptzeile">Bilanz detailliert</div>
                            </NavLink>
                        </NavItem>                                                
                    }
                    {this.props.plantyp === 3 &&                        
                        Utilities.createTTItem(this.props.settings, "bilanz-detailliert")
                    }
                    {(this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.bilanzKonten) &&                        
                        <NavItem id="kslplus-tab-investitionen-detailliert-bilanzkonten">
                            <NavLink active={this.state.activeMainTab === "4_1"} onClick={() => this.setState({ activeMainTab: "4_1" })} >
                                <div className="kslplus-reiter-text-hauptzeile">Investitionen detailliert</div>
                            </NavLink>
                        </NavItem>                            
                    }
                    
                    {(this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.bilanzKonten) &&
                        Utilities.createTTItem(this.props.settings, "detailliert-bilanzkonten")            
                    }

                    

                    {this.props.plantyp === 3 && this.opForderungen && this.opVerbindlichkeiten ?
                        <NavItem id="kslplus-tab-offene-postenforderungen-und-verbindlichkeiten">
                            <NavLink active={this.state.activeMainTab === "5"} onClick={() => this.setState({ activeMainTab: "5" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{opText}</div>
                            </NavLink>
                        </NavItem> : null
                    }
                    {this.props.plantyp === 3 && this.opForderungen && this.opVerbindlichkeiten &&                         
                        Utilities.createTTItem(this.props.settings, "offene-postenforderungen-und-verbindlichkeiten")            
                    }
                    
                    {this.props.plantyp !== 4 && this.props.settings.mitFinanzHH &&
                        <NavItem id="kslplus-tab-finanzhaushalt-detailliert">
                            <NavLink active={this.state.activeMainTab === "2"} onClick={() => this.setState({ activeMainTab: "2" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{this.props.plantyp === 3 ? "Finanzrechnung detailliert" : "Finanzhaushalt detailliert"}</div>

                            </NavLink>
                        </NavItem>
                    }
                    {this.props.plantyp !== 4 &&
                        Utilities.createTTItem(this.props.settings, "finanzhaushalt-detailliert")
                    }
                    {(this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.finKonten) &&                    
                        <NavItem id="kslplus-tab-investitionen-detailliert-finanzkonten">
                            <NavLink active={this.state.activeMainTab === "2"} onClick={() => this.setState({ activeMainTab: "2" })} >
                                <div className="kslplus-reiter-text-hauptzeile">{"Finanzrechnung detailliert"}</div>

                            </NavLink>
                        </NavItem>                            
                    }
                    {(this.props.plantyp === 4 && this.props.uhconfig && this.props.uhconfig.finKonten) &&
                        Utilities.createTTItem(this.props.settings, "investitionen-detailliert-finanzkonten")
                    }
                    {this.props.plantyp === 4 && this.invests.length > 0 ?
                        <NavItem id="kslplus-tab-investitionen">
                            <NavLink active={this.state.activeMainTab === "6"} onClick={() => this.setState({ activeMainTab: "6" })} >
                                <div className="kslplus-reiter-text-hauptzeile">Investitionen (tabellarisch)</div>
                            </NavLink>
                        </NavItem> : null
                    }
                    {this.invests.length > 0 ?
                        Utilities.createTTItem(this.props.settings, "investitionen") : null
                    }
                    {(this.props.plantyp !== 3 && this.props.plantyp !== 4) &&
                        <div>
                            <NavItem id="kslplus-tab-weitere-finanzdaten">
                                <NavLink active={this.state.activeMainTab === "3"} onClick={() => this.setState({ activeMainTab: "3" })} >
                                    <div className="kslplus-reiter-text-hauptzeile">Weitere Finanzdaten</div>
                                </NavLink>
                            </NavItem>
                            {Utilities.createTTItem(this.props.settings, "weitere-finanzdaten")}
                        </div>
                    }
                </Nav>
                
                <TabContent activeTab={this.state.activeMainTab}>                    

                    {(this.props.plantyp !== 4 || (this.props.uhconfig && this.props.uhconfig.ergKonten)) &&
                        <TabPane tabId="1">
                            <HaushaltsDetailiert kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} isVisible={this.props.isVisible && this.state.activeMainTab === "1"} summenTyp={this.props.plantyp == 3 ? 9 : (this.props.plantyp == 4 ? 19 : 3)} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                        </TabPane>
                    }
                    {(this.props.plantyp !== 4 || (this.props.uhconfig && this.props.uhconfig.finKonten)) && this.props.settings.mitFinanzHH &&   
                        <TabPane tabId="2">
                            <HaushaltsDetailiert kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} isVisible={this.props.isVisible && this.state.activeMainTab === "2"} summenTyp={this.props.plantyp == 3 ? 10 : (this.props.plantyp == 4 ? 20 : 4)} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                        </TabPane>
                    }
                    {(this.props.plantyp !== 3 && this.props.plantyp !== 4) &&
                        <TabPane tabId="3">
                            <WeitereFinanzdatenDoppisch kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} isVisible={this.state.activeMainTab === "3"} bilanz={false} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                        </TabPane>
                    }
                    <TabPane tabId="4">  
                        {this.aktiva != null ?
                            <HHSummiertDoppisch hh={this.aktiva} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4"} settings={this.props.settings} summenTyp={11} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"11_1"} />                            
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }                          
                        {this.passiva != null   ?    
                            <HHSummiertDoppisch hh={this.passiva} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4"} settings={this.props.settings} summenTyp={12} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"12_1"} />                            
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }      
                        {this.aktiva != null   ?    
                            <HHSummiertDoppisch hh={this.aktiva} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4"} settings={this.props.settings} summenTyp={11} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"11_2"} />                            
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }                          
                        {this.passiva != null   ?    
                            <HHSummiertDoppisch hh={this.passiva} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4"} settings={this.props.settings} summenTyp={12} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"12_2"} />                            
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }                     
                    </TabPane>
                    <TabPane tabId="4_1">
                        {this.investA != null ?
                            <HHSummiertDoppisch hh={this.investA} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4_1"} settings={this.props.settings} summenTyp={23} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"23_1"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                        {this.investP != null ?
                            <HHSummiertDoppisch hh={this.investP} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4_1"} settings={this.props.settings} summenTyp={24} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"24_1"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                        {this.investA != null ?
                            <HHSummiertDoppisch hh={this.investA} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4_1"} settings={this.props.settings} summenTyp={23} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"23_2"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                        {this.investP != null ?
                            <HHSummiertDoppisch hh={this.investP} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "4_1"} settings={this.props.settings} summenTyp={24} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"24_2"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                    </TabPane>
                    <TabPane tabId="5">
                        {this.opForderungen != null ?
                            <HHSummiertDoppisch hh={this.opForderungen} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "5"} settings={this.props.settings} summenTyp={15} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"15_1"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                        {this.opVerbindlichkeiten != null ?
                            <HHSummiertDoppisch hh={this.opVerbindlichkeiten} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "5"} settings={this.props.settings} summenTyp={16} renderTyp={1} uhconfig={this.props.uhconfig} callerID={"16_1"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                        {this.opForderungen != null ?
                            <HHSummiertDoppisch hh={this.opForderungen} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "5"} settings={this.props.settings} summenTyp={15} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"15_2"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                        {this.opVerbindlichkeiten != null ?
                            <HHSummiertDoppisch hh={this.opVerbindlichkeiten} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.state.activeMainTab === "5"} settings={this.props.settings} summenTyp={16} renderTyp={2} uhconfig={this.props.uhconfig} callerID={"16_2"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                    </TabPane>
                    {(this.invests.length > 0 && this.investSettings) ?
                        <TabPane tabId="6">
                            <InvestitionenDoppisch kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} invests={this.invests} investSettings={this.investSettings} />
                        </TabPane> : null
                    }
                </TabContent>
                               
            </div>
        );
    }
}